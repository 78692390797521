<template>
    <div :class="['sc-table', `sc-table--${tablesize}`]">
        <!-- 表格顶部操作区 -->
        <div class="sc-table__header">
            <!-- 搜索表单 -->
            <div class="left">
                <el-form
                    size="small"
                    v-if="searchColumns.length"
                    :model="search"
                    ref="searchForm"
                    :inline="true"
                    class="sc-table__search"
                >
                    <el-form-item
                        v-for="(item, index) in searchColumns"
                        :key="index"
                        :label="item.label"
                        :prop="item.prop"
                    >
                        <!-- 输入框 -->
                        <el-input
                            v-if="item.type === 'input'"
                            v-model="search[item.prop]"
                            :placeholder="'请输入' + item.label"
                            clearable
                            @keyup.enter="handleSearch"
                        />

                        <!-- 数字输入框 -->
                        <el-input-number
                            v-if="item.type === 'number'"
                            v-model="search[item.prop]"
                            :min="item.min ?? 0"
                            :placeholder="'请输入' + item.label"
                            style="width: 180px"
                            @keyup.enter="handleSearch"
                        />

                        <!-- 选择器 -->
                        <el-select
                            v-if="item.type === 'select'"
                            v-model="search[item.prop]"
                            :placeholder="'请选择' + item.label"
                            clearable
                            style="width: 180px"
                        >
                            <el-option
                                v-for="dict in item.dicData"
                                :key="dict.value"
                                :label="dict.label"
                                :value="dict.value"
                            />
                        </el-select>

                        <!-- 日期选择器 -->
                        <el-date-picker
                            v-if="item.type === 'date'"
                            v-model="search[item.prop]"
                            type="date"
                            :placeholder="'请选择' + item.label"
                            style="width: 180px"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleSearch">
                            <el-icon><Search /></el-icon>
                            查询
                        </el-button>
                        <el-button @click="handleReset">
                            <el-icon><Refresh /></el-icon>
                            重置
                        </el-button>
                    </el-form-item>
                </el-form>
                <slot name="left"></slot>
            </div>
            <div class="right">
                <el-button
                  
                    v-if="option.addBtn !== false && permissionList?.addBtn"
                    type="primary"
                    @click="rowAdd"
                >
                    <el-icon><Plus /></el-icon>
                    新增
                </el-button>
                
                <!-- 使用v-if和$slots来控制默认批量删除按钮的显示 -->
                <el-button 
                    v-if="!$slots['menu-left'] && option.batchDelete !== false && permissionList?.delBtn"
                    type="danger"
                    :disabled="!selectedRows.length"
                    @click="handleBatchDelete"
                > 
                    <el-icon><Delete /></el-icon>
                    批量删除
                </el-button>
                
                <slot name="menu-left"></slot>
                <slot name="right"></slot>
            </div>
        </div>

        <!-- 表格主体 -->
        <el-table
            ref="table"
            :data="data"
            v-loading="loading"
            style="width: 100%"
            size="small"
            border
            :stripe="option.stripe !== false"
            @selection-change="handleSelectionChange"
        >
            <!-- 复选框列 -->
            <el-table-column
                v-if="showSelection"
                type="selection"
                width="55"
                align="center"
                fixed="left"
            >   
              <!-- 自定义表头复选框 -->
                <template #header>
                    <el-checkbox 
                        :model-value="isAllSelected"
                        @change="handleSelectAll"
                        class="custom-checkbox"
                    />
                </template>
                <template #default="scope">
                    <el-checkbox 
                        v-model="scope.row.isSelected"
                        @change="handleSelect(scope.row)"
                        class="custom-checkbox"
                    />
                </template>
            </el-table-column>
            
            <!-- 序号列 -->
            <el-table-column
                v-if="showIndex"
                type="index"
                label="序号"
                width="80"
                align="center"
                :index="calculateIndex"
                fixed="left"
            />

            <template v-for="(column, index) in visibleColumns" :key="index">
                <!-- 操作列 -->
                <el-table-column
                    v-if="column.type === 'operate'"
                    :label="column.label"
                    :width="column.width"
                    :align="column.align || 'center'"
                    :fixed="column.fixed"
                >
                    <template #default="scope">
                        <!-- 默认按钮 -->
                        <template v-for="(btn, i) in getVisibleButtons(column.buttons)" :key="i">
                            <el-button 
                                size="small"
                                :type="btn.type || 'primary'"
                                :link="btn.link"
                                @click="handleBtnClick(btn, scope.row)"
                            >
                                {{ btn.label }}
                            </el-button>
                        </template>
                        
                        <!-- 添加扩展按钮插槽 -->
                        <slot name="menu-extend" v-bind="scope"></slot>
                    </template>
                </el-table-column>
                
                <!-- 普通列 -->
                <el-table-column
                    v-else
                    :prop="column.prop"
                    :label="column.label"
                    :width="column.width"
                    :align="column.align"
                    :fixed="column.fixed"
                >
                    <template #default="scope">
                        <!-- 优先使用字段的具名插槽 -->
                        <slot 
                            v-if="$slots[column.prop]"
                            :name="column.prop" 
                            v-bind="{ row: scope.row, column: column }"
                        />
                        <!-- 没有具名插槽时使用默认渲染逻辑 -->
                        <template v-else>
                            <template v-if="column.type === 'multi-line'">
                                <div class="cell-content">
                                    <div class="primary-text">{{ scope.row[column.prop] }}</div>
                                    <div class="secondary-text">{{ scope.row[column.secondProp] }}</div>
                                </div>
                            </template>
                            <template v-else-if="column.formatter"> 
                                {{ column.formatter(scope.row, column) }}
                            </template>
                            <template v-else>
                                <template v-if="column.dicUrl">
                                    <div class="flex gap-2">
                                        <template v-if="dictCache[column.dicUrl]">
                                            <template v-if="scope.row[column.prop] && scope.row[column.prop].includes(',')">
                                                <el-tag 
                                                    v-for="value in scope.row[column.prop].split(',')" 
                                                    :key="value"
                                                    type="info"
                                                >
                                                    {{ getDictLabel(value.trim(), column.dicUrl) }}
                                                </el-tag>
                                            </template>
                                            <template v-else>
                                                <el-tag type="info">
                                                    {{ getDictLabel(scope.row[column.prop], column.dicUrl) }}
                                                </el-tag>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <el-skeleton-item variant="text" style="width: 60px" />
                                        </template>
                                    </div>
                                </template>
                                <template v-else>
                                    {{ formatColumnValue(scope.row[column.prop], column) }}
                                </template>
                            </template>
                        </template>
                    </template>
                </el-table-column>
            </template>

            <!-- 添加自定义操作列 -->
            <el-table-column
                v-if="$slots.menu"
                label="操作"
                :width="option.menuWidth || 200"
                fixed="right"
                align="center"
            >
                <template #default="scope">
                    <slot name="menu" v-bind="scope"></slot>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="sc-table__pagination" v-if="page">
            <el-pagination
                size="small"
                background
                v-model:current-page="page.current"
                v-model:page-size="page.size"
                :total="page.total"
                :page-sizes="[10, 20, 30, 50]"
                layout="total, sizes, prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </div>

        <!-- 弹窗 -->
        <el-dialog
            v-model="dialogVisible"
            :title="dialogTitle"
            width="60%"
            :close-on-click-modal="option.dialogClickModal !== false"
            :destroy-on-close="true"
        >
            <component
                :is="dialogComponent"
                ref="form"
                :option="option"
                v-model="formData"
                :col-span="12"
                @submit="handleSubmit"
                @cancel="dialogVisible = false"
            />
        </el-dialog>
    </div>
</template>

<script>
import { Plus, Delete, Search, Refresh } from '@element-plus/icons-vue'
import ScAdd from './add.vue'
import ScEdit from './edit.vue'
import ScView from './view.vue'
import axios from '@/axios'

export default {
    name: 'ScTable',
    components: {
        Plus,
        Delete,
        Search,
        Refresh,
        ScAdd,
        ScEdit,
        ScView
    },
    props: {
        option: {
            type: Object,
            required: true
        },
        data: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        tablesize: {
            type: String,
            default: 'large',
        },
        page: { 
            type: Object,
            default: null
        },
        permissionList: {
            type: Object,
            default: () => ({
                addBtn: true,
                viewBtn: true,
                editBtn: true,
                delBtn: true
            })
        },
        rowSave: {
            type: Function,
            default: null
        },
        rowUpdate: {
            type: Function,
            default: null
        },
        rowDel: {
            type: Function,
            default: null
        },
        search: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        showSelection() {
            return this.option.selection !== false
        },
        showIndex() {
            return this.option.index !== false
        },
        visibleColumns() {
            let columns = this.option.column.filter(col => !col.hide)
            
            if (!this.$slots.menu && this.option.operateBtn !== false) {
                columns.push({
                    type: 'operate',
                    label: '操作',
                    width: this.option.menuWidth || 300,
                    fixed: 'right',
                    buttons: [
                        {
                            label: '查看',
                            type: 'primary',
                            link: true,
                            function: 'rowView',
                            permission: 'viewBtn'
                        },
                        {
                            label: '编辑',
                            type: 'primary',
                            link: true,
                            function: 'rowEdit',
                            permission: 'editBtn'
                        },
                        {
                            label: '删除',
                            type: 'danger',
                            link: true,
                            function: 'handleRowDel',
                            permission: 'delBtn'
                        }
                    ]
                })
            }
            
            return columns
        },
        // 获取需要搜索的列
        searchColumns() {
            return this.option.column.filter(item => item.search)
        },
        dialogTitle() {
            const titles = {
                add: '新增',
                edit: '编辑',
                view: '查看'
            }
            return titles[this.dialogType]
        },
        dialogComponent() {
            const components = {
                add: 'sc-add',
                edit: 'sc-edit',
                view: 'sc-view'
            }
            return components[this.dialogType]
        }
    },
    emits: ['on-load', 'selection-change', 'row-save', 'row-update', 'row-del', 'update:search'],
    data() {
        return {
            selectedRows: [],
            dialogVisible: false,
            dialogType: '',
            formData: {},
            size: 'small',
            dictCache: {}
        }
    },
    created() {
        this.loadDictionaryData()
    },
    mounted() {
        // 组件挂载时自动加载数据
        this.$emit('on-load', this.search);
    },
    methods: {
        handleSizeChange(val) {
            this.page.size = val
            this.$emit('on-load', this.search)
        },
        handleCurrentChange(val) {
            this.page.current = val
            this.$emit('on-load', this.search)
        },
        // 处理多选变化
        handleSelectionChange(selection) {
            this.selectedRows = selection
            this.$emit('selection-change', selection)
        },
        // 计算序号
        calculateIndex(index) {
            if (this.page) {
                const currentPage = this.page.current || this.page.currentPage;
                const pageSize = this.page.size || this.page.pageSize;
                
                return (currentPage - 1) * pageSize + index + 1;
            }
            return index + 1;
        },
        // 格式化列值
        formatColumnValue(value, column) {
            if (column.type === 'number' && (value === null || value === undefined)) {
                return 0
            }
            return value
        },
        // 处理搜索
        handleSearch() {
            if (this.page) {
                this.page.current = 1
            }
            debugger
            this.$emit('on-load', this.search)
        },

        // 重置搜索
        handleReset() {
            this.$refs.searchForm.resetFields()
            this.$emit('update:search', {})
            this.$emit('on-load', {})
        },
        // 添加获取可见按钮的方法
        getVisibleButtons(buttons) {
            if (!buttons) return []
            return buttons.filter(btn => {
                // 根据按钮的权限属性检查权限
                return this.permissionList[btn.permission]
            })
        },
        handleBtnClick(btn, row) {
            this[btn.function](row)
        },
        // 处理新增按钮点击
        rowAdd() {
            this.dialogType = 'add'
            this.formData = {}
            this.dialogVisible = true
        },

        // 处理编辑按钮点击
        rowEdit(row) {
            this.dialogType = 'edit'
            this.formData = { ...row }
            this.dialogVisible = true
        },

        // 处理查看按钮点击
        rowView(row) {
            this.dialogType = 'view'
            this.formData = { ...row }
            this.dialogVisible = true
        },

        // 处理表单提交
        async handleSubmit(formData) {
            this.dialogVisible = false
            this.$emit(this.dialogType === 'add' ? 'row-save' : 'row-update', formData)
        },

        // 处理单行删除，从操作列按钮触发
        handleRowDel(row) {
            this.$emit('row-del', row.id)
        },

        // 处理批量删除，从顶部按钮触发
        handleBatchDelete() {
            if (!this.selectedRows.length) return
            const ids = this.selectedRows.map(row => row.id).join(',')
            this.$emit('row-del', ids)
        },

        // 添加 toggleSelection 方法
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.table.toggleRowSelection(row)
                })
            } else {
                this.$refs.table.clearSelection()
            }
        },
        async loadDictionaryData() {
            for (const column of this.option.column) {
                if (column.dicUrl && !this.dictCache[column.dicUrl]) {
                    try {
                        const response = await axios.get(column.dicUrl)
                        if (response.data.success) {
                            this.dictCache[column.dicUrl] = response.data.data
                        }
                    } catch (error) {
                        console.error('Failed to load dictionary data:', error)
                    }
                }
            }
        },
        getDictLabel(value, dicUrl) {
            if (!value || !this.dictCache[dicUrl]) return value
            const dictItem = this.dictCache[dicUrl].find(
                item => item.dictKey === value || item.id === value
            )
            return dictItem ? dictItem.dictValue : value
        }
    }
}
</script>
<style>
.el-table__border-bottom-patch, .el-table__border-left-patch {
    background-color: var(--el-table-border-color);
    left: 0;
    position: absolute;
    z-index: calc(var(--el-table-index) + 2);
    display: none !important;
}
</style>
<style lang="scss" scoped> 
:deep(.el-table__border-bottom-patch,.el-table .el-table__border-left-patch) {
    display: none !important;
}
:deep(.action-buttons) {
  display: flex;
  gap: 8px;
  
  .action-btn {
    height: 36px;
    padding: 0 16px;
    border: none;
    background: transparent;
    
    .btn-content {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .icon-wrapper {
      width: 32px;
      height: 32px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      .el-icon {
        font-size: 16px;
      }
    }

    span {
      font-size: 14px;
      white-space: nowrap;
    }

    &.message-btn {
     
      .icon-wrapper {
        background: rgba(255, 171, 93, 0.1);
        color: #FFAB5D;
      }
      &:hover {
        .icon-wrapper {
          background: rgba(255, 171, 93, 0.2);
        }
      }
    }

    &.add-btn {
      .icon-wrapper {
        width: 100px;
        background: #525CE519 ; 
        color: #525CE5;
        font-weight: 400;
      }
      &:hover {
        .icon-wrapper {
          background: rgba(67, 24, 255, 0.2);
        }
      }
    }
  }
}
:deep(.el-table__border-bottom-patch, .el-table__border-left-patch) {
    display: none !important;
}
.sc-table {
    font-family: 'Nunito', sans-serif;
    background-color: transparent !important;
    // 去除表格整体边框
    border: none !important;
    
    // 去除表格单元格边框
    :deep(.el-table__cell) {
        border: none !important;
    }

    

    :deep(.el-table.el-table--border .el-table__inner-wrapper::after) {
  display: none !important;
}
    // 去除表格的伪元素边框
    &::before,
    &::after {
        display: none !important;
    }

    // 去除横向边框线
    :deep(.el-table__inner-wrapper::before,.el-table__inner-wrapper:after) {
        display: none !important;
    }

   
    // 去除内部边框线
    :deep(.el-table__border) {
        display: none !important;
    }

    // 除固定列的阴影（如果有的话）
    :deep(.el-table__fixed-right::before),
    :deep(.el-table__fixed::before) {
        display: none !important;
    }
    .el-table  {
  background-color: transparent !important;
 
  // 去除表格边框
  &::before,
  &::after {
    display: none !important;
  }
  
  // 隐藏表头
  // 表头样式
  :deep(.el-table__header-wrapper) {
    display: block !important; // 显示表头
    tr{
        background-color: transparent !important;  
    }
    th.el-table__cell {
      background-color: transparent !important;
      border: none !important;
      padding: 14px !important;
      font-weight: 400 !important;
      color: #1E253C !important;
      font-size: 14px;
      white-space: nowrap;
      // 表头排序图标样式
      .sort-caret-wrapper {
        height: 20px;
        width: 20px;
        position: relative;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        margin-left: 4px;
        
        &::after {
          content: '';
          position: absolute;
          right: 0;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #666;
        }
      }

      // 当排序列的样式
      &.ascending .sort-caret-wrapper::after {
        transform: rotate(180deg);
      }
    }
  }

  // 去除表头底部边框
  :deep(.el-table__header) {
    border-bottom: none;
  }
  
  // 表格主体
  ::v-deep .el-table__body-wrapper {
    background-color: transparent !important;
    
    
}
::v-deep .el-table__body {
    background-color: transparent !important;
    border-collapse: separate !important;
  border-spacing: 0 12px !important;
  
}
 
::v-deep td.el-table__cell {
    background-color: white !important;
    border: 20px !important;
    padding: 16px 16px;
    height: 70px;
    white-space: normal !important;
    max-width: 0;
    // 第一个单元格圆角
    &:first-child {
        border-top-left-radius: 16px !important;
        border-bottom-left-radius: 16px !important;
    }

    // 最后一个单元格圆角
    &:last-child {
        border-top-right-radius: 16px !important;
        border-bottom-right-radius: 16px !important;
    }
    }

    // 行间距
    & + .el-table__row {
    margin-top: 8px;
    }

    
}  



  // 默认样式
  &--large {
        :deep(.custom-checkbox) {
            .el-checkbox__inner {
                width: 20px;
                height: 20px;
                border-radius: 4px;
                border: 1px solid #dcdfe6;
                
                &::after {
                    height: 10px;
                    width: 5px;
                    left: 6px;
                    top: 2px;
                    border-color: white; // 勾选的颜色改为白色
                }
            }

            // 选中状态的样式
            &.is-checked .el-checkbox__inner {
                background-color: #4086F4; // 使用蓝色背景
                border-color: #4086F4;
            }

            // 鼠标悬停状态
            &:hover .el-checkbox__inner {
                border-color: #4086F4;
            }
        }
  }
}

.sc-table {
    :deep(.el-table__header-wrapper) {
        margin-bottom: 0;  // 移除底部边距
    }

    :deep(.el-table__body-wrapper) {
        margin-top: -18px;  // 设置一个较小的顶部边距
    }

    // 如果上面的方法不生效，可以尝试这个
    :deep(.el-table__header) {
        margin-bottom: 8px !important;  // 直接设置表头底部边距
    }

    // 确保第一行的样式正确
    :deep(.el-table__row:first-child) {
        td {
        padding-top: 8px;  // 调整第一行的顶部内边距
        }
    }

    // 如果使用了 border-spacing，也可以调整它
    :deep(.el-table__body) {
        border-spacing: 0 8px !important;  // 调整行间距
    }
  // 表格容器样式
  ::v-deep .el-table {
    background-color: #fff;
    border: none !important;
    
    // 移除默认边框
    &::before,
    &::after {
      display: none;
    }
    
    // 表格行边框和背景色
     // 减少表头和内容之间的间距
   
    // 表头样式
    .el-table__header-wrapper {
      th.el-table__cell {
        background-color: #fff;
        border: none;
        font-weight: 600;
        color: #8F95B2;
        padding: 16px;
        font-size: 14px;
        
        // 表头排序图标
        .sort-caret-wrapper {
          height: 16px;
          .sort-caret.ascending {
            border-bottom-color: #8F95B2;
          }
          .sort-caret.descending {
            border-top-color: #8F95B2;
          }
        }
      }
    }
    td.el-table__cell{
        background-color: #fff !important;
    }

    // 表格主体
    .el-table__body-wrapper {
      background-color: transparent;
      
      // 表格行
      .el-table__row {
        background-color: transparent; 
        cursor: pointer;
       
      
        &:hover {
            background-color: #525CE519  !important;
        }
        
        
        // 单元格样式
        td.el-table__cell {
          padding: 16px;
          border-bottom: 1px solid #525CE519;
          font-size: 14px;
          color: #2B3674;
          
          // 移除单元格边框
          &::before {
            display: none;
          }
        }
      }
      
      // 斑马纹样式
      .el-table__row--striped {
        background-color: #F8F9FE;
      }
    }
    
    // 选择框样式
    .el-checkbox__inner {
      border-radius: 4px;
      border-color: #E0E5F2;
    }
  }
  
  // 状态标签样式
  .status-tag {
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 12px;
    
    &.unpaid {
      color: #6C5DD3;
      background: rgba(108, 93, 211, 0.1);
    }
    
    &.pending {
      color: #FFB547;
      background: rgba(255, 181, 71, 0.1);
    }
    
    &.paid {
      color: #05CD99;
      background: rgba(5, 205, 153, 0.1);
    }
  }
  
  // 操作按钮样式
  .action-button {
    color: #2B3674;
    
    &:hover {
      color: #6C5DD3;
    }
  }
  
  // 分页器样式
  .sc-table__pagination {
    margin-top: 20px;
    
    :deep(.el-pagination) {
      .el-pagination__total,
      .el-pagination__sizes {
        margin-right: 16px;
      }
      
      .el-pagination__jump {
        margin-left: 16px;
      }
      
      .el-pager li {
        border-radius: 4px;
        
        &.active {
          background-color: #6C5DD3;
        }
      }
    }
  }
}

:deep(.flex.gap-2) {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
}

:deep(.el-tag) {
    margin: 2px 0;
    white-space: nowrap;
}
</style> 