<template>
    <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        :label-width="labelWidth"
        class="sc-form"
    >
        <el-row :gutter="20">
            <el-col 
                v-for="(item, index) in visibleColumns" 
                :key="index"
                :span="colSpan"
            >
                <el-form-item 
                    :label="item.label"
                    :prop="item.prop"
                >
                    <!-- 输入框 -->
                    <el-input 
                        v-if="item.type === 'input'"
                        v-model="formData[item.prop]"
                        :placeholder="'请输入' + item.label"
                        :readonly="item.editReadonly"
                        :class="{ 'is-readonly': item.editReadonly }"
                    />
                    
                    <!-- 数字输入框 -->
                    <el-input-number
                        v-if="item.type === 'number'"
                        v-model="formData[item.prop]"
                        :min="item.min ?? 0"
                        :max="item.max"
                        :placeholder="'请输入' + item.label"
                        :disabled="item.editReadonly"
                        :controls-position="item.controlsPosition || 'right'"
                        style="width: 100%"
                    />
                    
                    <!-- 选择器 -->
                    <el-select
                        v-if="item.type === 'select'"
                        v-model="formData[item.prop]"
                        :placeholder="'请选择' + item.label"
                        :disabled="item.editReadonly"
                    >
                        <el-option
                            v-for="dict in item.dicData"
                            :key="dict.value"
                            :label="dict.label"
                            :value="dict.value"
                        />
                    </el-select>

                    <!-- 日期选择器 -->
                    <el-date-picker
                        v-if="item.type === 'date'"
                        v-model="formData[item.prop]"
                        type="date"
                        :placeholder="'请选择' + item.label"
                        :disabled="item.editReadonly"
                    />
                </el-form-item>
            </el-col>
        </el-row>

        <el-form-item class="form-footer">
            <slot name="footer">
                <div class="footer-buttons">
                    <el-button 
                        type="primary" 
                        :loading="loading"
                        @click="debouncedSubmit"
                    >保存</el-button>
                    <el-button 
                        @click="$emit('cancel')"
                    >取消</el-button>
                </div>
            </slot>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    name: 'ScForm',
    props: {
        option: {
            type: Object,
            required: true
        },
        modelValue: {
            type: Object,
            default: () => ({})
        },
        labelWidth: {
            type: String,
            default: '120px'
        },
        colSpan: {
            type: Number,
            default: 24
        }
    },
    emits: ['update:modelValue', 'submit', 'cancel'],
    data() {
        return {
            formData: {},
            rules: {},
            loading: false,
            submitTimer: null
        }
    },
    watch: {
        modelValue: {
            handler(val) {
                // 处理默认值，特别是数字类型的字段
                const processedData = { ...val }
                this.visibleColumns.forEach(item => {
                    if (item.type === 'number' && (processedData[item.prop] === undefined || processedData[item.prop] === null)) {
                        processedData[item.prop] = 0
                    }
                })
                this.formData = processedData
            },
            immediate: true,
            deep: true
        },
        formData: {
            handler(val) {
                this.$emit('update:modelValue', val)
            },
            deep: true
        }
    },
    created() {
        this.initRules()
    },
    computed: {
        visibleColumns() {
            // 修改为 editDisplay 判断
            return this.option.column.filter(item => item.editDisplay !== false)
        }
    },
    methods: {
        initRules() {
            this.rules = this.visibleColumns.reduce((acc, item) => {
                if (item.required) {
                    acc[item.prop] = [{
                        required: true,
                        message: `请${item.type === 'select' ? '选择' : '输入'}${item.label}`,
                        trigger: item.type === 'select' ? 'change' : 'blur'
                    }]
                }
                return acc
            }, {})
        },

        // 添加防抖提交
        debouncedSubmit() {
            if (this.submitTimer) {
                clearTimeout(this.submitTimer)
            }
            
            this.submitTimer = setTimeout(() => {
                this.submitFormWithLoading()
            }, 300)
        },

        // 添加加载状态的表单提交
        async submitFormWithLoading() {
            if (this.loading) return
            
            this.loading = true
            try {
                await this.$refs.form.validate()
                await this.$emit('submit', this.formData)
            } catch (error) {
                // 验证失败时不做处理
            } finally {
                this.loading = false
            }
        },

        resetForm() {
            this.$refs.form.resetFields()
        }
    },
    
    // 组件销毁时清理定时器
    beforeDestroy() {
        if (this.submitTimer) {
            clearTimeout(this.submitTimer)
        }
    }
}
</script>

<style scoped>
.sc-form {
    padding: 20px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    position: relative;
}

.form-footer {
    text-align: center;
    margin-top: 20px;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    padding: 10px 0;
    margin-bottom: 0;
}

.footer-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

:deep(.el-form-item) {
    margin-bottom: 18px;
}

:deep(.el-select),
:deep(.el-date-picker),
:deep(.el-input) {
    width: 100%;
}

:deep(.is-readonly) {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #909399;
    cursor: not-allowed;
}

:deep(.is-readonly:hover) {
    border-color: #e4e7ed;
}

:deep(.is-readonly .el-input__inner) {
    cursor: not-allowed;
    background-color: #f5f7fa;
    color: #909399;
}

:deep(.el-form-item.form-footer) {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

:deep(.el-form-item.form-footer .el-form-item__content) {
  width: 100%;
  margin-left: 0 !important;  /* 覆盖默认的 margin-left */
}

.footer-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
}
</style>
