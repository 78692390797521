<template>
  <div class="avue-top">
    <div class="top-bar__left">
      <div
        class="avue-breadcrumb"
        :class="[{ 'avue-breadcrumb--active': isCollapse }]"
        v-if="setting.collapse && !isHorizontal"
      >
        <i class="icon-navicon" @click="setCollapse"></i>
      </div>
    </div>
    <div class="top-bar__title">
      <router-link to="/edu/index" class="home-link">首页</router-link>
      <!-- <top-menu ref="topMenu" v-if="setting.menu"></top-menu> -->
      <!-- <top-search class="top-bar__item" v-if="setting.search"></top-search> -->
    </div>
    <div class="top-bar__right">
      <!-- <div class="top-bar__item" v-if="setting.debug">
        <firm-top-notification></firm-top-notification>
      </div> -->
      <div class="top-user">
        <img class="top-bar__img" :src="userInfo.avatar" />
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ userInfo.real_name }}
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <router-link to="/">{{ $t('navbar.dashboard') }}</router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/firm/user/info">{{ $t('navbar.userinfo') }}</router-link>
              </el-dropdown-item>
              <el-dropdown-item @click="logout" divided
                >{{ $t('navbar.logOut') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
          <!-- <top-setting></top-setting> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import firmTopNotification from './firm-top-notification.vue';
export default {
  components: {
    firmTopNotification,
  },
  name: 'top',
  data() {
    return {};
  },
  filters: {},
  created() {},
  computed: {
    ...mapGetters([
      'setting',
      'userInfo',
      'tagWel',
      'tagList',
      'isCollapse',
      'tag',
      'logsLen',
      'logsFlag',
      'isHorizontal',
    ]),
  },
  methods: {
    setCollapse() {
      this.$store.commit('SET_COLLAPSE');
    },
    logout() {
      this.$confirm(this.$t('logoutTip'), this.$t('tip'), {
        confirmButtonText: this.$t('submitText'),
        cancelButtonText: this.$t('cancelText'),
        type: 'warning',
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push({ path: '/portal' });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-bar__title {
  display: flex;
  align-items: center;
  height: 100%;
}

.home-link {
  font-size: 14px;
  color: #606266;
  text-decoration: none;
  padding: 5px 12px;
  border-radius: 4px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  height: 32px;
  
  &:hover {
    color: #409EFF;
    background-color: rgba(64, 158, 255, 0.1);
  }
}
</style>
