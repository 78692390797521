<template>
  <div v-if="show" class="editing-instructions-dialog">
       <!-- 添加关闭按钮 -->
       <button class="close-btn" @click="$emit('close')">×</button>
    
    <div class="dialog-content">
      <div class="editable-title" 
           contenteditable="true" 
           @input="onInput" 
           @keydown="onKeyDown" 
           @paste="onPaste"
           @blur="onBlur"
           ref="editableDiv"
           :placeholder="'输入表格指令，比如查询...修改...'"
           ></div>
      <span class="close-instruction">Esc to close</span>
      <div class="bottom-row">
        <button 
          class="submit-btn"
          :class="{ 'active': hasInput }"
          @click="submit">
          提交问题
        </button>
        <span class="model"> </span>
        <span class="toggle-instruction">⌘K to toggle</span>
      </div>
    </div>
    
    <MentionPopup
      v-if="showMentionPopup"
      :show="showMentionPopup"
      :popupStyle="mentionPopupStyle"
      :params="params"
      @select="selectItem"
    />
  </div>
</template>

<script>
import MentionPopup from '../aiApplication/components/MentionPopup.vue'; 
export default {
  name: 'EditingInstructionsDialog',
  components: {
    MentionPopup
  },
  props: {
    show: Boolean,
    params: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['submit'], 
  data() {
    return {
      showMentionPopup: false,
      mentionPopupStyle: {
        position: 'absolute',
        top: '0px',
        left: '0px'
      },
      lastCursorPosition: null
    };
  },
  computed: {
    hasInput() {
      return true;
      const editableDiv = this.$refs.editableDiv;
      if (!editableDiv) {
        return false;
      }
     
      const text = editableDiv.innerText.trim();
      console.log('trimmed text length:', text.length); // Debug log
      return text.length > 0;
    }
  },
  methods: {
    submit() {
     
      const content = this.$refs.editableDiv.innerText.trim();
      console.log('submit',content)
      if (this.hasInput) {
        this.$emit('submit', {
          content,
          type: 'ai_instruction',  // 可以传递更多参数
          loading: true // 添加loading状态
        }); // 触发事件并显示加载中...
      }
    },
    onInput(event) {
      const text = event.target.innerText;
      const lastChar = text.slice(-1);
      
      if (lastChar === '@') {
        this.showMentionPopup = true;
        this.$nextTick(() => {
          this.updatePopupPosition();
        });
      }
    },
    onKeyDown(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        // 处理回车键逻辑
      } else if (e.key === 'Escape') {
        if (this.showMentionPopup) {
          this.showMentionPopup = false;
        } else {
          this.$emit('close');
        }
      }
    },
    onPaste(e) {
      e.preventDefault();
      const text = e.clipboardData.getData('text/plain');
      document.execCommand('insertText', false, text);
    },
    updatePopupPosition() {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        this.mentionPopupStyle = {
          position: 'absolute',
          top: `${rect.bottom}px`,
          left: `${rect.left}px`
        };
      }
    },
    onBlur() {
      // 在失去焦点时保存光标位置
      this.saveCursorPosition();
    },
    saveCursorPosition() {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        this.lastCursorPosition = selection.getRangeAt(0).cloneRange();
      }
    },
    selectItem(item) {
      if (item.children) {
        this.currentItems = item.children;
        this.currentPath.push(item.name);
      } else {
        const mentionText = `@${item.name} `;
        this.insertMention(mentionText,item);
        this.showMentionPopup = false;
      }
    },
    insertMention(mentionText,item) {
      const editableDiv = this.$refs.editableDiv;
      const selection = window.getSelection();
      let range = this.lastCursorPosition;

      if (!range) {
        // 如果没有保存的光标位置，则在末尾插入
        range = document.createRange();
        range.selectNodeContents(editableDiv);
        range.collapse(false);
      }

      // 检查是否在已有的 mention-tag 内
      let currentNode = range.startContainer;
      while (currentNode !== editableDiv) {
        if (currentNode.nodeType === Node.ELEMENT_NODE && currentNode.classList.contains('mention-tag')) {
          // 如果在 mention-tag 内，将范围移到 mention-tag 之后
          range.setStartAfter(currentNode);
          range.setEndAfter(currentNode);
          break;
        }
        currentNode = currentNode.parentNode;
      }

      // 检查并删除前面的 "@" 符号
      let beforeRange = range.cloneRange();
      beforeRange.setStart(beforeRange.startContainer, Math.max(0, beforeRange.startOffset - 1));
      let beforeText = beforeRange.toString();
      if (beforeText === '@') {
        beforeRange.deleteContents();
      }

      const mentionNode = document.createElement('span');
      mentionNode.className = 'mention-tag';
      mentionNode.textContent = mentionText;
      mentionNode.setAttribute('data-type', item.type);
      mentionNode.setAttribute('data-id', item.id);
      mentionNode.setAttribute('data-url', item.fileLink);
      range.insertNode(mentionNode);
      
      // 将光标移动到插入的 mention 之后
      range = document.createRange();
      range.setStartAfter(mentionNode);
      range.setEndAfter(mentionNode);
      
      selection.removeAllRanges();
      selection.addRange(range);

      // 在 mention 后插入一个空格
      const spaceNode = document.createTextNode(' ');
      range.insertNode(spaceNode);
      range.setStartAfter(spaceNode);
      range.setEndAfter(spaceNode);

      editableDiv.focus();
      this.lastCursorPosition = null; // 重置保存的光标位置
    },
     
  }
};
</script>

<style scoped>
.editing-instructions-dialog {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 600px;
  background-color: rgba(30, 30, 30, 0.9);
  color: #ffffff;
  border-radius: 8px;
  padding: 10px 15px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dialog-content {
  display: flex;
  flex-direction: column;
}

.editable-title {
  outline: none;
  min-height: 24px;
  padding: 5px 0;
  font-weight: bold;
  color: #ffffff;
  background-color: transparent;
  border: none;
  font-size: 16px;
}

.editable-title:empty:before {
  content: attr(placeholder);
  color: #888;
}

.close-instruction {
  color: #888;
  font-size: 12px;
  align-self: flex-end;
  margin-top: 5px;
}

.bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.model {
  color: #888;
  font-size: 12px;
}

.toggle-instruction {
  color: #888;
  font-size: 12px;
}

.mention-tag {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2px 4px;
  border-radius: 3px;
  margin: 0 2px;
}

.submit-btn {
  background-color: #2D7EF7;  /* 蓝色按钮背景色 */
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 6px;
  cursor: not-allowed;
  opacity: 0.5;
  font-size: 12px;
  transition: all 0.2s;
}

.submit-btn.active {
  opacity: 1;
  cursor: pointer;
  background-color: #2D7EF7;  /* 激活状态的蓝色 */
}

.submit-btn.active:hover {
  background-color: #1e6fd9;  /* 悬停时稍微深一点的蓝色 */
}
</style>
