import { setStore, getStore } from 'utils/store';
import website from '@/config/website';

const navs = {
  state: {
    entTags: getStore({ name: 'entTags' }) || {}, // 用于存储每个企业的标签数据
    entTagWel: website.enterpriseFirstPage,
  },
  mutations: {
    ADD_ENT_TAG: (state, { enterpriseId, tag }) => {
      let curEntTags = state.entTags[enterpriseId] || { entTagList: [], entTag: {} };
      if (typeof tag.name == 'function') {
        tag.name = tag.name(tag.query);
      }
      curEntTags.entTag = tag;
      if (!curEntTags.entTagList.some(ele => ele.fullPath === tag.fullPath)) {
        curEntTags.entTagList.push(tag);
      }
      state.entTags[enterpriseId] = curEntTags;
      setStore({ name: `entTags`, content: state.entTags });
    },
    DEL_ENT_TAG: (state, { enterpriseId, fullPath }) => {
      const curEntTags = state.entTags[enterpriseId];
      if (curEntTags) {
        curEntTags.entTagList = curEntTags.entTagList.filter(item => item.fullPath !== fullPath);
        state.entTags[enterpriseId] = curEntTags;
        setStore({ name: `entTags`, content: state.entTags });
      }
    },
    DEL_ENT_ALL_TAG: (state, enterpriseId) => {
      const curEntTags = state.entTags[enterpriseId];
      if (curEntTags) {
        curEntTags.entTagList = [];
        state.entTags[enterpriseId] = curEntTags;
        setStore({ name: `entTags`, content: state.entTags });
      }
    },
    DEL_ENT_TAG_OTHER: (state, { enterpriseId, excludePaths }) => {
      const curEntTags = state.entTags[enterpriseId];
      if (curEntTags) {
        curEntTags.entTagList = curEntTags.entTagList.filter(item =>
          excludePaths.includes(item.fullPath)
        );
        state.entTags[enterpriseId] = curEntTags;
        setStore({ name: `entTags`, content: state.entTags });
      }
    },
  },
};


export default navs;
