import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '@/studyPage/MainStudyLayout.vue'
import Main from '@/studyPage/Main.vue'

const routes = [
  {
    path: '/studyApi',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'Welcome',
        component: Main
      },
      
      // 添加其他路由...
    ]
  },
  {
    path: '/studywordonline',
    component: () => MainLayout,
    redirect: '/studywordonline/index',
    children: [
      {
        path: 'index',
        name: 'studywordonline',
        meta: {
          i18n: 'studyApp',
          menuType: 'project'
          // 你可以根据需要添加或修改元信息
        },
        component: () => import(/* webpackChunkName: "views" */ '@/views/wordonline/index.vue'),
      }
    ]
  },
]
 
export default routes