<template>
  <div class="small-sidebar">
       <i class="icon"> </i>
      <el-menu
        class="sidebar-menu "
        :default-active="activeIndex"
        @select="handleSelect"
        background-color="#f0f0f0"
        text-color="#333"
        active-text-color="#409EFF">
        <el-menu-item index="1" @click="toggleSidebar">
          <el-icon><HomeFilled /></el-icon>
          <span slot="title">Conto...</span>
        </el-menu-item>
        <el-menu-item index="2">
          <el-icon><Menu /></el-icon>
          <span slot="title">Contri...</span>
        </el-menu-item>
        <el-menu-item index="3">
          <el-icon><Comment /></el-icon>
          <span slot="title">Marke...</span>
          <el-badge :value="2" class="item" type="primary"></el-badge>
        </el-menu-item>
        <el-menu-item index="4">
          <el-icon><Avatar /></el-icon>
          <span slot="title">Privat...</span>
        </el-menu-item>
      </el-menu>
    </div>
</template>
    
  <script>
  export default {
    name: 'SidebarSmall',
    data() {
      return {
        activeIndex: '1'
      };
    },
    methods: {
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },
      toggleSidebar() {
        console.log(2);
        this.$emit('toggle-sidebar');
      },
    }
  }
  </script>
  
  <style scoped>
  .logo-container {
  width: 24px;  /* 调整为合适的大小 */
  height: 24px; /* 调整为合适的大小 */
  background-image: url('/img/de-logo4.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px; /* 根据需要调整间距 */
}
  .icon{
    background-image: url('/img/de-logo44.jpg'); /* 替换为您的背景图片路径 */
    background-size: 80% ; /* 根据需要调整背景图片的大小 */
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center center; /* 将图片定位到左侧 */
    margin-bottom: 10px;
    margin-left: 5px;
  }
  .sidebar-menu {
    width: 64px;
    /*height: 100vh;*/
    transition: width 0.3s;
  }
  
  .sidebar-menu:hover {
    width: 200px;
  }
  
  .el-menu-item {
    height: 64px;
    line-height: 64px;
    padding: 0 !important;
  }
  
  .el-menu-item i {
    font-size: 20px;
    margin-left: 10px;
  }
  
  .el-menu-item span {
    margin-left: 0px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .el-menu-item .item{
    
  }
  /* 移除悬停效果 */
.sidebar-menu:hover {
  width: 64px;
}
  .sidebar-menu:hover .el-menu-item span {
    /*opacity: 1;*/
  }
  
  .el-badge {
    margin-top: -32px;
    margin-left: -55px;
}

  .small-sidebar{
  background-color: #fcfbfd!important;
  color: #8e9fac!important;
  padding-top: 10px;
}
  .small-sidebar .el-menu-item.is-active,.small-sidebar .el-sub-menu__title.is-active {
    background-color: #3c80fd!important;
    margin:10px!important;
    line-height: 20px!important;
    height: 40px!important;
    border-radius: 4px!important;
    color: #000!important;
}
.small-sidebar .el-menu-item.is-active > i,
.small-sidebar .el-sub-menu__title.is-active > i {
    margin-left: 10px!important;
    margin-right: 0px!important;
}
.small-sidebar .el-menu-item,.small-sidebar .el-sub-menu__title{
    background-color: #e4e8f2!important;
    margin:10px!important;
    margin-top: 20px!important;
    line-height: 20px!important;
    height: 40px!important;
    border-radius: 4px!important;
    color: #000!important;
}
 
.small-sidebar .el-menu-item i,    .small-sidebar .el-menu-item span,
   .small-sidebar .el-sub-menu__title i,   .small-sidebar .el-sub-menu__title span{
  color: #516574;
}
  .small-sidebar .el-menu-item:hover i, 
  .small-sidebar .el-menu-item:hover span,
  .small-sidebar .el-sub-menu__title:hover i,
  .small-sidebar .el-sub-menu__title:hover span {
  color: #1E90FF; /* 您想要的悬停颜色color: #8A2BE2; /* BlueViolet */  
}
  .small-sidebar .el-menu-item.is-active i,  .small-sidebar .el-menu-item.is-active span,   .small-sidebar .el-sub-menu__title.is-active i, .avue-sidebar .el-sub-menu__title.is-active span {
    color: #fff!important;;
}
   .el-dropdown-menu__item--divided:before,   .el-menu, .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover, .el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover {
  color: #000!important;;
}
  </style>