import { setStore, getStore } from '@/utils/store';
import { validatenull } from '@/utils/validate';
import { deepClone } from '@/utils/util';
import { getRoutes, getTopMenu } from '@/api/system/menu';
import { formatPath } from '@/router/avue-router';
import { ElMessage } from 'element-plus';
import md5 from 'js-md5';

const exam = {
  state: {
    enterpriseSelectedProject: getStore({name: 'enterpriseSelectedProject'}) || {},
  },
  actions: {
    
  },
  mutations: {
    SET_ENTERPRISE_CURRENT_PROJECT: (state, {enterpriseId, project}) => {
      state.enterpriseSelectedProject[enterpriseId] = project;
      setStore({name: 'enterpriseSelectedProject', content: state.enterpriseSelectedProject})
    },
  },
};
export default exam;
