<template>
  <div>
    <div class="main-layout">
      <LeftMenu />
      <router-view class="content-area"></router-view>
    </div>
    <div class="sidebar-container bg-white" :class="{ 'sidebar-collapsed': isSidebarOpen }">
      <el-aside :width="sidebarWidth + 'px'" v-loading="loading" class="">
        <el-scrollbar v-if="!selectedChat">
          <!-- 聊天历史 -->
          <div class="project">
            <div class="bg-white flex-default jc-sb padding-10 line-30 border-bottom align-left">
              <i class="icon"><b>历史信息</b></i>
              <i class="iconfont iconicon_more" @click="toggleChatWindow"></i>
            </div>
            <div class="padding-10 bg-white">
              <div
                v-for="(item, index) in chatHistory"
                :key="index"
                class="margin-b10 fs-14 margin-b10 padding-10 border-radius8 saber-relative"
              >
                <p class="line-25 pointer">{{ item.label }}</p>
                <ul>
                  <li
                    v-for="(session, sessionIndex) in item.list"
                    :key="sessionIndex"
                    class="saber-relative"
                    :class="{ active: isActive(item, session) }"
                    @click="getChatHistory(item, session)"
                  >
                    <p class="line-30 text-line1 fs-14 fc-666 pointer">{{ session.name }}</p>
                    <div class="delete align-center text-center jc-center">
                      <i class="iconfont iconicon_delete"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </el-scrollbar>
        <!-- 显示 chatWindow 组件 -->
        <div v-if="selectedChat" class="chat-window">
          <div class="project" style="position: absolute;top: 0;left: 0;width: 100%;">
            <div class="bg-white flex-default jc-sb padding-10 line-30 border-bottom align-left">
              <i class="icon"><b>历史信息</b></i>
              <i class="iconfont iconicon_more" @click="toggleChatWindow"></i>
            </div>
          </div>
          <el-container class="height-100">
            <chatWindow ref="chatWindow" :queryParam="queryParam"></chatWindow>
          </el-container>
        </div>
      </el-aside>
      <div class="resize-handle" @mousedown="startResize" :style="{ right: resizeHandlePosition }"></div>
    </div>
  </div>
</template>

<script>
import LeftMenu from './LeftMenu.vue'
import chatWindow from '@/views/aiApplication/chatWindow.vue'
import { getChatSessions } from '@/api/project/chatsession'
import { ref, onMounted } from 'vue'

export default {
  name: 'MainLayout',
  components: {
    LeftMenu,
    chatWindow
  },
  data() {
    return {
      loading: false,
      sidebarWidth: 250,
      isSidebarCollapsed: false,
      chatHistory: [],
      selectedChat: null,
      queryParam: {},
      projectId: '',
      resizeStartX: 0,
      initialSidebarWidth: 250,
    }
  },
  computed: {
    isSidebarOpen() {
      return !this.isSidebarCollapsed
    },
    resizeHandlePosition() {
      return this.isSidebarCollapsed ? '0px' : `${this.sidebarWidth - 1}px`
    }
  },
  methods: {
    async fetchChatSessions() {
      try {
        const response = await getChatSessions(this.projectId)
        this.chatHistory = this.formatChatSessions(response.data.data)
      } catch (error) {
        console.error('Failed to fetch chat sessions:', error)
      }
    },
    formatChatSessions(sessions) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      const twoDaysAgo = new Date(today)
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2)

      const formatDate = (dateString) => {
        const date = new Date(dateString)
        if (isNaN(date.getTime())) {
          console.error('Invalid date:', dateString)
          return 'Invalid Date'
        }
        date.setHours(0, 0, 0, 0)

        if (date.getTime() === today.getTime()) {
          return '今天'
        } else if (date.getTime() === yesterday.getTime()) {
          return '昨天'
        } else if (date.getTime() === twoDaysAgo.getTime()) {
          return '前天'
        } else {
          return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
        }
      }

      const groupedSessions = sessions.reduce((acc, session) => {
        const formattedDate = formatDate(session.create_time)
        if (!acc[formattedDate]) {
          acc[formattedDate] = []
        }
        acc[formattedDate].push(session)
        return acc
      }, {})

      return Object.entries(groupedSessions).map(([date, list]) => ({
        label: date,
        list: list.map(session => ({ name: session.session_name, id: session.id }))
      }))
    },
    isActive(item, session) {
      return this.selectedChat && 
             this.selectedChat.item.label === item.label && 
             this.selectedChat.session.id === session.id
    },
    getChatHistory(item, session) {
      this.selectedChat = { item, session }
      this.sidebarWidth = 600
      this.$nextTick(() => {
        if (this.$refs.chatWindow) {
          this.$refs.chatWindow.getHistory(item, session)
        }
      })
    },
    toggleChatWindow() {
      if (this.selectedChat) {
        this.selectedChat = null
        this.sidebarWidth = 250
      } else {
        this.isSidebarCollapsed = !this.isSidebarCollapsed
      }
    },
    startResize(e) {
      this.resizeStartX = e.clientX
      this.initialSidebarWidth = this.sidebarWidth
      document.addEventListener('mousemove', this.resize)
      document.addEventListener('mouseup', this.stopResize)
    },
    resize(e) {
      const dx = window.innerWidth - e.clientX
      this.sidebarWidth = Math.max(200, Math.min(dx, 800))
    },
    stopResize() {
      document.removeEventListener('mousemove', this.resize)
      document.removeEventListener('mouseup', this.stopResize)
    },
  },
  created() {
    this.projectId = this.$route.query.projectId || ''
    this.fetchChatSessions()
  },
  watch: {
    $route: {
      handler() {
        this.$nextTick(() => {
          console.log('Route changed, initializing chat window')
          if (this.$refs.chatWindow && this.$refs.chatWindow.initializeChat) {
            this.$refs.chatWindow.initializeChat(this.$route.query)
          }
        })
      },
      immediate: true
    },
    sidebarWidth: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          setTimeout(() => {
            const event = new Event('resize')
            window.dispatchEvent(event)
          }, 100)
        }
      }
    }
  }
}
</script>

<style scoped>
.main-layout {
  display: flex;
  height: 100vh;
  background: url(/img/background.png) #f7f8ff;
  background-size: cover;
  transition: all 0.3s ease, margin-left 0s;
}

.content-area {
  flex-grow: 1;
  overflow-y: auto;
}

.sidebar-container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  transition: width 0.3s;
}

.resize-handle {
  position: absolute;
  top: 0;
  width: 5px;
  height: 100%;
  cursor: col-resize;
  background-color: #e0e0e0;
}

.chat-window {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project ul li {
  position: relative;
  padding: 0px 10px;
  cursor: pointer;
}

.project ul li:hover,
.project ul li.active {
  background-color: #f0f0f0;
}

.project ul li .delete {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 30px;
  background-color: #fff;
  align-items: center;
  justify-content: center;
}

.project ul li:hover .delete {
  display: flex;
}
.project-title{
  color: #333;
  background-image: none!important;
  line-height: 50px;
  margin-bottom: 30px;
  width: 200px;
  font-size: 18px;
  padding-left: 20px;
}
.project {
  .creat {
    background-color: #cfddf7;
  }
  ul li div.delete {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    background-color: #fff;
    color: #666;
    display: none;
    transition: 0.3s;
  }
  ul li:hover div.delete {
    display: flex;
  }
  ul li i:hover {
    color: #006cff;
    cursor: pointer;
  }
}
.chat-window {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.el-aside{
    height: 100%;
}
</style>