<template>
  <div class="avue-tags"  @click="contextmenuFlag = false" style="margin-bottom: 0px;">
    <!-- tag盒子 -->
   
    <div
      v-if="contextmenuFlag"
      class="avue-tags__contentmenu"
      :style="{ left: contentmenuX + 'px', top: contentmenuY + 'px' }"
    >
      <div class="item" @click="closeOthersTags">{{ $t('tagsView.closeOthers') }}</div>
      <div class="item" @click="closeAllTags">{{ $t('tagsView.closeAll') }}</div>
      <div class="item" @click="clearCacheTags">{{ $t('tagsView.clearCache') }}</div>
    </div>
    <div class="avue-tags__box">
      <el-tabs
        v-model="active"
        type="card"
        @contextmenu="handleContextmenu"
        :closable="tagLen !== 1"
        @tab-click="openTag"
        @edit="menuTag"
      >
        <!-- Default "项目主页" tab -->
         <!--
        <el-tab-pane
          :key="'home'"
          label="项目主页"
          name="projectHome"
        >
          <template #label>
            <i :class="['icon2 bot']"></i>
            <span>项目主页</span>
          </template>
        </el-tab-pane>
        -->
        <el-tab-pane
          v-for="(item, index) in projectTagList"
          :key="index"
          :label="generateTitle(item)"
          :name="item.fullPath"  
          :closable="index !== 0"
        >
          <template #label>
            <el-icon><Files /></el-icon>
            <span class="tab-text" style="margin-left: 5px;">{{ generateTitle(item) }}</span>
            <span class="tab-icons"></span>
          </template>
        </el-tab-pane>
      </el-tabs>
      
      <el-dropdown class="avue-tags__menu">
        <el-button  >
         <!--{{ $t('tagsView.menu') }}  --> 
          <el-icon  ><MoreFilled /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="openSearch">{{ $t('tagsView.search') }}</el-dropdown-item>
            <el-dropdown-item @click="closeOthersTags"
              >{{ $t('tagsView.closeOthers') }}
            </el-dropdown-item>
            <el-dropdown-item @click="closeAllTags">{{ $t('tagsView.closeAll') }}</el-dropdown-item>
            <el-dropdown-item @click="clearCacheTags"
              >{{ $t('tagsView.clearCache') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div class="avue-btn-box"> 
        <el-tooltip content="查看历史对话" placement="bottom" :show-after="100">
          <el-button @click="toggleSidebar">
            <el-icon><Clock /></el-icon>
          </el-button>
        </el-tooltip>
      </div>
      <div class="avue-btn-box btn-2" style="right: 90px;"> 
        <el-tooltip content="新建对话" placement="bottom" :show-after="100">
          <el-button @click="addChat">
            <el-icon><Plus /></el-icon> 
          </el-button>
        </el-tooltip>
      </div>
      <div class="avue-btn-box btn-2" style="right: 180px;"> 
        <el-tooltip content="AI初始化企业资料" placement="bottom" :show-after="100">
          <el-button @click="dosomeChat">
            <i class="ri-magic-line"></i>
          </el-button>
        </el-tooltip>
      </div>
      <div class="avue-btn-box btn-2" style="right: 135px;"> 
        <el-tooltip content="保存底稿" placement="bottom" :show-after="100">
          <el-button @click="dosaveExcel">
            <i class="ri-save-line"></i> 
          </el-button>
        </el-tooltip>
      </div>
      <!--
      <div class="avue-btn-box btn-2"> 
        <el-tooltip content="聊天" placement="bottom" :show-after="100">
          <el-button @click="ChatView">
            <el-icon><ChatDotRound /></el-icon> 
          </el-button>
        </el-tooltip>
      </div>
      -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { clearCache } from '@/api/user';
import { mapState, mapMutations } from 'vuex';
import { createChatSession,gen_table_csvformat } from '@/api/project/chatsession';

export default {
  name: 'projectTags',
  inject: ['projectIndex'],
  data() {
    return {
      refresh: false,
      active: '',
      contentmenuX: '',
      contentmenuY: '',
      contextmenuFlag: false,
    };
  },
  watch: {
    projectTag: {
      handler(val) {
        this.active = val.fullPath;
      },
      immediate: true,
    },
    contextmenuFlag() {
      window.addEventListener('mousedown', this.watchContextmenu);
    }  
  },
  computed: {
    ...mapGetters([
      'setting',
      'userInfo', 
    ]),
    currentProjectId(){
      return this.$route.query.projectId;
    },
    currentEnterpriseId(){
      return this.$route.query.enterpriseId;
    },
    projectTag() {
      return this.$store.getters.projectTag(this.currentProjectId);
    },
    projectTagList() {
      
      return this.$store.getters.projectTagList(this.currentProjectId);
    },
    ...mapGetters(['projectTagWel', 'setting']),
    tagLen() {
      return this.projectTagList.length || 0;
    }, 
    ...mapState({
      isSidebarOpen: state => state.projectTags.isSidebarOpen
    }),
  },
  methods: {
    ...mapMutations( ['toggleSidebar']),
    addChat(name,_callback){
      if (!name || typeof name !== 'string') {
        const now = new Date();
        const dateStr = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
        name = `新对话 ${dateStr}`;
      }
      createChatSession(this.currentProjectId, name,{
        create_user:this.userInfo.user_id,
        tenant_id:this.userInfo.tenant_id,

      },{
        project_id:this.currentProjectId,
        enterprise_id:this.currentEnterpriseId
      }).then(res => {
        console.log('res', res)
        this.projectIndex.refreshChatSessions()
        if(typeof _callback === 'function'){
          this.$nextTick(_callback())
        }
      })
    },
    openSearch() {
      console.log('this.$store.state.isSidebarOpen',this.$store.state)
      this.$store.commit('SET_IS_SEARCH', true);
    },
    handleRefresh() {
      this.refresh = true;
      this.$store.commit('SET_IS_REFRESH', false);
      setTimeout(() => {
        this.$store.commit('SET_IS_REFRESH', true);
      }, 100);
      setTimeout(() => {
        this.refresh = false;
      }, 500);
    },
    generateTitle(item) {
      return this.$router.$avueRouter.generateTitle({
        ...item,
        ...{
          label: item.name,
        },
      });
    },
    watchContextmenu(event) {
      if (!this.$el.contains(event.target) || event.button !== 0) {
        this.contextmenuFlag = false;
      }
      window.removeEventListener('mousedown', this.watchContextmenu);
    },
    handleContextmenu(event) {
      let target = event.target;
      let flag = false;
      if (target.className.indexOf('el-tabs__item') > -1) flag = true;
      else if (target.parentNode.className.indexOf('el-tabs__item') > -1) {
        target = target.parentNode;
        flag = true;
      }
      if (flag) {
        event.preventDefault();
        event.stopPropagation();
        this.contentmenuX = event.clientX;
        this.contentmenuY = event.clientY;
        this.tagName = target.getAttribute('aria-controls').slice(5);
        this.contextmenuFlag = true;
      }
    },
    dosaveExcel(){
      console.log('dosaveExcel')
      // window.luckysheet.exportExcel()
      // 触发保存底稿
      const event = new KeyboardEvent('keydown', {
        key: 'j',
        ctrlKey: true
      });
      window.dispatchEvent(event);
    },
    async dosomeChat(){
      const projectId = this.currentProjectId;
      try {
          const response = await gen_table_csvformat(projectId,this.currentEnterpriseId);
          console.log('gen_table_struction', response);
          
           
          // 这里可以进行进一步的处理，比如更新 UI 等
        } catch (error) {
          console.error('Error in gen_table_struction:', error);
        }
    },
    menuTag(value, action) {
      if (action === 'remove') {
        let { tag, key } = this.findTag(value);
        this.$store.commit('DEL_PROJECT_TAG', {projectId: this.currentProjectId,fullPath:tag.fullPath});
       
        if (tag.fullPath === this.projectTag.fullPath) {
          if(this.projectTagList.length > 0){
            tag = this.projectTagList[key - 1];
            
          } else { 
            tag = this.projectTagWel 
            tag.query = {
              projectId:this.currentProjectId,
              enterpriseId:this.currentEnterpriseId
            }
          }
          
          this.$router.push({
              path: tag.path,
              query: tag.query,
            });
        }
      }
    },
    openTag(item) {
      let value = item.props.name;
      let { tag } = this.findTag(value);
      if(value=='projectHome') {
        tag = this.projectTagWel 
            tag.query = {
              projectId:this.currentProjectId,
              enterpriseId:this.currentEnterpriseId
            }
      }
      this.$router.push({
        path: tag.path,
        query: tag.query,
      });
    },
    findTag(fullPath) {
      let tag = this.projectTagList.find(item => item.fullPath === fullPath);
      let key = this.projectTagList.findIndex(item => item.fullPath === fullPath);
      return { tag, key };
    },
    closeOthersTags() {
      this.contextmenuFlag = false;
      this.$store.commit('DEL_PROJECT_TAG_OTHER',{projectId: this.currentProjectId});
    },
    closeAllTags() {
      this.contextmenuFlag = false;
      this.$store.commit('DEL_PROJECT_ALL_TAG',this.currentProjectId);
      this.$router.push(this.projectTag);
    },
    clearCacheTags() {
      this.$confirm('是否需要清除缓存?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        clearCache().then(() => {
          this.contextmenuFlag = false;
          this.$message.success('清除完毕');
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ul-title{
  display: inline-block;
}
.tab-text {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-icons {
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.avue-tags{
  background-color: transparent;
}
.avue-tags__box{
  height: 50px;
}
.avue-tags__menu{
    position: absolute !important;
    top: 10px;
    right: 0px;
    padding: 1px 0 0 15px;
    box-sizing: border-box;
}
.avue-btn-box {
    position: absolute !important;
    top: 10px;
    right: 45px;
    padding: 1px 0 0 15px;
    box-sizing: border-box;
}
::v-deep .el-tabs__nav {
  margin-top: 10px;
} 
 .avue-tags .el-tabs--card > .el-tabs__header{
  margin-top: 0px;
}
::v-deep .avue-tags .el-tabs--card > .el-tabs__header .el-tabs__item{
     padding-left: 10px!important;  
     padding-right: 10px!important
}
::v-deep .avue-tags .el-tabs--card > .el-tabs__header .el-tabs__item:hover{
     padding-left: 10px!important;  
     padding-right: 10px!important
}
::v-deep .avue-tags .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
     padding-left:10px;  
     padding-right: 10px;  
}
::v-deep  .el-tabs--card>.el-tabs__header .el-tabs__item .is-icon-close{
  width: 14px;
}

::v-deep {
  .el-tabs--card > .el-tabs__header .el-tabs__item.is-closable:hover {
    padding-left: initial;
    padding-right: initial;
  }
}
::v-deep .el-button{
  font-weight: 300;
  font-size: 14px;
  padding: 0px 10px;
  height: 30px;
  color: #888;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.avue-tags{
  box-shadow: none;
}

.el-icon{
  font-size: 16px;
}
.btn-2{
  right: 90px;
}
</style>
