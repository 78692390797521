import ScTable from './table.vue'
import ScAdd from './add.vue'
import ScEdit from './edit.vue'
import ScView from './view.vue'

export default {
    install(app) {
        app.component('sc-table', ScTable)
        app.component('sc-add', ScAdd)
        app.component('sc-edit', ScEdit)
        app.component('sc-view', ScView)
    }
} 