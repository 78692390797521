<template>
  <div class="avue-contail" :class="{ 'avue--collapse': isCollapse }">
    <div class="avue-layout" :class="{ 'avue-layout--horizontal': isHorizontal }">
      <div class="avue-sidebar" v-show="validSidebar">
        <!-- 左侧导航栏 -->
        <logo />
        <sidebar />
      </div>
      <div class="avue-main">
        <!-- 顶部导航栏 -->
        <top ref="top" />
        <!-- 顶部标签卡 -->
        <tags ref="eduTags" />
        <search class="avue-view" v-show="isSearch"></search>
        <!-- 主体视图层1 -->
        <div id="avue-view" v-show="!isSearch" v-if="isRefresh">
          <router-view #="{ Component }">
            <keep-alive :include="$store.getters.tagsKeep">
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>
      </div>
    </div>
    <!-- <wechat></wechat> -->
  </div>
</template>

<script>
import index from '@/mixins/index';
import wechat from './wechat.vue';
//import { validatenull } from 'utils/validate';
import { mapGetters } from 'vuex';
import tags from './eduTags.vue';
import search from './search.vue';
import logo from './logo.vue';
import top from './top/eduTop.vue';
import sidebar from './sidebar/eduSidebar.vue';

export default {
  mixins: [index],
  components: {
    top,
    logo,
    tags,
    search,
    sidebar,
    wechat,
  },
  name: 'index',
  provide() {
    return {
      index: this,
    };
  },
  computed: {
    ...mapGetters([
      'isHorizontal',
      'isRefresh',
      'isLock',
      'isCollapse',
      'isSearch',
      'menu',
      'setting',
      'userInfo',
    ]),
    validSidebar() {
      return !(
        (this.$route.meta || {}).menu === false || (this.$route.query || {}).menu === 'false'
      );
    },
  },
  props: [],
  methods: {
    //打开菜单
    openMenu(item = {}) {
      this.$store.dispatch('GetMenuByVisibilityScope', item.visibilityScope).then(data => {
        if (data.length !== 0) {
          this.$router.$avueRouter.formatRoutes(data, true);
        }
      });
    },
  },
};
</script>
