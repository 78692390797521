const getters = {
  tag: state => state.tags.tag,
  language: state => state.common.language,
  setting: state => state.common.setting,
  userInfo: state => state.user.userInfo,
  themeName: state => state.common.themeName,
  isMacOs: (state, getters) => getters.themeName === 'mac-os',
  isRefresh: state => state.common.isRefresh,
  isSearch: state => state.common.isSearch,
  isHorizontal: state => state.common.setting.sidebar === 'horizontal',
  isCollapse: state => state.common.isCollapse,
  isLock: state => state.common.isLock,
  isFullScren: state => state.common.isFullScren,
  isMenu: state => state.common.isMenu,
  lockPasswd: state => state.common.lockPasswd,
  tagList: state => state.tags.tagList,
  tagsKeep: (state, getters) => {
    return getters.tagList
      .filter(ele => {
        return (ele.meta || {}).keepAlive;
      })
      .map(ele => ele.fullPath);
  },
  tagWel: state => state.tags.tagWel,
  tenantType: state => state.user.tenantType,
  token: state => state.user.token,
  roles: state => state.user.roles,
  permission: state => state.user.permission,
  menuId: state => state.user.menuId,
  menu: state => state.user.menu,
  menuAll: state => state.user.menuAll,
  logsList: state => state.logs.logsList,
  logsLen: state => state.logs.logsList.length || 0,
  logsFlag: (state, getters) => getters.logsLen === 0,

  flowRoutes: state => state.dict.flowRoutes,
  financialStatementType: state => state.dict.financialStatementType,
  
  enterpriseExam: state => state.exam.enterpriseExam,
  firmWel: state => state.firm.firmWel,
  schoolWel: state => state.school.schoolWel,
  courseWel: state => state.courseTags.courseWel,
  tagCourse: state => state.courseTags.tagCourse,
  tagCourseList: state => state.courseTags.tagCourseList,
  schoolInfo: state => state.school.schoolInfo,
  entTag: (state) => (enterpriseId) => {
    return state.aiTags.entTags[enterpriseId]?.entTag || {};
  },
  entTagList: (state) => (enterpriseId) => {
    return state.aiTags.entTags[enterpriseId]?.entTagList || [];
  },
  entTagWel: (state) => {
    return state.aiTags.entTagWel;
  },
  projectTag: (state) => (projectId) => {
    return state.projectTags.projectTags[projectId]?.projectTag || {};
  },
  projectTagList: (state) => (projectId) => {
    return state.projectTags.projectTags[projectId]?.projectTagList || [];
  },
  projectTagWel: (state) => {
    return state.projectTags.projectTagWel;
  },
  currentProject: (state) => (enterpriseId) => {
    return state.exam.enterpriseSelectedProject[enterpriseId];
  },
  tagFirmList: (state) => {
    return state.firmTags.tagFirmList;
  },
  tagFirm: (state) => {
    return state.firmTags.tagFirm;
  },
  needRefresh: state => state.projectList.needRefresh,
  firmInfo: state => state.user.firmInfo,
  firmId: state => state.user.firmInfo?.id,
  portalUrl: state => state.user.portalUrl
};
export default getters;
