import { createStore } from 'vuex';
import user from './modules/user';
import common from './modules/common';
import tags from './modules/tags';
import aiTags from './modules/aiTags';
import logs from './modules/logs';
import dict from './modules/dict';
import exam from './modules/exam';
import projectTags from './modules/projectTags';
import firm from './modules/firm';
import firmTags from './modules/firmTags';
import courseTags from './modules/eduTags';
import projectList from './modules/projectList';
import school from './modules/school';
import getters from './getters';

const store = createStore({
  modules: {
    user,
    common,
    logs,
    tags,
    aiTags,
    dict,
    exam,
    projectTags,
    firm,
    firmTags,
    courseTags,
    projectList,
    school,
  },
  getters,
});

export default store;
