<template>
  <router-view />
</template>

<script>
import sseClient from '@/api/sse/sse.js'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      sseConnected: false
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {
    // 监听用户信息变化，处理登录/登出场景
    'userInfo.user_id': {
      handler(newUserId) {
        if (newUserId) {
          // 只在 tenantType 为 firm 时获取事务所信息
          if (this.$store.state.user.tenantType === 'firm') {
            this.$store.dispatch('GetFirmInfo').catch(err => {
              console.error('获取事务所信息失败:', err);
            });
          }
          // this.connectSse(newUserId);
        } else if (this.sseConnected) {
          this.disconnectSse();
        }
      },
      immediate: true
    }
  },
  methods: {
    connectSse(userId) {
      if (!this.sseConnected) {
        sseClient.connect(userId)
        sseClient.onMessage('app', this.handleSseMessage)
        this.sseConnected = true
      }
    },
    disconnectSse() {
      if (this.sseConnected) {
        sseClient.removeMessageHandler('app')
        sseClient.disconnect()
        this.sseConnected = false
      }
    },
    handleSseMessage(data) {
      try {
        switch(data.type) {
          case 'MENU_UPDATE':
            // 触发全局的菜单更新
            this.$store.dispatch('GetMenuByVisibilityScope', data.data.visibilityScope)
            break
          case 'NOTIFICATION':
            // 全局消息通知
            this.$message({
              message: data.message,
              type: data.messageType || 'info'
            })
            break
          case 'USER_STATUS':
            // 用户状态变更处理
            this.$store.commit('UPDATE_USER_STATUS', data.data)
            break
          // 可以添加更多全局级别的消息处理
        }
      } catch (error) {
        console.error('SSE消息处理错误:', error)
      }
    }
  },
  beforeDestroy() {
    this.disconnectSse()
  }
}
</script>

<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
