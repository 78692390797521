<template>
  <div :class="['left-menu', { 'collapsed': isCollapsed }]">
    
    <!-- 原有的内容 -->
    <template v-if="!isCollapsed">
      <div class="user-info">
        <img src="/img/de-logo4.jpg" alt="User Avatar" class="avatar">
        <div class="user-details">
          <p class="greeting">下午好,</p>
          <h2 class="user-name">{{ userName }}</h2>
        </div>
        <div class="points" @click="toggleMenu">
          <span class="points-value">2,840</span>
          <span class="points-label">score</span>
        </div>
      </div>
      
      <div class="categories">
        <span v-for="category in categories" :key="category.name" class="category-tag">
          <span class="category-dot" :style="{ backgroundColor: category.color }"></span>
          {{ category.name }}
        </span>
      </div>
      <div class="reminders-section">
        <h2 class="reminders-title">财务管理BP</h2>
        <p class="reminders-description">Before meeting, have momonyetan remind.</p>
        <div class="reminders-content">
           
          
        </div>
      </div>
      
      <div class="menu-container course-directory">
        <div class="date-header">课程目录</div>
        <div class="menu-items">
          <div v-for="(item, index) in menuItems" :key="index" class="menu-item">
           <div :class="['menu-item-icon' ]" style="font-size: 14px;font-weight: normal">
              {{ index + 1 }}
            </div>
            <div class="menu-item-details">
              <div :class="['menu-item-title' ]">{{ item.title }}</div>
              <div class="menu-item-description">{{ item.description }}</div>
            </div>
            <div class="menu-item-time">{{ item.timeLeft }}</div>
          </div>
        </div>
      </div> 
      <div class="menu-container  file-section" style="padding:0px 18px 18px 18px;margin-top: 18px;">
          
          <div class="section-header" style="padding: 0px;margin-bottom: 0px;">
              <div class="date-header">文件区</div>
            <el-button type="primary" size="small" @click="uploadFile" class="upload-button" style="color: aliceblue;">
              <el-icon><Plus /></el-icon> 
            </el-button>
          </div>
          
          <div class="file-items" style="border-radius: 5px;">
              <div v-for="file in uploadedFiles" :key="file.id" class="file-item"> 
              <span :class="['file-icon', getFileIconClass(file.type)]" style="margin-left: 10px;"></span>
                    
              <div class="file-details">
                  <p class="file-name">{{ file.name }}</p>
                  <p class="file-size">{{ formatFileSize(file.size) }}</p>
              </div>
              <el-button link  class="download-button" @click="downloadFile(file.id)">
                
              </el-button>
              </div>
          </div>
        </div> 
      
    </template>
    
    <!-- 收起时显示的内容 -->
    <div v-else class="collapsed-content">
     <div class="toggle-button" @click="toggleMenu">
      <el-icon><ArrowLeft v-if="!isCollapsed" /><ArrowRight v-else /></el-icon>
    </div>
    <div class="user-info">
      <img src="/img/de-logo4.jpg" alt="User Avatar" class="avatar">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapsed: false,
      userName: "Yanghuahua",
      userPoints: 2840,
      categories: [
          { name: "学习达人", color: "#FFA500" },  // 橙色
          { name: "自律达人", color: "#00BFFF" },   // 浅蓝色
          { name: "金牌选手", color: "#32CD32" }, // 绿色
      ],
      mainMenuTitle: "Reminders to Prepare",
      mainMenuDescription: "Before meeting, have momonyetan remind.",
      uploadedFiles: [
      { id: 1, name: 'lecture_notes.pdf', type: 'pdf', size: 2500000 },
      { id: 2, name: 'assignment.docx', type: 'doc', size: 1200000 },
      { id: 3, name: 'presentation.pptx', type: 'ppt', size: 3800000 }, 
      { id: 5, name: 'lecture_video.mp4', type: 'video', size: 15000000 }
    ],
      menuItems: [
      {
        title: ' AI+财务BP名字由来',
        description: 'Bangbeng Web Project',
        timeLeft: '1 hours left',
        icon: 'Monitor',
        iconColor: 'blue',
        titleColor: ''
      },
      {
        title: '用老板的思维思考创业期前瞻性问题',
        description: 'UX Fundamental Sharing',
        timeLeft: '4 hours left',
        icon: 'Light-Bulb',
        iconColor: 'pink',
        titleColor: 'pink'
      },{
        title: '企业财务管理核心和重点',
        description: 'Bangbeng Web Project',
        timeLeft: '1 hours left',
        icon: 'Light-Bulb',
        iconColor: 'blue',
        titleColor: ''
      },
      {
        title: 'Sharing Information',
        description: 'UX Fundamental Sharing',
        timeLeft: '4 hours left',
        icon: 'Light-Bulb',
        iconColor: 'pink',
        titleColor: 'pink'
      }
      // 可以添加更多菜单项
    ],
    
    };
  },
  methods: {
    toggleMenu() {
      this.isCollapsed = !this.isCollapsed;
    },
    uploadFile() {
      // 实现文件上传逻辑
      console.log('Uploading file');
    },
    downloadFile(fileId) {
      // 实现文件下载逻辑
      console.log(`Downloading file with id: ${fileId}`);
    },
    getFileIconClass(filePath) {
      const extension = filePath.split('.').pop().toLowerCase();
      const iconMap = {
        vue: 'vue-icon',
        css: 'css-icon',
        html: 'html-icon',
        js: 'js-icon',
        scss: 'scss-icon',
        json: 'json-icon',
        pdf: 'pdf-icon',
        xls: 'excel-icon',
        xlsx: 'excel-icon',
        doc: 'word-icon',
        docx: 'word-icon',
        ppt: 'ppt-icon',
        pptx: 'ppt-icon',
        txt: 'txt-icon',
        other: 'other-icon'
      };
      return iconMap[extension] || 'default-icon';
    },
    formatFileSize(bytes) {
      if (bytes < 1024) return bytes + ' B';
      else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + ' KB';
      else return (bytes / 1048576).toFixed(1) + ' MB';
    }
  }
};
</script>

<style scoped>
.left-menu {
  background-color: #8a70d6;
  color: white;
  padding: 18px;  
  font-size: 12px;
  transition: width 0.3s;
  width: 350px; /* 展开时的宽度 */
  overflow: hidden;
}

.left-menu.collapsed {
  padding: 10px;
  width: 60px; /* 收起时的宽度 */
}
.left-menu.collapsed .user-info {
  justify-content: center;
}

.left-menu.collapsed .avatar {
  width: 30px; /* 收起时稍微缩小头像 */
  height: 30px;
}

.toggle-button {
  cursor: pointer;
  padding: 10px;
  text-align: right;
}

.collapsed-content {
  /* 收起时的内容样式 */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}

.left-menu {
  font-family: 'Tahoma', sans-serif;
}

.left-menu {
  display: flex;
  flex-direction: column;
  height: 100%; /* 确保左侧菜单占满整个高度 */
}
.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

 
.user-details {
  flex-grow: 1;
}

 
 

.points {
  background-color: white;
  color: #8a70d6;
  padding: 5px 15px;
  border-radius: 18px;
  font-weight: 300;

}

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

 

.menu-section {
  background-color: #9f89e5;
  border-radius: 5px;
  padding: 18px;
}

.section-title {
  margin: 0 0 5px 0;
  font-size: 18px;
}

.section-description {
  margin: 0 0 18px 0;
  font-size: 12px;
  opacity: 0.8;
}

.menu-items {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.menu-item {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 1px;
}

.menu-item i {
  font-size: 18px;
  margin-right: 15px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 12px;
}

.item-details {
  flex-grow: 1;
}

.item-title {
  margin: 0;
  font-weight: bold;
}

.item-description {
  margin: 0;
  font-size: 12px;
  opacity: 0.8;
}

.item-time {
  font-size: 12px;
  opacity: 0.8;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.category-tag {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5px 18px 5px 15px; /* 左侧padding稍微减少，为颜色点留出空间 */
  border-radius: 18px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.category-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}

.reminders-section {
  background-color: #7b55d3;
  border-radius: 15px;
  padding: 18px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  padding-bottom: 40px; 
}

.reminders-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.reminders-description {
  font-size: 12px;
  opacity: 0.8;
  margin-bottom: 10px;
}

.reminders-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  height: 100px; /* 调整高度以适应您的设计 */
  padding-bottom: 18px; /* 为背景图片留出空间 */
  
}
.reminders-content::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background-image: url(/img/course_menu_bg.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
}

.reminders-icons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.reminder-icon {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reminder-icon i {
  font-size: 18px;
  color: white;
}

.character-image {
  width: 150px;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 18px;
}
.menu-container {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-top: -60px; /* 根据需要调整 */
  z-index: 1;
}

.menu-container::before {
  content: '';
  position: absolute;
  top: 10px; /* 调整位置 */
  left: 50%;
  transform: translateX(-50%);
  width: 40px; /* 调整宽度 */
  height: 5px; /* 调整高度 */
  background-color: #E0E0E0; /* 调整颜色 */
  border-radius: 3px;
}

.date-header {
  font-size: 14px; 
  margin-bottom: 0px;
  color: #778899;
}

 

.menu-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.menu-item-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background-color: #83c1ff;
}

.menu-item-icon.blue {
  background-color: #87CEFA;
}

.menu-item-icon.pink {
  background-color: #FFC0CB;
}

.menu-item-details {
  flex-grow: 1;
}

.menu-item-title {
  font-size: 13px;
  font-weight: normal;
  color: #36454F;  /* 蓝色标题 */
  max-width: 150px; 
}

.menu-item-title.pink {
  color: #FF69B4;  /* 粉色标题 */
}

.menu-item-description {
  font-size: 11px;
  color: #708090;
  margin-top: 2px;
}

.menu-item-time {
  font-size: 10px;
  color: #708090;
}
.user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #fff;
  border: 5px solid #fff;
}

.user-details {
  flex-grow: 1;
  margin-left: 15px;
}

.greeting {
  margin: 0;
  font-size: 14px;
  opacity: 0.8;
}

.user-name {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.points {
  background-color: white;
  color: #333;
  padding: 8px 18px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.points-value {
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
}

.points-label {
  font-size: 12px;
  color: #666;
}
 

.menu-item-icon .el-icon {
  font-size: 20px;
  color: white;
}


.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section-title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.upload-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color:transparent;
  ;
}

.upload-button .el-icon {
  margin-right: 5px;
}

.menu-items {
  margin-top: 15px;
}
.file-items{
    background-color: #fff;
    font-size: 12px;
}
.file-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.3s;
}

.file-item:last-child {
  border-bottom: none;
}

.file-item:hover {
  background-color: #f9f9f9;
}

.file-item .el-icon {
  font-size: 24px;
  margin-right: 12px;
  color: #4A90E2;
}

.file-details {
  flex-grow: 1;
  overflow: hidden;
}

.file-name {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-size {
  margin: 4px 0 0;
  font-size: 12px;
  color: #999;
}

.download-button {
  color: #4A90E2;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: color 0.3s;
}

.download-button:hover {
  color: #357ABD;
}

.upload-button{
    color: #fff!important;
}


.course-directory {
  flex-grow: 1;
  overflow-y: auto; /* 如果内容过多，允许滚动 */
}
.file-section {
  flex-shrink: 0;
  height: 300px; /* 设置固定高度，根据需要调整 */
  padding: 18px;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.file-items {
  flex-grow: 1;
  overflow-y: auto;
  border-radius: 5px;
}

.left-menu .menu-container.file-section {
  flex-shrink: 0;
  padding: 0px 18px 18px 18px;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  height: 150px; /* 默认高度 */
}

/* 针对不同屏幕高度的媒体查询 */
@media screen and (min-height: 600px) {
  .left-menu .menu-container.file-section {
    height: 150px;
  }
}

@media screen and (min-height: 800px) {
  .left-menu .menu-container.file-section {
    height: 200px;
  }
}

@media screen and (min-height: 1000px) {
  .left-menu .menu-container.file-section {
    height: 300px;
  }
}
</style>