import { setStore, getStore } from 'utils/store';
import website from '@/config/website';

const firmWel = website.firmFirstPage;

const firm = {
  state: {
    firmWel: firmWel,
  },
  mutations: {
    SET_FIRM_WEL: (state, wel) => {
      state.firmWel = wel;
    }
  },
  actions: {
    updateFirmWel({ commit }) {
      const newFirmWel = website.firmFirstPage; // 或者你想要的任何新值
      commit('SET_FIRM_WEL', newFirmWel);
    }
  }
};

export default firm;
