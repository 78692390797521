<!--项目列表-->
<template>
  <div>
    <div class="project">
      <div class="flex-default jc-sb padding-10 line-30 border-bottom align-center">
        <b>{{ website.firmProjectEnterpriseEncrypt ? encryptEnterpriseName(aiIndex.enterpriseDetail.enterpriseName) : aiIndex.enterpriseDetail.enterpriseName }}</b>
        <i class="iconfont iconicon_more"></i>
      </div>
      <div class="flex-default jc-sb padding-10 line-30 border-bottom align-center">
        <i class="icon"><b>项目 project</b></i> 
        <i class="iconfont iconicon_more"></i>
      </div>
      <ul class="padding-10">
        <li
          class="creat margin-b10 grey-border fs-14 padding-10 border-radius8 pointer"
          @click="dialogFormVisible = true"
        >
          新建项目 New Project
        </li>
        <li
          v-for="(item, index) in projects"
          :class="['margin-b10 grey-border fs-14 padding-10 border-radius8 saber-relative', { 'border-sky-500': ''=== item.id}]"
          @click="selectProject(item)"
        >
          <p class="line-25 pointer">{{ item.projectName }}</p>
          <!-- <p class="line-25 fc-999">{{item.desc}}</p> -->
          <div class="edit align-center text-center jc-center" @click.stop="handleProjectEdit(item)">
            <i class="iconfont iconicon_edit">edit</i>
          </div>
          <div class="delete align-center text-center jc-center" @click.stop="handleProjectRemove(item)">
            <i class="iconfont iconicon_delete"></i>
          </div>
        </li>
      </ul>
    </div>
    <!--      新建弹窗-->
    <el-dialog v-model="dialogFormVisible" title="新建项目" width="90%">
      <avue-form
        ref="formRef"
        :option="projectOption"
        v-model="projectForm"
        @submit="handleProjectSubmit"
      ></avue-form>
    </el-dialog>
    <!-- 编辑项目弹窗 -->
    <el-dialog v-model="editDialogFormVisible" title="编辑项目" width="90%">
      <avue-form
        ref="editFormRef"
        :option="projectOption"
        v-model="editProjectForm"
        @submit="handleProjectUpdate"
      ></avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getList as getProjectList,
  add as addProject,
  update as updateProject,
  remove as deleteProject,
} from '@/api/firmProject/firmProject';
import projectOption from '@/option/firmProject/firmProject';
import website from '@/config/website'

export default {
  inject: ['aiIndex'],
  name: 'projectList',
  data() {
    return {
      website,
      // 新建项目弹窗
      dialogFormVisible: false,
      editDialogFormVisible: false, // 编辑项目弹窗
      formLabelWidth: '120px',
      // 新项目数据设置
      projectOption: projectOption,
      projectForm: {},
      editProjectForm: {}, // 编辑项目表单
      projects: [],
      activeProject: null
    };
  },
  // 监视，如果aiIndex.enterpriseDetail有值，赋值给projectForm
  watch: {
    // 监视 aiIndex 对象中的 enterpriseDetail 属性
    'aiIndex.enterpriseDetail': {
      handler(newVal) {
        // 只有在 newVal 有实际值的情况下才进行赋值操作
        if (newVal && Object.keys(newVal).length > 0) {
          this.projectForm = {
            firmId: newVal.firmId,
            enterpriseId: newVal.id,
            enterpriseName: newVal.enterpriseName,
            creditCode: newVal.creditCode,
          };
        }
      },
      deep: true, // 使用深度监听，以便捕获对象内部的变化
      immediate: true, // 立即执行，以便初始化时就进行赋值
    },
  },
  mounted() {
    // 获取项目列表
    this.currentProject
    this.loadProjectList();
  },
  computed: {
      currentProject() {
        const currentProject = this.$store.getters.currentProject(this.$route.query.enterpriseId);
        if(currentProject){
          this.activeProject = currentProject;
        }
        return currentProject;
      },
  },
  methods: {
    encryptEnterpriseName(name) {
      if (!name) return name
      
      if (name.length <= 3) {
        // 如果名称长度小于等于3，只显示最后一个字符
        return '*'.repeat(name.length - 1) + name.slice(-1)
      } else {
        // 如果长度大于3，保留第三个字符，其他用*替换
        const stars = '*'.repeat(name.length - 1)
        return stars.slice(0, 2) + name.charAt(2) + stars.slice(3)
      }
    },
    loadProjectList() {
      getProjectList(1, 100, { enterpriseId: this.$route.query.enterpriseId }).then(res => {
        const result = res.data;
        if (result.code === 200) {
          this.projects = result.data.records;
        } else {
          this.$message.error(result.message);
        }
      });
    },
    // 新建项目确定
    handleProjectSubmit(form,done) {
      addProject(this.projectForm).then(res => {
        const result = res.data;
        if (result.code === 200) {
          this.$message.success('新建项目成功');
          this.dialogFormVisible = false;
          // 重置表单
          this.$refs.formRef.resetForm();
          // 重新初始化表单数据
          if (this.aiIndex.enterpriseDetail) {
            this.projectForm = {
              firmId: this.aiIndex.enterpriseDetail.firmId,
              enterpriseId: this.aiIndex.enterpriseDetail.id,
              enterpriseName: this.aiIndex.enterpriseDetail.enterpriseName,
              creditCode: this.aiIndex.enterpriseDetail.creditCode,
            };
          }
          done();
          this.loadProjectList();
        } else {
          this.$message.error(result.message);
          done();
        }
      }).catch(error => {
        done();
      });
    },
    handleProjectEdit(project) {
      this.editProjectForm = { ...project };
      this.editDialogFormVisible = true;
    },
    handleProjectUpdate() {
      // 调用更新项目的API
      updateProject(this.editProjectForm).then(res => {
        const result = res.data;
        if (result.code === 200) {
          this.$message.success('项目更新成功');
          this.editDialogFormVisible = false;
          // 重置编辑表单
          this.$refs.editFormRef.resetForm();
          this.editProjectForm = {};
          this.loadProjectList();
        } else {
          this.$message.error(result.message);
        }
      });
    },
    handleProjectRemove(project) {
      this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        // 删除项目
        deleteProject(project.id).then(res => {
          const result = res.data;
          if (result.code === 200) {
            this.$message.success('删除成功');
            this.loadProjectList();
            // 设置需要刷新的状态
            this.$store.dispatch('setNeedRefresh', true);
          }
        });
      });
    },
    selectProject(project) {
      this.activeProject = project;
      // 将项目数据转换为 URL 参数
      const params = new URLSearchParams({
        enterpriseId: project.enterpriseId,
        projectId: project.id,
        projectName: project.projectName,
        // 添加其他需要传递的项目信息
      }).toString();

      // 打开新窗口
      const newWindow = window.open(`/projectApp/index?${params}`, '_blank' );

      // 可选：如果需要在新窗口加载完成后执行一些操作
      if (newWindow) {
        newWindow.onload = () => {
          // 这里可以添加在新窗口加载完成后需要执行的代码
        };
      }

      // 仍然在当前页面更新状态
      this.activeProject = project;
      this.$store.commit('SET_ENTERPRISE_CURRENT_PROJECT', {
        enterpriseId: project.enterpriseId,
        project: project
      });
    },
     
  },
};
</script>

<style lang="scss" scoped>
.project {
  .creat {
    background-color: #cfddf7;
  }
  ul li div.edit, ul li div.delete {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    background-color: #fff;
    color: #666;
    display: none;
    transition: 0.3s;
  }
  ul li div.edit {
    right: 50px; /* Adjust position to the left of delete button */
  }
  ul li:hover div.edit, ul li:hover div.delete {
    display: flex;
  }
  ul li i:hover {
    color: #006cff;
    cursor: pointer;
  }
}
.pointer {
  cursor: pointer;
}
.icon { 
  background-image: url('/img/icon/12文件夹.png'); /* 替换为您的背景图片路径 */ 
}
 
</style>
