<template>
  <el-scrollbar class="avue-menu">
    <div v-if="menu && menu.length == 0 && !isHorizontal" class="avue-sidebar--tip">
     {{ $t('menuTip') }}
    </div>
    <el-menu
      unique-opened
      :default-active="activeMenu"
      :mode="setting.sidebar"
      :collapse="getScreen(isCollapse)"
    >
      <sidebar-item :menu="menu"></sidebar-item>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex';
import sidebarItem from './sidebarItem.vue';

export default {
  name: 'sidebar',
  components: { sidebarItem },
  inject: ['aiIndex'],
  created() {
    if(this.$route.meta.menuType==='ai'){ 
      const enterpriseId = this.$route.query.enterpriseId;
      //enterpriseId 必须存在
      if(!enterpriseId){
        return;
      }else{
        this.aiIndex.openMenu({enterpriseId:enterpriseId});
      }
    }else{
      this.$message.error('页面异常，请重新进入');
    }
  },
  computed: {
    ...mapGetters(['isHorizontal', 'setting', 'menu', 'tag', 'isCollapse', 'menuId']),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
};
</script>
<style lang="scss" >

</style>
