import router from './router/';
import store from './store';
import { getToken } from '@/utils/auth';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css';
import { setTheme } from '@/utils/util.js'; // progress bar style
NProgress.configure({ showSpinner: false });
const lockPage = '/lock'; //锁屏页
router.beforeEach((to, from, next) => {
  NProgress.start();
  const meta = to.meta || {};
  const isMenu = meta.menu === undefined ? to.query.menu : meta.menu;
  store.commit('SET_IS_MENU', isMenu === undefined);
  if (getToken()) {
    if (store.getters.isLock && to.path !== lockPage) {
      //如果系统激活锁屏，全部跳转到锁屏页
      next({ path: lockPage });
    } else if (to.path.endsWith('/login') || to.path.endsWith('/regist') || to.path.endsWith('/portal') || to.path.endsWith('/edu/home')) {
      next();
    } else {
      if (store.getters.token.length === 0) {
        store.dispatch('FedLogOut').then(() => {
          next({ path: '/login' });
        });
      } else {
        const meta = to.meta || {};
        const query = to.query || {};
        // 目前默认判断条件：menuType为ai。后面类似处理的菜单多起来的话，可以考虑写成一个单独的函数，现在没处理必要
        
        if (to.meta.menuType === 'ai') {
          if (Object.keys(from.query).length !== 0) {
            if (!query.enterpriseId) {
              let updateQuery = {
                enterpriseId: from.query.enterpriseId,
              };
              next({ path: to.path, query: updateQuery });
              return;
            } else {
              store.commit('ADD_ENT_TAG', {
                enterpriseId: query.enterpriseId,
                tag: {
                  name: query.name || to.name,
                  path: to.path,
                  fullPath: to.fullPath,
                  params: to.params,
                  query: to.query,
                  meta: meta,
                },
              });
              next();
              return;
            }
          }else{
            store.commit('ADD_ENT_TAG', {
              enterpriseId: query.enterpriseId,
              tag: {
                name: query.name || to.name,
                path: to.path,
                fullPath: to.fullPath,
                params: to.params,
                query: to.query,
                meta: meta,
              },
            });
            if (meta.target && from.path!=='/') {
              if(query.url) {
                window.open(query.url.replace(/#/g, '&'));
              }else{
                window.open(to.fullPath.replace(/#/g, '&'));
              }
              return;
            }else{
              next()
              return 
            }
          }
          // // 变更主题色
          // store.commit('SET_THEME_NAME', 'theme-d2');
          // setTheme('theme-d2');
          // // 变更路由, 目前设置别名为aiMenu的都是ai应用的路由
          // const aiMenu = store.getters.menu.filter(i => i.alias === 'aiMenu');
          // console.log('to', aiMenu);
          // store.dispatch('setAIMenu', aiMenu);
        } else if (to.meta.menuType === 'project') {
          if (Object.keys(from.query).length !== 0) {
            if (!query.projectId) {
              let updateQuery = {
                projectId: from.query.projectId,
                ...to.query
              };
              next({  path: to.path, query: updateQuery });
              return;
            } else {
              store.commit('ADD_PROJECT_TAG', {
                projectId: query.projectId,
                tag: {
                  name: query.name || to.name,
                  path: to.path,
                  fullPath: to.fullPath,
                  params: to.params,
                  query: to.query,
                  meta: meta,
                },
              });
              next();
              return;
            }
          }
        } else {
          // Handle other routes
          if (meta.isTab !== false) {
            if(meta.menuType==='firm'){
              store.commit('ADD_FIRM_TAG', {
                name: query.name || to.name,
                path: to.path,
                fullPath: to.fullPath,
                params: to.params,
                query: to.query,
                meta: meta,
              });
            } else if (meta.menuType === 'edu') {
              store.commit('ADD_COURSE_TAG', {
                name: query.name || to.name,
                path: to.path,
                fullPath: to.fullPath,
                params: to.params,
                query: to.query,
                meta: meta,
              });
            }
          }
          next();
          return;
        }
        if (meta.target && from.path !== '/') {
          if (query.url) {
            window.open(query.url.replace(/#/g, '&'));
          } else {
            window.open(to.fullPath.replace(/#/g, '&'));
          }
          return;
        } else if (meta.isTab !== false) {
          store.commit('ADD_TAG', {
            name: query.name || to.name,
            path: to.path,
            fullPath: to.fullPath,
            params: to.params,
            query: to.query,
            meta: meta,
          });
        }
        next();
      }
    }
  } else {
    //判断是否需要认证，没有登录访问去登录页
    if (meta.isAuth === false) {
      next();
    } else if (to.path.endsWith('/login') || to.path.endsWith('/regist') || to.path.startsWith('/portal') || to.path.endsWith('/edu/home')) {
      next();
    } else {
      // 如果访问的是教育门户，则跳转到教育门户首页
      if (to.path.startsWith('/edu')) {
        next('/edu/home');
      } else {
        next('/portal/home');
      }
    }
  }
});

router.afterEach(to => {
  NProgress.done();
  let title = router.$avueRouter.generateTitle(to, { label: 'name' });
  router.$avueRouter.setTitle(title);
  store.commit('SET_IS_SEARCH', false);
});
