import request from '@/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const getDetailById = (id) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/detailById',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/save',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/update',
    method: 'post',
    data: row
  })
}

export const subFiles = (current,size,fileId) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/attachFiles',
    method: 'get',
    params: {
      current,
      size,
      parentFileId: fileId
    }
  })
}

export const addAttachment = (formData,knowledgeDocId,parentFileId) => {
  formData.append('knowledgeDocId', knowledgeDocId);
  formData.append('parentFileId', parentFileId);
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/addAttachments',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  });
};

export const extractFileMeta = (fileIds) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/extractFileMeta',
    method: 'post',
    data: fileIds
  });
}

export const extractFileMetaFolder = (folderId,cover) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/extractFileMetaFolder',
    method: 'post',
    params: {
      folderId,
      cover
    }
  });
};

export const fileUpload = (formData,knowledgeDocId,financialStatementType) => {
  formData.append('knowledgeDocId', knowledgeDocId);
  formData.append('financialStatementType', financialStatementType);
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/file-upload',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  });
};

export const fileUploadBatch = (formData,knowledgeDocId,financialStatementType) => {
  formData.append('knowledgeDocId', knowledgeDocId);
  formData.append('financialStatementType', financialStatementType);
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/file-upload-batch',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  });
};

export const checkFileNameExist = (folderId,fileName) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/checkFileNameExist',
    method: 'get',
    params: {
      folderId,
      fileName
    }
  })
}

export const listVectors = (fileId) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/listVectors',
    method: 'get',
    params: {
      fileId
    }
  });
}

export const forceRemove = (fileId) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/forceRemove',
    method: 'post',
    params: {
      fileId
    }
  });
} 


export function processSubjectSheet(fileId, fileLink) {
  return request({
    url: '/pythonapi/api/subject/process_subject_sheet',
    method: 'get',
    params: {  // 使用 params 而不是 data
      file_id: fileId,
      file_link: fileLink
    },
  });
}

export const regenerateDataframe = (fileId) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/file-only-regenerate-dataframe',
    method: 'put',
    params: {
      fileId
    }
  });
}

export const batchRegenerateDataframe = (fileIds) => {
  return request({
    url: '/sc-llm//llmKnowledgeDocFiles/file-batch-regenerate',
    method: 'post',
    params: {
      fileIds
    }
  });
}



export const fileUploadCover = (formData,fileId) => {
  formData.append('knowledgeFileId', fileId);
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/file-upload-cover',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  });
};


// 处理表格
export function process_htmltable(query) {
  return request({
    url: '/pythonapi/api/tablehtml/process_htmltable',
    method: 'get',
    params: {  // 使用 params 而不是 data
      query: query 
    },
  });
}