import { getStore, setStore } from '@/utils/store';

import { getDictionary } from '@/api/system/dict';

const dict = {
  state: {
    flowRoutes: getStore({ name: 'flowRoutes' }) || {},
    financialStatementType: getStore({ name: 'financialStatementType' }) || [],
  },
  actions: {
    FlowRoutes({ commit }) {
      return new Promise((resolve, reject) => {
        getDictionary({ code: 'flow' })
          .then(res => {
            commit('SET_FLOW_ROUTES', res.data.data);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async loadDictFinancialStatementType({ commit, state }) {
      // 如果已有数据，直接返回
      if (Object.keys(state.financialStatementType).length > 0) {
        return state.financialStatementType;
      }
      
      // 否则请求数据
      try {
        const res = await getDictionary({ code: 'financial_statement_type' });
        commit('SET_FINANCIAL_STATEMENT_TYPE', res.data.data);
        return state.financialStatementType;
      } catch (error) {
        console.error('Failed to load financial statement type:', error);
        return {};
      }
    }
  },
  mutations: {
    SET_FLOW_ROUTES: (state, data) => {
      state.flowRoutes = data.map(item => {
        return {
          routeKey: `${item.code}_${item.dictKey}`,
          routeValue: item.remark,
        };
      });
      setStore({ name: 'flowRoutes', content: state.flowRoutes });
    },
    SET_FINANCIAL_STATEMENT_TYPE: (state, data) => {
      state.financialStatementType = data.map(item => {
        return {
          dictKey: item.dictKey,
          dictValue: item.dictValue,
        };
      });
      setStore({ name: 'financialStatementType', content: state.financialStatementType });
    },
  },
};

export default dict;
