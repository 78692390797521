<template>
  <div class="avue-contail" :class="{ 'avue--collapse': isCollapse }">
    <div class="avue-layout" :class="{ 'avue-layout--horizontal': isHorizontal }">
      <div class="avue-sidebar ai-sidebar" v-show="validSidebar">
        <!-- 左侧导航栏 -->
        <logo />
        <sidebar />
      </div>
      <div class="avue-main">
        <!-- 顶部导航栏 -->
        <top ref="top" />
        <!-- 顶部标签卡 -->
        <tags />
        <search class="avue-view" v-show="isSearch"></search>
        <!-- 主体视图层 -->
        <div id="avue-view" v-show="!isSearch" v-if="isRefresh">
          <router-view #="{ Component }">
            <keep-alive :include="$store.getters.tagsKeep">
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>
      </div>
      <el-aside v-loading="loading" class="width-300 border-left ">
        <el-scrollbar>
          <!-- 项目列表组件-->
          <project-list></project-list>
          <!-- 聊天历史-->
          <div class="project">
            <div class="flex-default jc-sb padding-10 line-30 border-bottom align-left">
              <i class="icon" ><b>历史信息</b></i>
             
              <i class="iconfont iconicon_more"></i>
            </div>
            <div class="padding-10">
              <div
                v-for="(item, index) in chatHistory"
                class="margin-b10 fs-14 margin-b10 padding-10 border-radius8 saber-relative"
              >
                <p class="line-25 pointer">{{ item.label }}</p>
                <ul>
                  <li
                    v-for="i in item.list"
                    class="saber-relative"
                    :class="{ active: isActive(item, i) }"
                    @click="getChatHistory(item, i)"
                  >
                    <p class="line-30 text-line1 fs-14 fc-666 pointer">{{ i.name }}</p>
                    <div class="delete align-center text-center jc-center">
                      <i class="iconfont iconicon_delete"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </el-aside>
    </div>
    <!-- <wechat></wechat> -->
  </div>
</template>

<script>
import {
  getDetailById as getEntDetailById,
} from '@/api/firmEnterprise/firmEnterprise';
import index from '@/mixins/index';
import wechat from './wechat.vue';
//import { validatenull } from 'utils/validate';
import { mapGetters } from 'vuex';
import tags from './aiTags.vue';
import search from './search.vue';
import logo from './logo.vue';
import top from './top/aiIndex.vue';
import sidebar from './sidebar/aiIndex.vue';
import projectList from '@/views/aiApplication/projectList.vue';
export default {
  mixins: [index],
  components: {
    top,
    logo,
    tags,
    search,
    sidebar,
    wechat,
    projectList,
  },
  name: 'aiIndex',
  provide() {
    return {
      aiIndex: this,
    };
  },
  data() {
    return {
      loading: true,
      enterpriseDetail: {},
      chatHistory: [
        { label: '第一天', list: [{ name: '信息1' }, { name: '信息2' }] },
        { label: '第二天', list: [{ name: '信息1' }, { name: '信息2' }] },
        { label: '第3天', list: [{ name: '信息1' }, { name: '信息2' }] },
        { label: '第4天', list: [{ name: '信息1' }, { name: '信息2' }] },
        { label: '第5天', list: [{ name: '信息1' }, { name: '信息2' }] },
      ],
    };
  },
  watch: {
  enterpriseDetail: {
    handler(newVal) {
      // 检查对象是否为空
      if (Object.keys(newVal).length === 0) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
    deep: true, // 深度监听，确保即使对象的属性变化也能触发
    immediate: true // 立即执行，确保在组件实例化时立即检查
  }
},
  created() {
    this.Load(this.$route.query.enterpriseId);
  },
  computed: {
    ...mapGetters([
      'isHorizontal',
      'isRefresh',
      'isLock',
      'isCollapse',
      'isSearch',
      'menu',
      'setting',
    ]),
    validSidebar() {
      return !(
        (this.$route.meta || {}).menu === false || (this.$route.query || {}).menu === 'false'
      );
    },
  },
  props: [],
  methods: {
    Load(enterpriseId) {
      // 检查 enterpriseId 是否有值
      if (enterpriseId) {
        // 用 enterpriseId 查询企业信息
        getEntDetailById(enterpriseId).then(res => {
          const data = res.data;
          if (data.success) {
            this.enterpriseDetail = data.data;
          }
        });
      }
    },
    //打开菜单
    openMenu(item = {}) {
      this.$store
        .dispatch('GetMenuByVisibilityScope', 'enterprise')
        .then(data => {
          if (data.length !== 0) {
            this.$router.$avueRouter.formatRoutes(data, true);
          }
        });
    },
    // 选中状态的数据组合方式，可以根据实际情况更改
    compositionString(item, i) {
      return item.label + '' + i.name;
    },

    // 判断是否选中
    isActive(item, i) {
      return this.compositionString(item, i) === this.historySelected;
    },
    // 点击后执行函数，调取数据
    getChatHistory(item, i) {
      this.historySelected = this.compositionString(item, i);
      this.$refs.chatWindow.getHistory(i);
    },
  },
};
</script>
<style lang="scss" scoped>
.project {
  .creat {
    background-color: #cfddf7;
  }
  ul li div.delete {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    background-color: #fff;
    color: #666;
    display: none;
    transition: 0.3s;
  }
  ul li:hover div.delete {
    display: flex;
  }
  ul li i:hover {
    color: #006cff;
    cursor: pointer;
  }
}
.pointer {
  cursor: pointer;
}
.saber-relative .active {
  background-color: #f1f1f1;
  padding: 0 5px;
  border-radius: 5px;
}
.icon { 
  background-image: url('/img/icon/21信息.png'); /* 替换为您的背景图片路径 */ 
}
 
::v-deep .ai-sidebar .el-menu-item.is-active:before,::v-deep  .ai-sidebar .el-sub-menu__title.is-active:before {
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    width: 0px!important;
    background: #409eff;
    position: absolute;
}
.avue-logo {
    height: 50px;
    line-height: 50px;
    background-color: #fff!important;
    font-size: 20px;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15)!important;
    color: #fff!important;
    border: #8e9fac;
}
.ai-sidebar{
  background-color: #fcfbfd!important;
  color: #8e9fac!important;
}
::v-deep .ai-sidebar .el-menu-item.is-active,::v-deep .ai-sidebar .el-sub-menu__title.is-active {
    background-color: #3c80fd!important;
    margin: 15px!important;
    line-height: 20px!important;
    height: 40px!important;
    border-radius: 10px!important;
    color: #000!important;
}
::v-deep .ai-sidebar .el-menu-item.is-active > i,
::v-deep .ai-sidebar .el-sub-menu__title.is-active > i {
    margin-left: -15px!important;
    margin-right: 0px!important;
}
::v-deep .ai-sidebar .el-menu-item i, ::v-deep  .ai-sidebar .el-menu-item span,
::v-deep  .ai-sidebar .el-sub-menu__title i,::v-deep  .ai-sidebar .el-sub-menu__title span{
  color: #516574;
}
::v-deep .ai-sidebar .el-menu-item:hover i, 
::v-deep .ai-sidebar .el-menu-item:hover span,
::v-deep .ai-sidebar .el-sub-menu__title:hover i,
::v-deep .ai-sidebar .el-sub-menu__title:hover span {
  color: #1E90FF; /* 您想要的悬停颜色color: #8A2BE2; /* BlueViolet */  
}
::v-deep .ai-sidebar .el-menu-item.is-active i,::v-deep .ai-sidebar .el-menu-item.is-active span,::v-deep  .ai-sidebar .el-sub-menu__title.is-active i, .avue-sidebar .el-sub-menu__title.is-active span {
    color: #fff!important;;
}
::v-deep  .el-dropdown-menu__item--divided:before,::v-deep  .el-menu, .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover, .el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover {
  color: #000!important;;
}

</style>
