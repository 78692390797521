<template>
  <div>
  <el-scrollbar class="avue-menu">
    <div v-if="menu && menu.length == 0 && !isHorizontal" class="avue-sidebar--tip">
     {{ $t('menuTip') }}
    </div>
    <el-menu
      unique-opened
      :default-active="activeMenu"
      :mode="setting.sidebar"
      :collapse="getScreen(isCollapse)"
    >
      <sidebar-item :menu="projectMenu"></sidebar-item>
    </el-menu>
  </el-scrollbar>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import sidebarItem from './sidebarTreeItem.vue';
import { fetchProjectWorkpapers } from '@/api/project/projectapi';

export default {
  name: 'sidebar',
  components: { sidebarItem },
  inject: ['projectIndex'],
  data() {
    return {
      projectMenu: [],
      projectId: '',
      enterpriseId: '',
      projectName:""
    };
  },
  created() {
    this.initRouteParams();
    if(this.$route.meta.menuType==='project'){ 
      const enterpriseId = this.$route.query.enterpriseId;
      const projectId = this.$route.query.projectId;
      const projectName = this.$route.query.projectName;
      this.fetchProjectMenu();
      //enterpriseId 必须存在
      if(!enterpriseId){
        return;
      }else{
         this.projectIndex.openMenu({enterpriseId:enterpriseId,projectId:projectId,projectName:projectName});
      }
    }else{
      this.$message.error('页面异常，请重新进入');
    }
  },
  computed: {
    ...mapGetters(['isHorizontal', 'setting', 'menu', 'tag', 'isCollapse', 'menuId']),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  methods: {
    initRouteParams() {
      this.projectId = this.$route.query.projectId || '';
      this.enterpriseId = this.$route.query.enterpriseId || '';
      this.projectName = this.$route.query.projectName || '';
    },
    async fetchProjectMenu() {
      if (this.$route.meta.menuType === 'project') {
        const enterpriseId = this.enterpriseId;
        const projectId = this.projectId;
        
        if (!enterpriseId || !projectId) { 
          return;
        }

        //模拟数据
        let tempData=[
          { 
            id:'1',
            name:'企业信息',
            code:'1',
            children:[ 
            {id: '1823978793236738049', parentId: '1823977691388882946',
             code: 'baseInfo', name: '企业基本信息', path: '/projectfirmEnterprise/index',
             query:{
                  projectId:projectId,
                  enterpriseId:enterpriseId,
                  projectName:this.projectName,
                  name:'企业基本信息',
                  fileLink:''
                }}, 
             ]
          },
          { 
            id:'2',
            name:'项目资料',
            code:'2',
            children:[  
                {
                id: projectId+'info',
                name:  '企业财务资料' ,
                icon: 'el-icon-document', // 你可以根据实际情况设置适当的图标
                component:  '/firmProject/financialInfo',
                path:'/firmProject/financialInfo',
                query:{
                  projectId:projectId,
                  enterpriseId:enterpriseId,
                  projectName:this.projectName,
                  name:'企业财务资料',
                  fileLink:''
                }
                //children: item.children ? this.formatChildren(item.children) : undefined
              }
             ]
          },
          
          { 
            id:'3',
            name:'项目底稿',
            code:'3',
            children:[ 
             ]
          },
          /*{ 
            id:'4',
            name:'纳税申报表',
            code:'4',
            children:[  
             ]
          },
          { 
            id:'5',
            name:'word在线编辑',
            code:'5',
            children:[  
                {
                id: projectId+'info',
                name:  'word在线编辑' ,
                icon: 'el-icon-document', // 你可以根据实际情况设置适当的图标
                component:  '/wordonline/index',
                path:'/wordonline/index',
                query:{
                  projectId:projectId,
                  enterpriseId:enterpriseId,
                  projectName:this.projectName,
                  name:'word在线编辑',
                  fileLink:''
                }
                //children: item.children ? this.formatChildren(item.children) : undefined
              }
             ]
          },*/
        ]
        try {
          // 为每个菜单项填充子项
          for (let item of tempData) {
            if(item.children.length<=0){
              item.children = await this.fetchChildrenForMenuItem(item.id, enterpriseId, projectId);
            }
          }
          console.log(tempData)
          this.projectMenu = tempData;
        } catch (error) {
          console.error('获取项目菜单失败:', error);
          this.$message.error('获取项目菜单失败，请重试');
        } 
         
      } else {
        this.$message.error('页面异常，请重新进入');
      }
    },
    async fetchChildrenForMenuItem(menuId, enterpriseId, projectId) {
      // 根据menuId调用不同的接口
      switch (menuId) {
        case '1':
          // 调用企业信息接口
          //return await this.fetchEnterpriseInfo(enterpriseId, projectId);
          return 
        case '2':
          // 调用项目资料接口
          //return await this.fetchProjectDocuments(enterpriseId, projectId);
          return 
        case '3':
          // 调用项目底稿接口
          return await this.fetchProjectWorkpapers(enterpriseId, projectId);
        case '4':
          // 调用纳税申报表接口
          //return await this.fetchTaxReturns(enterpriseId, projectId);
          return 
        default:
          return [];
      }
    },
    getComponentByFileType(fileName) {
      const excelExtensions = ['xlsx', 'xls', 'csv'];
      const fileExtension = fileName.split('.').pop().toLowerCase();
      return excelExtensions.includes(fileExtension) ? '/luckysheet/index' : '/table/index';
    },
    async fetchProjectWorkpapers(enterpriseId, projectId) {
      try {
        let response = await fetchProjectWorkpapers(  projectId);
        response = response.data;

        // 假设 API 返回的数据结构是 { code: 200, data: [...] }
        if (response.code === 200 && Array.isArray(response.data)) {
          // 将 API 返回的数据转换为 el-menu 需要的格式
          return response.data.map(item => {
            const uniqueKey = `${item.id}` //`${item.id}-${Math.random().toString(36).substr(2, 9)}`; // 基于 item.id 生成唯一的 key
            localStorage.setItem('navigationParams-'+uniqueKey, JSON.stringify({
              id:item.id,
              fileName:item.fileName,
              name:item.originalName,
              projectName:this.projectName,
              originalName: item.originalName,
              fileLink: encodeURIComponent(item.fileLink) , 
            }));
            return {
              id: item.id,
              name: item.originalName || item.fileName,
              icon: 'el-icon-document', // 你可以根据实际情况设置适当的图标
              component: this.getComponentByFileType(item.originalName || item.fileName),
              path: this.getComponentByFileType(item.originalName || item.fileName),
              query: {
                name: (item.originalName || item.fileName).replace(/\.xlsx|\.xls/g, ''),
                projectId: projectId,
                enterpriseId: enterpriseId,
                projectName:this.projectName,
                key: uniqueKey // 添加唯一的 key
              }
              // children: item.children ? this.formatChildren(item.children) : undefined
            };
          });
        } else {
          console.error('获取项目底稿失败:', response);
          return [];
        }
      } catch (error) {
        console.error('获取项目底稿时发生错误:', error);
        return [];
      }
    },
  },
  
};
</script>
<style lang="scss" scoped>
.avue-menu{
  background-color: #eff0fb;
}
</style>
