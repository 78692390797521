import { getToken } from '@/utils/auth'
import crypto from '@/utils/crypto'

class SseClient {
  constructor() {
    this.eventSource = null
    this.userId = null
    this.messageHandlers = new Map()
  }

  // 连接SSE
  connect(userId) {
    if (this.eventSource) {
      this.disconnect()
    }

    this.userId = userId
    const token = getToken()
    // 创建 EventSource 连接
    const sa = crypto.encryptAES(token, crypto.cryptoKey)
    this.eventSource = new EventSource(`/api/sc-llm/sse/connect/${encodeURIComponent(userId)}?Sc-Auth=${encodeURIComponent('crypto ' + sa)}`)

    // 连接建立时的处理
    this.eventSource.onopen = () => {
      console.log('SSE connection established')
    }

    // 处理连接错误
    this.eventSource.onerror = (error) => {
      console.error('SSE connection error:', error)
      this.disconnect()
    }

    // 处理 connect 事件
    this.eventSource.addEventListener('connect', (event) => {
      console.log('SSE connect event:', event.data)
    })

    // 处理 message 事件
    this.eventSource.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)
      // 调用所有注册的消息处理器
      this.messageHandlers.forEach((handler) => {
        handler(data)
      })
    })
  }

  // 断开SSE连接
  disconnect() {
    if (this.eventSource) {
      this.eventSource.close()
      this.eventSource = null
      this.userId = null
      console.log('SSE connection closed')
    }
  }

  // 注册消息处理器
  onMessage(handlerId, handler) {
    this.messageHandlers.set(handlerId, handler)
  }

  // 移除消息处理器
  removeMessageHandler(handlerId) {
    this.messageHandlers.delete(handlerId)
  }

  // 获取连接状态
  isConnected() {
    return this.eventSource !== null && this.eventSource.readyState === EventSource.OPEN
  }

  // 获取当前用户ID
  getCurrentUserId() {
    return this.userId
  }
}

// 创建单例实例
const sseClient = new SseClient()
export default sseClient
