<!--没有项目列表的页面-->
<template>
  
  <el-container class="padding-20 _app_1k3bk_1" style="padding: 55px 0px 15px 50px;" ref="chatContainer">
    <EditingInstructionsDialog ref="editingInstructions" :show="showEditingInstructions" :params="queryParam" @submit="handleEditingInstructionsSubmit" @close="showEditingInstructions = false"/>
    <!--          对话列表-->
    <el-main id="chat" ref="chat" class="margin-b20" >
      <!--          没消息的时候-->
      <div v-if="!chatData.length" class="ai-guide">
        <div class="text-center"><b class="fs-35">Welcome to the AI area </b></div>
        <p class="text-center fc-999 line-30 margin-b20">你可以询问我关于任何相关的问题</p>
        <ul class="base-question padding-30">
          <li class="flex-default" v-for="(item, index) in baseQuestion">
            <div class="ai-avatar border-radius-50">
              <img :src="item.img || '/img/bot.png'" width="50" height="50" alt="" />
            </div>
            
            <div class="fs-14 fc-999">
              <p class="line-25">{{item.title}}</p>
              <p class="line-25">{{item.desc}}</p>
            </div>
          </li>
        </ul>
      </div>
      <!--          有返回消息的时候-->
      <div  @click="handleTableClick"  v-else>
        <div class="padding-r20" style="padding-right: 45px;">
          <div v-for="(item, index) in chatData" :id="'chat-item-' + item.id" >
            <!--                ai、真人-->
            <div v-if="[1, 2].includes(item.type) && item.checkStatus !== 1 && !item.isHidden" class="chat-box flex-default margin-b20" :class="{'row-reverse': item.type === 2}">
              <div class="ai-avatar border-radius-50" >
                <img :src="item.type === 1 ? '/img/bot.png' : item.avatar" width="55" height="55" alt="" />
              </div>
              <div class="flex-column flex-1 " :class="item.type === 2 ? 'margin-l5 align-flex-end padding-l55' : 'margin-l5 padding-r55'">
                <span class="fs-14 fc-light" v-if="item.name!=''" style="padding-top: 2px;">{{item.name}}</span>
                <!--                    对话框-->
                <div class="fc-666 fs-14 line-22 padding-5 border-radius8" :class="{ 'width-fit': !item.isGenerate }">
                  <!--                  检测信息-->
                  <template v-if="item.hasOwnProperty('checkResult')">
                    <div v-if="item.checkResult === 2" class="flex-default">企业基本信息检测完毕！信息完整 <Check class="width-16 margin-l10 fc-green" /></div>
                    <div v-if="item.checkResult === 3">
                      <p>正在获取数据...</p>
                      <p class="flex-default">获取失败<Close class="width-16 margin-l10 fc-red"/></p>
                      <p>请上传列表</p>
                    </div>
                  </template>
                  <div :ref="'content-' + index" v-else v-html="renderMarkdown(item)" class=" border-radius8"></div>  
                   <div v-if="item.type === 1 && item.isGenerate" class="flex-default jc-sb margin-t10">
                    <ul class="flex-default operation-btn">
                      <!--                        框架有一套自带的iconfont，我没有项目地址就不再另外加了，可以自己找两个合适的替换一下-->
                      <li class="grey-line-s"><i class="iconfont  iconfont iconicon_star"></i></li>
                      <li class="grey-line-s active"><i class="iconfont  iconfont iconicon_star"></i></li>
                    </ul>
                    <ul class="flex-default operation-btn">
                      <li class="grey-line-s"><i class="iconfont iconfont iconicon_dispose"> 📔复制</i></li>
                      <li class="grey-line-s"><i class="iconfont iconfont iconicon_share"> 分享</i></li>
                    </ul>
                  </div>
                </div>
<!--                AI专用操作类型-->
                <template v-if="item.type === 1">
                  <!--                    上传文件-->
                                      
                  <div v-if="item.operationType === 1" class="margin-t10 flex-default">
                    <div class="operation-need-container">
                      <template v-for="(it, ind) in item.operationNeed">
                        <div  class="operation-need-item">
                          <el-upload
                            v-if="!it.associatedFiles || it.associatedFiles.length === 0"
                            class="width-300 margin-b10"
                            :action="uploadUrl"
                            :show-file-list="false"
                            :drag="true"
                            accept=".xls, .xlsx"
                            list-type="picture"
                            :on-success="handleAvatarSuccess"
                            :http-request="(options) => customUpload(options, it)"
                            :before-upload="beforeAvatarUpload"
                            :data="uploadData"
                            :headers="uploadHeaders"
                          >
                            <div class="fs-14">
                              <p>{{ it.dictValue }}</p>
                              <p><span class="fc-blue">点击上传</span>/拖拽到此区域</p>
                              <p class="fc-999">请上传excel文件，大小在60M以内</p>
                            </div>
                          </el-upload>
                          <div v-else class="grey-border padding-20 flex-default width-fit fs-14 fc-999 margin-b10 border-radius8">
                            <img src="/img/EXCEL.png" class="margin-r10" width="40" alt="" />
                            <div class="flex-column margin-r10 margin-l10">
                              <b>{{ it.dictValue}}</b>
                              <p class="flex-default">{{formatDateTime(it.associatedFiles[0].updateTime)}}</p>
                            </div>
                            <div class="flex-default margin-l10">
                              <Download class="width-16 margin-l10 margin-r10 pointer" @click="downloadFile(it.associatedFiles[0])" />
                              <MoreFilled class="width-16 margin-l10 rotate90 pointer" @click="navigateToLucksheet(it.associatedFiles[0])" />
                          </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <!--                    多选-->
                  <div v-if="item.operationType === 2 " class="margin-b5 flex-default">
                    <el-checkbox-group v-model="item.operationValue">
                      <el-checkbox class="select-box custom-radio" label="Option1" value="Value1" border />
                      <el-checkbox class="select-box custom-radio" label="Option2" value="Value2" border />
                    </el-checkbox-group>
                  </div>
                  <!--                    单选-->
                  <div v-if="item.operationType === 3 " class="margin-b5 flex-default">
                    <el-radio-group v-model="item.operationValue">
                      <el-radio class="select-box" value="1" border>Option A</el-radio>
                      <el-radio class="select-box" value="2" border>Option B</el-radio>
                    </el-radio-group>
                  </div>
                </template>
              </div>
            </div>
            <!--                检测信息-->
            <div v-else-if="item.type === 1 && item.checkStatus === 1 && !item.isHidden" v-html="renderMarkdown(item)" class="margin-v10 fc-999 fs-14"></div>
          </div>
        </div>
        <div class="regenerate flex-default">
          <RefreshRight class="margin-r10" style="width: 18px"/> 重新生成
        </div>
      </div>
    </el-main>
    <!--        输入框-->
    <el-footer v-loading="sengLoading" style="padding: 0px 40px 0px 0px;">
      <div class="message-box border-radius10">
        <div class="saber-relative mentions-wrapper">
          <div class="mentions-header">
            <div class="add-icon-wrapper">
              <span class="add-icon" @click="toggleFileList" ref="addIcon">+</span>
              <!-- 文件列表浮动框 -->
              <div v-if="showFileList" class="file-list-popup" :style="popupStyle">
                <div class="search-bar">
                  <input type="text" placeholder="Search files..." v-model="searchQuery">
                </div>
                <div v-for="(file, index) in filteredFiles" :key="index" class="file-item" @click="selectFile(file)">
                  <span :class="['file-icon', getFileIconClass(file.fileName)]"></span>
                  <span class="file-name">{{ file.originalName }}</span>
                  <span class="file-path">{{ file.fileName.split('.').pop() }}</span>
                </div>
              </div>
              
            </div>
            <div v-for="(file, index) in openFiles" :key="index" class="file-tab-component">
              <span class="file-name">{{ file?.originalName?.split('.')[0] || '未命名' }}</span>
              <span class="file-name" style="font-size: 11px;">
                {{ ' ' + (file?.sheetName || '') + ' ' + (convertToA1Notation(file?.range) || '') }}
              </span>
              <span class="file-type">{{ file?.fileName?.split('.')?.pop() || '' }}</span>
              <span class="close-icon" @click="closeFile(index)">×</span>
            </div>
            <div v-if="openFiles.length<=0" class="file-tab-component" style="color: #999;font-size: 12px;background-color: #fff;">添加上下文</div>
          </div>
          <div class="mentions-wrapper">
            <div
        ref="editableDiv"
        class="editable-div"
        contenteditable="true"
        @input="onInput"
        @keydown="onKeyDown"
        @paste="onPaste"
        @blur="onBlur"
        :placeholder="placeholderText"
      ></div>
        </div>
        </div>
         
          
          <i class="saber-absolute fc-A3A3A3 iconfont send-msg-btn icon-fasong" @click="sendMessage"></i>
        </div>
        <ul class="flex-default send-msg-other align-center bg-grey padding-10 fs-14">
          <li class="grey-line-l flex-default align-center font-light"><el-icon class="margin-r10"><Link /></el-icon> 文件</li>
          <li class="grey-line-l flex-default align-center font-light"><el-icon class="margin-r10"><Microphone /></el-icon> 语音</li>
          <li class="grey-line-l flex-default align-center font-light"><el-icon class="margin-r10"><View /></el-icon> 查看关键词</li>
          <li class="grey-line-l flex-default align-center font-light" @click="toggleMentionPopup"><el-icon class="margin-r10">@</el-icon> Mention</li>
          
        </ul>
        <MentionPopup
          :show="showMentionPopup"
          :popupStyle="mentionPopupStyle"
          :params="queryParam"
          :currentPath="currentPath"
          :currentItems="currentItems"
          @navigate="navigateTo"
          @select="selectItem"
        />
    </el-footer>
  </el-container>
</template>

  <script>
// 首先，在文件顶部引入 uuid 库（如果还没有安装，需要先安装）
import { v4 as uuidv4 } from 'uuid';

import { mapState, mapMutations } from 'vuex';
import { ref, onMounted,computed, nextTick } from 'vue'
import website from '@/config/website'; 
import { getChatMessages, createChatMessage,runFlowApi,gen_table_struction ,save_user_customformat_table,getTableInfo} from '@/api/project/chatsession'; 
import { process_htmltable } from '@/api/knowledge/llmKnowledgeDocFiles';
import { messageConfig } from 'element-plus'; 
import { getToken } from 'utils/auth';
import { generateUUIDFromString } from '@/utils/util';
import { Mentions } from 'ant-design-vue'; 
import { parseNestedJson,formatDateTime} from '@/utils/util';
import axios from 'axios'
import { MoreFilled } from '@element-plus/icons-vue'
import { getProjectFiles } from '@/api/firmProject/firmProject'; // 确保路径正确
import { marked } from 'marked';
import DOMPurify from 'dompurify'; // 用于sanitize HTML，防止XSS攻击
import MentionPopup from '@/views/aiApplication/components/MentionPopup.vue';
import { h, render } from 'vue';
import { ElTag } from 'element-plus' 
import EditingInstructionsDialog from '@/views/luckysheet/EditingInstructionsDialog.vue';
import TableUtils from '@/utils/aiTableUtils'
import { fetchWithTimeout } from '@/utils/util'
import Handle from '../work/process/leave/handle.vue';
// 然后使用
const { TableOperator, useTableOperator } = TableUtils

 

export default {
  name: 'chanqatWindow',
  inject: ['projectIndex'],
  props: {
    queryParam: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    AMentions: Mentions,
    MoreFilled,
    MentionPopup,
    ElTag,
    EditingInstructionsDialog
  },
  data() {
    return {
      highlightedCells: {}, // 用于存储高亮状态
      showEditingInstructions:false,
      baseQuestion: [
        { img: '', title: '企业年度税审', desc: '全面评估企业税务合规性，识别潜在风险' },
        { img: '', title: '高新企业认定', desc: '协助企业申请高新技术企业资格，享受税收优惠' },
        { img: '', title: '研发费用加计扣除', desc: '优化研发费用核算，最大化税收优惠' },
        { img: '', title: '房地产两税清算', desc: '专业处理土地增值税和企业所得税清算' },
        { img: '', title: '专项税务审计', desc: '针对特定税务问题进行深入分析和审计' } 
      ],
      chatData: [
        // type: 1-ai 2-用户 3-检测信息
        // name 名称
        // content 对话内容
        // avatar 头像地址
        // operationType 1-显示传
        // isGenerate 判断是否为生成的答案，是的话展示底部操作，不是的话，不展示
        // { type: 1, name: '机器人-小A', content: '请问你想说什么', avatar: '' },
      ],
      testAvatar: 'https://s1.best-wallpaper.net/wallpaper/m/1711/Lithuania-flag-texture_m.webp',
      sendMSG: '', // 输入框提问输入
      sengLoading: false, // 发送状态,\
      session_id: '', // 会话id
      suggestions: [
        { value: '张三' },
        { value: '李四' },
        { value: '王五' },
        // 添加更多建议...
      ],
      tablesSortConfig:{},
      triggerChar: '@',
      lastAtIndex: -1,
      dummyValue: '',
      placeholderText: '你可以输入想要问的问题，比如最新的是什么...',
      openFiles:[],
      showFileList: false,
      searchQuery: '',
      allFiles: [],
      popupStyle: {
        top: '0px',
        left: '0px'
      },
      uploadUrl: '/api/sc-llm/llmKnowledgeDocFiles/projectFileUpload',
       
      uploadHeaders: {
        [website.tokenHeader]: 'bearer ' + getToken(),
        'TenantId': 'your-tenant-id', // 替换为实际的租户ID
        'ClientInfo': 'your-client-info' // 替换为实际的客户端信息
      },
      showMentionPopup: false,
      mentionPopupStyle: {
        top: '0px',
        left: '0px'
      },
      lastCursorPosition: null, 
      currentItems: [],
      currentPath: [],
      mentionedItems: [] , // 用于存储提取的 mention-tag 信息
      currentEditingTable: null
    }
  },
  
  computed: {
    setting() {
      return this.$store.getters.setting;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    mentionOptions() {
      return this.suggestions.map(item => ({
        value: item.value,
        label: item.value
      }));
    },
    filteredFiles() {
        return this.allFiles.filter(file => 
          file.originalName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          file.fileLink.toLowerCase().includes(this.searchQuery.toLowerCase())
        ); 
    },
  },
  created() {
    // 假数据
    /* setTimeout(() => {
      // type: 1-ai 2-用户 3-检测信息
      // name 名称
      // content 对话内容
      // avatar 头像地址
      // operationType 1-显示上传； 2-多选； 3-单选； 这三个类型都必须配置一个对应的operationValue用于存储数据，但是数据类型可能不一致
      // isGenerate 判断是否为生成的答案，是的话展示底部操作，不是的话，不展示
      // checkStatus 1-检测中； 2-检测成功； 3-检测失败
      // { type: 1, name: '机器人-小A2', content: '这是上传，isGenerate为true的时候显示底下的复制和分享，为false或者没有这个参数的时候，宽度自适应', avatar: '', isGenerate: true },
      this.chatData = [
        { type: 1, name: '机器人-小A2', content: '这是上传，isGenerate为true的时候显示底下的复制和分享，为false或者没有这个参数的时候，宽度自适应', avatar: '', isGenerate: true },
        { type: 1, name: '机器人-小A', content: '这是多选', avatar: '', operationType: 2, operationValue: [] },
        { type: 1, name: '机器人-小A', content: '这是多选', avatar: '', operationType: 2, operationValue: [] },
        { type: 1, name: '机器人-小A', content: '这是单选', avatar: '' },
        { type: 1, name: '机器人-小A', checkStatus: 1, content: '<i class="iconfont fs-14 fc-red iconicon_search"></i> 正在检查企业基本信息', avatar: '' },
        { type: 1, name: '机器人-小A', checkResult: 2, avatar: '' },
        { type: 1, name: '机器人-小A', checkResult: 3, avatar: '', operationType: 1,
          operationValue: [{
           name: '上传的文件', type: 'excel', size: '1.4MB', time: '07月13日 14:59'
          }] },
        { type: 2, name: '杨晓红', content: '没什么想说的', avatar: 'https://s1.best-wallpaper.net/wallpaper/m/1711/Lithuania-flag-texture_m.webp' },
        { type: 2, name: '杨晓红', content: '没什么想说的没什么想说的没什么想说的没什么想说的没什么想说的没什么想说的没什么想说的没什么想说的没什么想说的没什么想说的没什么想说的没什么想说的没什么想说的没什么想说的没什么想说的没什么想说的没什么想说的', avatar: 'https://s1.best-wallpaper.net/wallpaper/m/1711/Lithuania-flag-texture_m.webp' },
      ]
    }, 2000)
    */
  },
  watch: {
    queryParam: {
      handler(newVal) {
        if (newVal.projectId) {
          this.fetchProjectFiles(); 
        }
      },
      deep: true,
      immediate: true
    }
  },
  
  mounted() {
    // 使用箭头函数来保持正确的 this 上下文
    window.openFile = (fileId, fileName) => {
      this.openFile(fileId, fileName);
    };

    this.$nextTick(() => {
      document.addEventListener('click', (event) => {
          if (event.target.classList.contains('file-link')) {
            const fileId = event.target.getAttribute('data-file-id');
            const fileName = event.target.getAttribute('data-file-name');
            console.log('Fil4 zhe link clicked', fileId, fileName);
            this.openFile(fileId, fileName);
          }
        });
    });
  
    console.log('window.openFile defined:', !!window.openFile);
    window.addEventListener('resize', this.updatePopupPosition);
    window.addEventListener('scroll', this.updatePopupPosition);
    window.addEventListener('luckySheetActivated', this.handleSheetActivate)
    window.addEventListener('submitRangeDataToGenData', this.submitRangeDataToGenData)
    console.log('Event listener added in chatWindow') 
    //加载的现在的路由和名字
    this.initializeChat(this.$route.query);
    /*
    const route = useRoute();
    const key = route.query.key;
   
    console.log('this.getLuckysheetData()'+key,this.getLuckysheetData())
    */
    function removeBillInfoItems() {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith('billinfo_')) {
          localStorage.removeItem(key);
          i--; // Decrement i because the array length has changed
        }
      }
    }
    //removeBillInfoItems();
  }, 
  beforeDestroy() {
    window.removeEventListener('resize', this.updatePopupPosition);
    window.removeEventListener('scroll', this.updatePopupPosition);
    window.removeEventListener('luckySheetActivated',  this.handleSheetActivate)
    window.removeEventListener('submitRangeDataToGenData', this.submitRangeDataToGenData)
  },
 
  methods: {
    // 获取特定表格的排序配置
    getTableSortConfig (tableId){
      if (!this.tablesSortConfig[tableId]) {
        this.tablesSortConfig[tableId] = {
          column: null,
          direction: 'asc'
        };
      }
      return this.tablesSortConfig[tableId];
    },

    formatDateTime:formatDateTime,
    /**获得当前的luckysheet数据 */
    initializeChat(query){
      nextTick(()=>{
        const uniqueKey = query.key
        const params = JSON.parse(localStorage.getItem('navigationParams-'+uniqueKey));
        console.log('Chat window initialized',params,query);
        // 在这里添加你想要在 chatWindow 加载完成后执行的逻辑an
        // 例如，获取 Luckysheet 数据
      
        let decodedFileLink = null;
        if(params){
          decodedFileLink  =  decodeURIComponent(params.fileLink);
        }
        let sheetInfo = null;
        let sheetIndex = null
        if (window.luckysheet && typeof window.luckysheet.getSheetData === 'function') {
           sheetInfo = window.luckysheet.getSheet()
           sheetIndex =sheetInfo.index
          console.log('Current Luckysheet data:', sheetInfo);
        }

        if (!this.openFiles.some(f => f.fileName === params.fileName)) {
          params.fileLink = decodedFileLink
          params.fileName = params.name 
          params.sheetName = sheetInfo.name
          params.sheetIndex = sheetIndex
          this.openFiles.push(params);
        }
        this.getLuckysheetData(query);

      })
      
    },
    parseContent(content) {
      const blocks = [];
      let currentBlock = { type: 'text', content: '' };

      content.split('\n').forEach(line => {
        if (line.trim().startsWith('|')) {
          if (currentBlock.type === 'text' && currentBlock.content.trim() !== '') {
            blocks.push(currentBlock);
            currentBlock = { type: 'table', headers: [], rows: [] };
          }
          if (currentBlock.type === 'table') {
            const cells = line.split('|').filter(cell => cell.trim() !== '').map(cell => cell.trim());
            if (currentBlock.headers.length === 0) {
              currentBlock.headers = cells;
            } else {
              currentBlock.rows.push(cells);
            }
          }
        } else {
          if (currentBlock.type === 'table') {
            blocks.push(currentBlock);
            currentBlock = { type: 'text', content: '' };
          }
          currentBlock.content += line + '\n';
        }
      });

      if (currentBlock.type === 'text' && currentBlock.content.trim() !== '') {
        blocks.push(currentBlock);
      } else if (currentBlock.type === 'table') {
        blocks.push(currentBlock);
      }

      return blocks;
    },
    mergeJsonToLastMessage(jsonData) {
      if (this.chatData.length > 0) {
        const lastMessage = this.chatData[this.chatData.length - 1];
        
        if (!lastMessage.json) {
          // 如果最后一条消息还没有 JSON 数据，添加一个新的 json 属性
          this.$set(lastMessage, 'json', jsonData);
        } else {
          // 如果已经有 JSON 数据，合并新的数据
          Object.keys(jsonData).forEach(key => {
            if (lastMessage.json[key]) {
              // 如果键已存在，合并数组
              lastMessage.json[key] = [...lastMessage.json[key], ...jsonData[key]];
            } else {
              // 如果键不存在，直接赋值
              this.$set(lastMessage.json, key, jsonData[key]);
            }
          });
        }
      } else {
        // 如果没有任何消息，创建一个新的消息
        this.chatData.push({
          id: uuidv4(), 
          type: 'json',
          content: '',
          json: jsonData,
          timestamp: new Date().toISOString()
        });
      }

      // 触发视图更新
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },

    renderMarkdown(item) {
      let content = item.content
      
      // 检查是否存在 <<json>> 标记
      const jsonMatch = content.match(/<<json>>\s*(\{[\s\S]*\})/);
      
      if (jsonMatch) {
        // 提取 JSON 字符串
        const jsonString = jsonMatch[1];
        
        try {
          // 解析 JSON 字符串
          const cleanedJsonString = this.cleanJsonString(jsonString);
          const jsonData = JSON.parse(cleanedJsonString);
           
          
          // 将解析后的 JSON 数据添加到 item 对象中
          item.json = jsonData;
          
          // 从内容中移除 JSON 部分
          content = content.replace(jsonMatch[0], '');
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      }

      let jsonobj = item.json
      console.log('renderMarkdown',jsonobj  )

        // 1. 清理多余的引号和转义字符
      let cleanContent = content
        .replace(/^["']|["']$/g, '')  // 移除开头和结尾的引号
        .replace(/\\n/g, '\n')    // 替换 \n 为真实换行
        .trim();
      const linkedContent = cleanContent.replace(
        /\[\[(.*?)\|(.*?)\]\]/g,
        (match, fileName, fileId) => {
          return `<a href="javascript:void(0);" class="file-link" data-file-id="${fileId}" data-file-name="${fileName}">${fileName}</a>`;
        }
      );

      const renderer = new marked.Renderer();

      renderer.table = (header, body) => {
        // 处理 header 对象
        let headerHtml = '<tr>';
        if (header && header.header) {
          header.header.forEach((cell, index) => {
            headerHtml += `<th${header.align[index] ? ` style="text-align:${header.align[index]}"` : ''}>${cell.text}</th>`;
          });
        }
        headerHtml += '</tr>';
        
        // 处理 body
         // 处理表体
        let bodyHtml = '';
        header.rows.forEach(row => {
          let rowHtml = '<tr>';
          row.forEach((cell, index) => {
            rowHtml += `<td style="text-align:${header.align[index]}">${cell.text}</td>`;
          });
          rowHtml += '</tr>';
          bodyHtml += rowHtml;
        });
        // Add a tag in the top right corner of the table
     
        const tableHtml = `<table class="markdown-table" id="table-${item.id}"><thead>${headerHtml}</thead><tbody>${bodyHtml}</tbody></table>`;
        return this.processTable(tableHtml, item);
 
      };

      // 设置 marked 选项
      marked.setOptions({ renderer });

      const renderedContent = marked(linkedContent);
      return DOMPurify.sanitize(renderedContent);
    },

    addSortHeader(table){
    
      // 在表格创建时，给表头添加特殊的类名和数据属性
      const headers = Array.from(table.querySelectorAll('th')).map(th => {
        const headerText = th.textContent.trim();
        if (headerText.includes('借方') || headerText.includes('贷方') || headerText.includes('日期') || headerText.includes('凭证字号')  || headerText.includes('科目名称')||  headerText.includes('摘要')) {
          th.innerHTML = `
            ${headerText}
            <span class="sort-icon">↕</span>
          `;
          th.classList.add('sortable-header');
          th.dataset.column = headerText;
        }
        return headerText;
      });

     

      

      // 添加样式
      const style = document.createElement('style');
      style.textContent = `
        .sortable-header {
          cursor: pointer;
          user-select: none;
        }
        .sortable-header:hover {
          background-color: #f5f7fa;
        }
        .sort-icon {
          margin-left: 5px;
          display: inline-block;
        }
      `;
      document.head.appendChild(style);
    },
    // 修改排序处理函数，添加 th 参数
    handleSort(table,column, th) {
        const sortConfig = this.getTableSortConfig(table.id);


        const rows = Array.from(table.querySelectorAll('tbody tr'));
        const tbody = table.querySelector('tbody');

        // 更新排序状态
        if (sortConfig.column === column) {
          sortConfig.direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        } else {
          sortConfig.column = column;
          sortConfig.direction = 'asc';
        }

        // 添加排序类型判断函数
        const getSortType = (headerText) => {
          if (headerText.includes('金额') || headerText.includes('数量') || headerText.includes('借方') || headerText.includes('贷方')) {
            return 'number';
          } else if (headerText.includes('日期') || headerText.includes('时间')) {
            return 'date';
          } else {
            return 'string';
          }
        };
        // 执行排序
        rows.sort((a, b) => {
          const columnIndex = Array.from(table.querySelectorAll('th')).findIndex(header => 
            header.dataset.column === column
          );
          const sortType = getSortType(column);
          const aText = a.cells[columnIndex].textContent.trim();
          const bText = b.cells[columnIndex].textContent.trim();
          
          let comparison;
          
          switch (sortType) {
            case 'number':
              // 处理数字（包括金额）
              const aValue = parseFloat(aText.replace(/[,¥]/g, '')) || 0;
              const bValue = parseFloat(bText.replace(/[,¥]/g, '')) || 0;
              comparison = aValue - bValue;
              break;
              
            case 'date':
              // 处理日期
              const aDate = new Date(aText.replace(/年|月|日/g, '/'));
              const bDate = new Date(bText.replace(/年|月|日/g, '/'));
              comparison = aDate - bDate;
              break;
              
            default:
              // 处理中文字符串
              comparison = aText.localeCompare(bText, 'zh-CN');
          }
          
          return sortConfig.direction === 'asc' ? comparison : -comparison;
        });

        // 重新插入排序后的行
        rows.forEach(row => tbody.appendChild(row));

        // 更新所有排序图标为默认状态
        document.querySelectorAll('.sortable-header .sort-icon').forEach(icon => {
          icon.textContent = '↕';
        });

        // 更新当前列的排序图标
        const sortIcon = th.querySelector('.sort-icon');
        if (sortIcon) {
          sortIcon.textContent = sortConfig.direction === 'asc' ? '↑' : '↓';
        }
      },
    processTable(tableHtml, item) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(tableHtml, 'text/html');
      const table = doc.querySelector('table');

      if (!table) return tableHtml;
      
      const headers = Array.from(table.querySelectorAll('th')).map(th => th.textContent.trim());
      this.addSortHeader(table, headers)
      // Check if '凭证字号' is present in headers
      const voucherIndex = headers.indexOf('凭证字号');
      
      if (voucherIndex !== -1) {
        return this.processJournalTable(table, headers,item);
      }
      
      

      const isLeafIndex = headers.indexOf('is_leaf');

      if (isLeafIndex === -1) return tableHtml;

      const rows = table.querySelectorAll('tbody tr');
      rows.forEach(row => {
        const cells = row.querySelectorAll('td');
        if (cells[isLeafIndex]) {
          const isLeafValue = cells[isLeafIndex].textContent.trim();
          cells[isLeafIndex].innerHTML = this.renderTag(isLeafValue);
        }
      });

      return table.outerHTML;
    },
    processJournalTable(table, headers,item){
      // Helper function to create dropdown
      const createDropdown = (options, selectedValue) => {
        const select = document.createElement('select');
        select.className = 'el-select el-select--small table-select';
        
        // Add empty option
        const emptyOption = document.createElement('option');
        emptyOption.value = selectedValue;
        emptyOption.textContent = selectedValue;
        select.appendChild(emptyOption);
        
        // Add all options
        options.forEach(option => {
          const optionElement = new Option(option, option, false, option === selectedValue);
          select.appendChild(optionElement);
        }); 
        return select;
      };

      // Add checkbox column and action button column
      const rows = Array.from(table.querySelectorAll('tbody tr'));
      const dateIndex = headers.indexOf('日期');
      const voucherIndex = headers.indexOf('凭证字号');
      // Check for is_modified column
      const isModifiedIndex = headers.indexOf('is_modified');
      
      if (isModifiedIndex !== -1) {
        // Hide is_modified column
        const thElements = table.querySelectorAll('th');
        const tdElements = table.querySelectorAll('td');
        
        thElements[isModifiedIndex].style.display = 'none';
        
        // Process each row
        const rows = table.querySelectorAll('tbody tr');
        rows.forEach(row => {
          const cells = row.querySelectorAll('td');
          // Hide is_modified cell
          cells[isModifiedIndex].style.display = 'none';
          
          // If is_modified = 1, make entire row red
          if (cells[isModifiedIndex].textContent.trim() === '1') {
            row.style.color = 'red';
          }
        });
      }
      // Add new header for checkbox and action button
      const headerRow = table.querySelector('thead tr');
      const checkboxHeader = document.createElement('th');
      checkboxHeader.textContent = '选择';
      headerRow.insertBefore(checkboxHeader, headerRow.firstChild);
      const actionHeader = document.createElement('th');
      actionHeader.textContent = '操作';
      headerRow.appendChild(actionHeader);

      // Find parent container ID by traversing up the DOM tree
      console.log("item.info",item.info)
      if(item.info){
        let tableConfig = item.info
        if(tableConfig.category_names){
        for (const category_name of tableConfig.category_names) {
          const index = headers.indexOf(category_name)
          if (index !== -1) {
            console.log('category_name',category_name,index)
            const options = tableConfig.category_options[category_name]
            rows.forEach(row => {
              const cells = row.querySelectorAll('td');
              
              // Replace revenue type cell with dropdown if exists
              if (index !== -1 && cells[index]) {
                const currentValue = cells[index].textContent.trim();
                cells[index].innerHTML = '';
                cells[index].appendChild(createDropdown(options, currentValue));
              }
              
            
            });
            
            console.log('options',options)
          }
        }
        }
      }
      
     
      


       // Add submit button next to the header row
      const submitButton = document.createElement('button');
      submitButton.textContent = '保存修改';
      submitButton.className = 'el-button el-button--primary el-button--small table-submit-btn'; 
      const buttonContainer = document.createElement('div');
      buttonContainer.style.marginBottom = '10px';
      buttonContainer.appendChild(submitButton);
      
      // Add AI button
      const aiButton = document.createElement('button');
      aiButton.innerHTML = '<i class="el-icon"><svg viewBox="0 0 1024 1024" class="magic-icon"><path d="M832 64h64v128h128v64H896v128h-64V256H704v-64h128V64zM769.376 256l-50.688 50.688L288 737.376 224 864l126.624-64 430.688-430.688L832 320 769.376 256zM384 896H192V704l384-384 192 192-384 384z"></path></svg></i> 表格AI';
      aiButton.className = 'el-button el-button--primary el-button--small table-ai-btn';
      aiButton.style.marginBottom = '10px';
    
      buttonContainer.appendChild(aiButton);
      table.insertBefore(buttonContainer, table.firstChild);


      rows.forEach((row, index) => {
        const cells = row.querySelectorAll('td');
        const date = cells[dateIndex] ? cells[dateIndex].textContent.trim() : '';
        const voucherNo = cells[voucherIndex] ? cells[voucherIndex].textContent.trim() : '';
        const checkboxValue = `${date}${voucherNo}`;

        // Create checkbox cell
        const checkboxCell = document.createElement('td');
        const checkbox = document.createElement('input');
        checkbox.type = 'checkbox';
        checkbox.value = checkboxValue;
        checkboxCell.appendChild(checkbox);
        row.insertBefore(checkboxCell, row.firstChild);

        // Create action button cell
        const actionCell = document.createElement('td');
        actionCell.className = 'operation-cell'; // 添加样式类
        
        // 创建第一个图标按钮
        const actionButton = document.createElement('button');
        const iconSpan = document.createElement('span');
        iconSpan.innerHTML = '<i class="el-icon"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Definition-Search-Book--Streamline-Core-Neon.svg" height="14" width="14"><desc>Definition Search Book Streamline Icon: https://streamlinehq.com</desc><g id="definition-search-book"><path id="vector" stroke="#000cfe" stroke-linecap="round" stroke-linejoin="round" d="M10 12.5002c1.3807 0 2.5 -1.1192 2.5 -2.5 0 -1.38067 -1.1193 -2.49996 -2.5 -2.49996 -1.38071 0 -2.5 1.11929 -2.5 2.49996 0 1.3808 1.11929 2.5 2.5 2.5Z" stroke-width="1"></path><path id="vector_2" stroke="#000cfe" stroke-linecap="round" stroke-linejoin="round" d="m13.4995 13.4998 -1.73 -1.73" stroke-width="1"></path><path id="vector 2652" stroke="#c71dff" stroke-linecap="round" stroke-linejoin="round" d="M5.5 10.5002h-5V0.500244h3.75C5.76878 0.500244 7 1.73146 7 3.25024" stroke-width="1"></path><path id="vector 2656" stroke="#c71dff" stroke-linecap="round" stroke-linejoin="round" d="M7 2.00024v4.5" stroke-width="1"></path><path id="vector 2653" stroke="#c71dff" stroke-linecap="round" stroke-linejoin="round" d="M13.5 7.50024V0.5H9.75C8.23122 0.5 7 1.73122 7 3.25" stroke-width="1"></path></g></svg></i>';
        iconSpan.style.pointerEvents = 'none'; // 禁止图标的事件响应
        actionButton.appendChild(iconSpan);
        actionButton.className = 'el-button el-button--primary el-button--small el-button--link view-journal-entry';
        actionButton.dataset.checkboxValue = checkboxValue;
        actionButton.dataset.index = index;

        // 创建第二个图标按钮  
        const actionButton2 = document.createElement('button');
        const iconSpan2 = document.createElement('span');
        iconSpan2.innerHTML = '<i class="el-icon"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Bookmark-1--Streamline-Core-Neon.svg" height="14" width="14"><desc>Bookmark 1 Streamline Icon: https://streamlinehq.com</desc><g id="bookmark-1--bookmarks-tags-favorite"><path id="Vector 4007" stroke="#c71dff" stroke-linecap="round" stroke-linejoin="round" d="M2 1.5v12L7 10l5 3.5v-12c0 -0.552285 -0.4477 -1 -1 -1H3c-0.55228 0 -1 0.447715 -1 1Z" stroke-width="1"></path></g></svg></i>';
        iconSpan2.style.pointerEvents = 'none'; // 禁止图标的事件响应
        actionButton2.appendChild(iconSpan2);
        actionButton2.className = 'el-button el-button--primary el-button--small el-button--link view-vat-file';
        actionButton2.dataset.checkboxValue = checkboxValue;
        actionButton2.dataset.index = index;
        actionButton2.dataset.itemindex = item.id;

        actionCell.appendChild(actionButton);
        actionCell.appendChild(actionButton2);
        row.appendChild(actionCell);
 

      });

      return table.outerHTML;
    },
    findTableConfig(configid){
      return this.chatData.find(item => item.id === configid).tableConfig
    },
     // 或者更通用的方法
    getNextTable(element) {
      let next = element;
      while (next = next.nextElementSibling) {
        if (next.tagName === 'TABLE' || 
            next.classList.contains('markdown-table')) {
          return next;
        }
      }
      return null;
    },
    handleTableClick(event) {
      if (event.target.classList.contains('view-journal-entry')) { 
        console.log('view-journal-entry')
        event.preventDefault();
        event.stopPropagation();
        const index = event.target.dataset.index;
        const checkboxValue = event.target.dataset.checkboxValue;
        console.log('Checkbox value:', checkboxValue); // 添加这行来检查值
        if (checkboxValue) {
          this.viewJournalEntry(checkboxValue);
        } else {
          console.error('No checkbox value found on the button');
        }
      }


      if (event.target.classList.contains('sortable-header')) { 
        console.log('sortable-header')
        const column = event.target.dataset.column;
        const table = event.target.closest('table');
        if (!table) {
          console.error('No table found');
          return;
        }
        this.handleSort(table,column, event.target)
      }

      if (event.target.classList.contains('table-submit-btn')) { 
        const currentTable = event.target.closest('.markdown-table');
    
        if (!currentTable) {
          // 获取当前表格后面的元素
          const nextElement = event.target.parentElement.nextElementSibling;
          
          console.log('Next element:', nextElement);
          
          if (nextElement) {
            // 如果是表格，直接使用
            if (nextElement.classList.contains('markdown-table')) {
              // 处理下一个表格
              this.handleTableSubmit(nextElement);
            }
          }
        } 
      }
      if (event.target.classList.contains('table-select')) { 
          // 移除所有行的高亮
          const select = event.target;
          const currentEditingTable = select.closest('table');
          const allRows = select.closest('table').querySelectorAll('tr');
          allRows.forEach(row => {
            row.classList.remove('highlighted-row');
          });

            // 当选择值发生变化时
            select.addEventListener('change', (e) => {
              const currentRow = select.closest('tr');
              if (currentRow) {
                // 高亮当前行
                currentRow.classList.add('highlighted-row');
                
                // 选中当前行的复选框
                const checkbox = currentRow.querySelector('td:first-child input[type="checkbox"]');
                if (checkbox) {
                  checkbox.checked = true;
                  // 可选：触发复选框的 change 事件
                  checkbox.dispatchEvent(new Event('change', { bubbles: true }));
                }

                const rowIndex = Array.from(allRows).indexOf(currentRow);
                const { tableOperator } = useTableOperator(currentEditingTable);
                tableOperator.highlightRow(rowIndex);
              }
            });
      }

      if (event.target.classList.contains('table-ai-btn')) { 
        const currentTable = event.target.closest('.markdown-table');
    
        if (!currentTable) {
          // 获取当前表格后面的元素
          const nextElement = event.target.parentElement.nextElementSibling; 
          console.log('Next element:', nextElement);
          
          if (nextElement) {
            // 如果是表格，直接使用
            if (nextElement.classList.contains('markdown-table')) {
              // 处理下一个表格
              this.handleTableAI(nextElement);
            }
          }
        } 
      }

      if (event.target.classList.contains('view-vat-file')) { 
        event.preventDefault();
        event.stopPropagation();
        this.handleViewVatFile(event)
      }
      
    },
    handleEditingInstructionsSubmit(data) {
      console.log('Editing instructions submitted:', data);
      // 可以访问 data.content 和 data.type
      //提交后台访问
      process_htmltable(data.content).then(res=>{
        console.log('process_htmltable',res)
        this.showEditingInstructions = false;
        this.handleTableAIDone(res.data)
      }) 
    },
    handleTableAIDone(data){
      console.log('handleTableAIDone',data)
      const { tableOperator } = useTableOperator(this.currentEditingTable)
      tableOperator.highlightRow(1)
      // 调用 setSelectValuesByConditions 方法
      if(data.conditions){
        const conditions = data.conditions
        tableOperator.setSelectValuesByConditions(conditions,  data.new_value);
      }
      this.currentEditingTable = null
      console.log('tableOperator',tableOperator)
    },
    handleTableAI(table){
      console.log('handleTableAI',table)
      this.showEditingInstructions = true 

      this.currentEditingTable = table
     
    },
    handleTableSubmit(table){
      console.log('handleTableSubmit')
       const selectedRows = [];
       const tbody = table.querySelector('tbody');
       if (!tbody) {
         console.error('No tbody found in table');
         return;
       }
       // Get headers from the table header row
       const headers = [];
       const headerRow = table.querySelector('thead tr');
       if (!headerRow) {
         console.error('No header row found in table');
         return;
       }
       // Skip first cell (checkbox column) and get header text
       headerRow.querySelectorAll('th').forEach((th, index) => {
         if (index > 0) { // Skip checkbox column
           headers.push(th.dataset.column || th.textContent.trim());
         }
       });
       const rows = tbody.querySelectorAll('tr');
       rows.forEach((row, index) => {
          const checkbox = row.querySelector('input[type="checkbox"]');
          if (checkbox && checkbox.checked) {
            const rowData = {};
            headers.forEach((header, colIndex) => {
              const td = row.querySelectorAll('td')[colIndex + 1]; // +1 to skip checkbox column
              const select = td.querySelector('select');
              if (select) {
                // If there's a select element, get its selected value
                rowData[header] = select.value;
              } else {
                // Otherwise get the text content
                rowData[header] = td.textContent.trim();
              }
            });
            selectedRows.push(rowData);
          }
        });
        console.log('selectedRows',selectedRows)
        
        if (selectedRows.length === 0) {
          // Use Element Plus message
          this.$message({
            message: '请至少选择一条记录',
            type: 'warning'
          });
          return;
        }

        save_user_customformat_table(this.queryParam.projectId,selectedRows).then(res=>{
          console.log('save_user_customformat_table',res)
          this.$message.success('保存成功！请点击“自动生成数据”按钮重新生成数据');
        })
        console.log('Selected rows:', selectedRows);
         
      
    },
    findLinkForMonth(jsonData, targetMonth) {
      const monthKey = Object.keys(jsonData).find(key => key.includes(targetMonth));
      if (monthKey && jsonData[monthKey].length > 0) {
        return jsonData[monthKey][0]
      }
      return null;
    },
    handleViewVatFile(event) {
      const itemId = event.target.dataset.itemindex;
      if (itemId) {
        const item = this.chatData.find(chatItem => chatItem.id === itemId);
        if (item && item.json) {
          console.log('Item JSON:', item.json);
          
          // 从行号中提取月份
          const match = event.target.dataset.checkboxValue.match(/(\d{4}-(\d{2})-\d{2})记 - (\d+)/);
          if (match) {
            const [, fullDate, month, period] = match;
            console.log(`提取的月份: ${month}月`);
            
            // 查找对应月份的第一个元素的 link
            const link = this.findLinkForMonth(item.json, `${month}月`);
            if (link) {
              console.log(`找到的链接: ${link}`);
              // 这里可以添加打开链接或进行其他操作的逻辑
              this.projectIndex.handlePreviewFile(link);
            } else {
              console.log(`未找到 ${month}月 的链接`);
            }
          } else {
            console.error('无法从 checkboxValue 中提取日期和会计期间');
          }
        } else {
          console.log('No JSON data found for item with id:', itemId);
        }
      } else {
        console.error('No item id found on the button');
      }
    },
    findRowByDateAndPeriod(date, period) {
      // 获取当前sheet的数据
      const sheetData = window.luckysheet.getSheetData(); 
      
      // 预处理 date 和 period，移除所有空格
      const cleanDate = date.replace(/\s/g, '');
      const cleanPeriod = period.replace(/\s/g, '');
      
      // 遍历行，从第3行开始（跳过表头）
      for (let rowIndex = 2; rowIndex < sheetData.length; rowIndex++) {
        const row = sheetData[rowIndex];
        
        // 检查整行是否包含日期和会计期间
        let hasDate = false;
        let hasPeriod = false;
        
        // 遍历该行的所有单元格
        for (let colIndex = 0; colIndex < row.length; colIndex++) {
          const cell = row[colIndex];
          const cellValue = cell ? cell.m ? cell.m : cell.v : null;
          
          if (cellValue) {
             // 移除单元格值中的所有空格后再比较
            const cleanCellValue = String(cellValue).replace(/\s/g, '');
            
            // 检查单元格是否包含日期
            if (cleanCellValue === cleanDate) {
              hasDate = true;
            }
            // 检查单元格是否包含会计期间
            if (cleanCellValue === cleanPeriod) {
              hasPeriod = true;
            }
          }
        }
        
        // 如果该行同时包含日期和会计期间，返回行号
        if (hasDate && hasPeriod) {
          return rowIndex + 1;
        }
      }
      
      // 如果没有找到匹配的行，返回-1或者抛出一个错误
      return -1;
    },
    activateFirstSheet() {
      if (window.luckysheet) {
        window.luckysheet.setSheetActive(0);
        
      } else {
        console.warn('Luckysheet 未初始化');
      }
    },
    scrollToJournalEntry(targetRow) {
      if (window.luckysheet && typeof window.luckysheet.scroll === 'function') {
        window.luckysheet.scroll({
          targetRow: Math.max(0, targetRow - 10),  // 确保目标行在视图中居中
          targetColumn: 0,
          success: () => {
            setTimeout(() => {
              const sheetData = window.luckysheet.getSheetData();
              const lastColumn = sheetData[0].length - 1;
              const range = `A${targetRow}:${String.fromCharCode(65 + lastColumn)}${targetRow}`;
              
              window.luckysheet.setRangeShow(range, {show: true});
              
               
            }, 100);
          }
        });
      } else {
        console.warn('Luckysheet 未初始化或不支持滚动功能');
      }
    },
    viewJournalEntry(checkboxValue){
      console.log('viewJournalEntry',checkboxValue)
      // 获取序时账文件
      const journalFile = this.allFiles.find(file => file.originalName.includes('序时账') || file.financialStatementTypeValue.includes('序时账'));
      
      if (journalFile) {
        this.navigateToLucksheet(journalFile)
        // 一秒钟后滚动 luckysheet 到 A50
        setTimeout(() => {
          if (window.luckysheet && typeof window.luckysheet.scroll === 'function') {
            this.activateFirstSheet();
            setTimeout(() => {
              console.log('viewJournalEntry2',checkboxValue)
               // 从 checkboxValue 中提取日期和会计期间
            const match = checkboxValue.match(/(\d{4}-\d{2}-\d{2})记\s*-\s*(\d+)/);
            if (match) {
              const date = match[1]; // 年-月-日
              const period = '记-' + match[2]; // 记-xx 中的 xx (无空格)
              
              // 使用 findRowByDateAndPeriod 方法查找对应的行，同时尝试有空格和无空格的情况
              const targetRow = this.findRowByDateAndPeriod(date, '记-' + match[2]) 
              if (targetRow !== -1) {
                // 如果找到了匹配的行，更新滚动目标
                window.luckysheet.scroll({
                  targetRow: targetRow -10 ,  // 因为 Luckysheet 的行号从0开始，所以要减1 //喊他居中吧
                  targetColumn: 0 ,// 滚动到第1列（A列）,
                  success: function() {
                    setTimeout(() => {
                      // 选中目标行的整行
                      const lastColumn = window.luckysheet.getSheetData()[0].length - 1;
                      //${String.fromCharCode(65 + lastColumn)}
                      const range = `A${targetRow}:Z${targetRow}`;
                      console.log('滚动完成',range);
                      window.luckysheet.setRangeShow(range,{show:true});
                      //window.luckysheet.setRangeShow("A6:I20",{show:true})  
                      // 高亮显示选中的行
                      // 高亮显示选中的行
                     /* for (let col = 0; col <= lastColumn; col++) {
                        window.luckysheet.setCellFormat(targetRow - 1, col, "bg", "#FFFF00");
                        window.luckysheet.setCellFormat(targetRow - 1, col, "fc", "#FF0000");
                      }

                      // 3秒后取消高亮
                      setTimeout(() => {
                        for (let col = 0; col <= lastColumn; col++) {
                          window.luckysheet.setCellFormat(targetRow - 1, col, "bg", null);
                          window.luckysheet.setCellFormat(targetRow - 1, col, "fc", null);
                        }
                      }, 3000);*/
                      /*
                      window.luckysheet.setCellFormat(targetRow - 1, null, {
                        bg: '#FFFF00', // 设置背景色为黄色
                        fc: '#FF0000'  // 设置字体颜色为红色
                      });

                      // 3秒后取消高亮
                      setTimeout(() => {
                        window.luckysheet.setCellFormat(targetRow - 1, null, {
                          bg: null,
                          fc: null
                        });
                      }, 3000);
                      */
                  } ,200)
                }
              });
                
                
              } else {
                console.error('未找到匹配的行');
                this.$message.warning('未找到匹配的记录');
              }
            } else {
              console.error('无法从 checkboxValue 中提取日期和会计期间');
              this.$message.warning('记录格式不正确');
            }
            },100)
            
           
            
           
          } else {
            console.error('Luckysheet is not initialized or scrollToCell method is not available');
          }
        }, 2000);
      } else {
        this.$message.warning('未找到序时账文件');
      }
    },


    renderTag(value) {
      const container = document.createElement('div');
      const vnode = h(ElTag, {
        type: value === 'true' ? 'success' : 'info' ,
        effect: 'light',
        class: 'static-tag' // 添加一个自定义类
      }, () => value);
      render(vnode, container);
      return container.innerHTML;
    },
    
    openFile(fileId, fileName)  {
      console.log(`Opening file: ${fileName} with ID: ${fileId}`);
      // Implement your file opening logic here 
      this.$router.push({   
        path: '/file-viewer/index',
        query: { 
          projectId:this.queryParam.projectId,
          projectName:this.queryParam.projectName,
          enterpriseId:this.queryParam.enterpriseId,
          fileId: fileId,
          fileName: fileName,
          name:fileName 
        }
      });
    },
    getLuckysheetData(query) {
      if (window.luckysheet && typeof window.luckysheet.getSheetData === 'function') {
        const currentSheetData = window.luckysheet.getSheetData();
        //console.log('Current Luckysheet data:', currentSheetData,window.luckysheet.getSheet());
        return currentSheetData;
      } else {
        //console.error('Luckysheet is not initialized or getSheetData method is not available');
        return null;
      }
    }, 
    downloadFile(file) {
      // 这里假设 file 对象中有一个 fileLink 属性
      if (file.fileLink) {
        // 创建一个隐藏的 a 标签来触发下载
        const link = document.createElement('a');
        link.href = file.fileLink;
        link.download = file.dictValue || 'download';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        this.$message.error('下载链接不可用');
      }
    },

    // 辅助函数：检查是否已经存在相似的文件名
   // 辅助函数：检查文件名是否包含目标类型
   containsTargetType(fileName, targetTypes) {
      return targetTypes.some(type => fileName.includes(type));
    },


    async submitRangeDataToGenData(event){
      console.log('submitRangeDataToGenData',event.detail.data)
      const { originalName, sheetName, fileLink, range, rangeValue, messageType = 'gendata' } = event.detail;
      // 获取当前已打开的文件名列表
      let sheetInfo = null;
      if(messageType=='autoGendata'){
        this.openFiles = []
      }else{
        const openFileNames = this.openFiles.map(file => file.originalName);

        // 定义要查找的文件类型
        const targetTypes = ['利润表', '资产负债表', '科目余额表'];

        // 遍历 allFiles，找到匹配的文件并添加到 openFiles
        this.allFiles.forEach(file => {
          if (this.containsTargetType(file.originalName, targetTypes) && !this.openFiles.some(f => f.originalName === file.originalName)) {
      
            let decodedFileLink = null;
            if(file.fileLink){
              decodedFileLink  =  decodeURIComponent(file.fileLink);
            }
            file.fileLink = decodedFileLink;
            file.sheetName  = file.sheetName || '';

            file.name = file.name || file.originalName || file.fileName;
            
            this.openFiles.push(file);
          }
        });
      }
      
      if (range) {
        //生成数据先把 包括 税审 底稿的 数据 先删除
        this.openFiles = this.openFiles.filter(file => 
          !(file.originalName.includes('税审') || file.originalName.includes('底稿'))
        );
        // 如果有 range，先删除同一 sheetName 但没有 range 的元素
        this.openFiles = this.openFiles.filter(file => 
          !(file.originalName == originalName && file.sheetName === event.detail.sheetName )
        );
        sheetInfo = await this.getSheetContentInfo(originalName,fileLink,sheetName);
      }

      
       // If the file doesn't exist, add it to openFiles 
       this.openFiles.push({
          ...event.detail,
          sheetName: event.detail.sheetName,
          range: range || null,
          rangeText: this.convertToA1Notation(range) || null,
          rangeValue: rangeValue || null,
          sheetInfo:sheetInfo
        });  

      // 更新 placeholderText
      if (this.openFiles.length > 0) {
        const editableDiv = this.$refs.editableDiv;
        if (editableDiv) {
          editableDiv.textContent = '请帮我填写审核表中选中的空白区域';
          this.sendMessage(messageType);
        }
        this.sengLoading = true;

      }
    },
    
    async fetchProjectFiles() {
      try {
        console.log('fetchProjectFiles',this.queryParam)
        const response = await getProjectFiles(this.queryParam.projectId);
        if (response.data.code==200) {
          this.allFiles = response.data.data;
        } else {
          this.$message.error('Failed to load project files');
        }
      } catch (error) {
        console.error('Error fetching project files:', error);
        this.$message.error('Error fetching project files');
      }
    },
    
    navigateToLucksheet(item) { 
      const uniqueKey = `${item.id}` //`${item.id}-${Math.random().toString(36).substr(2, 9)}`; // 基于 item.id 生成唯一的 key
      localStorage.setItem('navigationParams-'+uniqueKey, JSON.stringify({
        id:item.id,
        fileName:item.fileName,
        name:item.originalName,
        originalName: item.originalName,
        fileLink: encodeURIComponent(item.fileLink) , 
      }));
      console.log('navigateToLucksheet',this.$route.meta)
      this.$router.push({  
        path: '/luckysheet/index',
        query: { 
          // 可以根据需要添加更多参数
          projectId:this.queryParam.projectId,
          key:uniqueKey,
          name:item.originalName || item.fileName,
          enterpriseId:this.queryParam.enterpriseId,
          projectName:this.queryParam.projectName
        }
      });
    },
    async customUpload(options,itemData) {
      try {
        const formData = new FormData()
        formData.append('file', options.file)
        formData.append('projectId', this.queryParam.projectId)
        formData.append('financialStatementType', itemData.dictKey) 

        const response = await axios.post(this.uploadUrl, formData, {
          headers: {
            ...this.uploadHeaders,
            'Content-Type': 'multipart/form-data'
          }
        })

        options.onSuccess(response.data)
        
        // 更新 it.associatedFiles
        if (!itemData.associatedFiles) {
          itemData.associatedFiles = []
        }
        itemData.associatedFiles.push(response.data)
        
      } catch (error) {
        console.error('Upload error:', error)
        options.onError(error)
      }
    },
    convertToA1Notation(rangeString) {
        if (!rangeString || rangeString === '') {
          return '';
        }

        function columnToLetter(column) {
          let temp, letter = '';
          while (column >= 0) {
            temp = column % 26;
            letter = String.fromCharCode(temp + 65) + letter;
            column = Math.floor(column / 26) - 1;
          }
          return letter;
        }

        // 解析输入字符串
        const matches = rangeString.match(/\((\d+),\s*(\d+)\)\s*to\s*\((\d+),\s*(\d+)\)/);
        if (!matches) {
          return 'invalid_range';
        }

        const [, startRow, startCol, endRow, endCol] = matches.map(Number);

        const startColLetter = columnToLetter(startCol);
        const endColLetter = columnToLetter(endCol);
        const startRowNumber = startRow + 1; // 加1因为Excel行从1开始
        const endRowNumber = endRow + 1;

        if (startColLetter === endColLetter && startRowNumber === endRowNumber) {
          return `${startColLetter}${startRowNumber}`;
        }

        return `${startColLetter}${startRowNumber}:${endColLetter}${endRowNumber}`;
      },
    async getSheetContentInfo(originalName,fileLink,sheetName){
      // 生成一个基于 fileLink 和 sheetName 的唯一 cacheKey
      const cacheKey = await generateUUIDFromString(`${fileLink}_${sheetName}`);
      
      const cachedData = localStorage.getItem('billinfo_'+cacheKey);
      let sheetInfo = null;
      if (cachedData) { 
        // 如果有缓存数据，直接使用
        console.log('Using cached data for', cacheKey, JSON.parse(cachedData));
        sheetInfo = JSON.parse(cachedData);
        // 这里可以进行进一步的处理，比如更新 UI 等
      } else if (originalName && originalName.includes('税审')) {
        // 如果没有缓存数据，且文件名包含"税审"，则发送请求
        try {
          // const response = await gen_table_struction(fileLink, sheetName);
          // console.log('gen_table_struction', response);
          
          // // 将响应数据存储到 localStorage
          // localStorage.setItem('billinfo_'+cacheKey, JSON.stringify(response.data));
          // sheetInfo = response.data;
          // 这里可以进行进一步的处理，比如更新 UI 等
        } catch (error) {
          console.error('Error in gen_table_struction:', error);
        }
      }
      return sheetInfo;
    },
    async handleSheetActivate(event) {
      console.log('handleSheetActivate', event.detail.data)
      const { originalName, sheetName, range, rangeValue, fileLink } = event.detail;
     
      let existingFileIndex = this.openFiles.findIndex(f => 
        f.originalName === originalName && f.range === range && f.sheetName === sheetName
      );
      if (existingFileIndex !== -1) {
        // If the file already exists, update its sheetName
        this.openFiles[existingFileIndex] = {
          ...this.openFiles[existingFileIndex],
          sheetName: event.detail.sheetName,
          range: range || null,
          rangeText: this.convertToA1Notation(range) || null,
          rangeValue: rangeValue || null 
        }; 
      } else {
        // If the file doesn't exist, add it to openFiles 
        this.openFiles.push({
          ...event.detail,
          sheetName: event.detail.sheetName,
          range: range || null,
          rangeText: this.convertToA1Notation(range) || null,
          rangeValue: rangeValue || null ,  
        });
      }

      let sheetInfo = await this.getSheetContentInfo(originalName,fileLink,sheetName);
      existingFileIndex = this.openFiles.findIndex(f => 
        f.originalName === originalName &&
        (
          (f.range === (range||null)) || 
          (typeof(f.range) === 'undefined' && typeof(range) === 'undefined')
        ) && f.sheetName === sheetName
      );
      this.openFiles[existingFileIndex].sheetInfo = sheetInfo
      
      console.log('Updated openFiles:', this.openFiles);
    },
    handleCloseExcelPreview() {
      this.excelPreviewVisible = false;
      this.excelPreviewUrl = '';
    },
    getOperationType(biz_type){ 
      switch(biz_type){
        case 'File':
        case 'FileList':
          return 1
        default:
          return 0
      } 
    },
    // 执行历史记录点击后 获取对话历史的操作， param为从外部传递进来的被点击记录的相关数据
    getHistory(item,param) {
      console.log('获取历史对话记录，然后写入 chatData',param)
      //根据param.id 获取历史对话记录
      // 将获取到的历史对话记录写入 chatData
      // 将 chatData 返回给父组件
      // 模拟API调用获取历史对话记录
      let convertItem = (item) => {
          let info = item.info
          try{
            info = JSON.parse(info)
            info = parseNestedJson(info)
          }catch(e){
            //console.error('Error parsing info:', e);
          }
          console.log('item',info)
          // Remove <<>> prefix from content if present
          if (item.content && typeof item.content === 'string') {
            item.content = item.content.replace(/^"(.*)"$/, '$1');
            item.content = item.content.replace(/^<<[a-zA-Z]+>>/, '');
          }
          const jsonStringMatch = item.content.match(/```json\s*([\s\S]*?)\s*```/);
          if (jsonStringMatch && jsonStringMatch[1]) {
            item.content = "";
          }

          return {
            id:item.id,
            type: item.role === 'user' ? 2 : (item.role === 'system' ? 3 : 1),
            name: item.name || item.role,
            content: item.content,
            avatar: item.avatar || '',
            isGenerate: item.isGenerate,
            operationType: this.getOperationType(item.biz_type),
            operationValue: info?.operationValue || [],
            operationNeed: info?.operationNeed || info?.operation_need || [], 
            info:info,
            isHidden: item.content==""?true:false
        }
      }

      //设置param.id为当前对话的session_id
      this.session_id = param.id
      
      getChatMessages(param.id).then(response => { 
        if (response.data.code==200) {
          // 将获取到的历史对话记录写入 chatData
          console.log(response.data.data)
          this.chatData = response.data.data.map(item => {
            const convertedItem = convertItem(item);

            // 处理 nextbox 标记的内容 
            return convertedItem;
          });

          console.log('this.chatData',this.chatData)
           
          // 滚��到聊天窗口底部
          this.$nextTick(() => {
            const chatElement = document.getElementById('chat');
            if (chatElement) {
              chatElement.scrollTop = chatElement.scrollHeight;
            }
          });

          // 将 chatData 返回给父组件
          this.$emit('chat-history-loaded', this.chatData);
        } else {
          console.error('Failed to load chat history');
          this.$message.error('加载历史对话记录失败');
        }
      }).catch(error => {
        console.error('Error loading chat history:', error);
        this.$message.error('加载历史对话记录时发生错误');
      }); 
    },

    getFileIconClass(filePath) {
      const extension = filePath.split('.').pop().toLowerCase();
      const iconMap = {
        vue: 'vue-icon',
        css: 'css-icon',
        html: 'html-icon',
        js: 'js-icon',
        scss: 'scss-icon',
        json: 'json-icon',
        pdf: 'pdf-icon',
        xls: 'excel-icon',
        xlsx: 'excel-icon',
        doc: 'word-icon',
        docx: 'word-icon',
        ppt: 'pp-icon',
        pptx: 'ppt-icon',
        txt: 'txt-icon',
        other: 'other-icon'
      };
      return iconMap[extension] || 'default-icon';
    },
    // 上传操作
    handleAvatarSuccess(response, file, fileList) {
      if (response.code === 200) {
        this.$message.success('文件上传成功');
        // 处理上传成功���的逻辑，例如更新 it.associatedFiles
      } else {
        this.$message.error('文件上传失败：' + response.msg);
      }
    },
    beforeAvatarUpload(file) {
      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel';
      const isLt60M = file.size / 1024 / 1024 < 60;

      if (!isExcel) {
        this.$message.error('上传文件只能是 Excel 格式!');
        return false;
      }
      if (!isLt60M) {
        this.$message.error('上传文件大小不可超过 60MB!');
        return false;
      }
      return true;
    },
    fillDataToLuckysheet(content){
      console.log('fillDataToLuckysheet',content)
      let data  = null
      try {  
        // 如果 content 已经是对象，就不需要解析
        if (typeof content === 'string') {
          const jsonStringMatch = content.match(/```json\s*([\s\S]*?)\s*```/);
          if (jsonStringMatch && jsonStringMatch[1]) {
            let jsonString = jsonStringMatch[1];
            // 替换单引号为双引号
            // 2. 替换 nan 为 null
            content =   jsonString
            .replace(/:\s*nan\b/gi, ': null')    // 处理 nan/NaN
            .replace(/:\s*NaN\b/g, ': null')     // 明确处理 NaN
            .replace(/:\s*undefined\b/g, ': null')// 处理 undefined
            .replace(/'/g, '"');                 // 替换单引号为双引号
          
            content = content.replace(/'/g, '"');
            data = JSON.parse(content);
          }else{
            // 替换单引号为双引号
            content = content.replace(/:\s*nan\b/g, ': null').replace(/:\s*nan\b/gi, ': null')
        .replace(/:\s*NaN\b/g, ': null')
        .replace(/:\s*undefined\b/g, ': null')
        .replace(/'/g, '"');
      
            content = content.replace(/'/g, '"');
            data = JSON.parse(content);
          } 
        } else {
          data = content;
        }
      } catch (e) {
       // console.log('Failed to parse content as JSON:', e);
        data = content;
      }
      const event = new CustomEvent('fillDataToLuckysheet', { detail: { 
        data}
      })   
            
      window.dispatchEvent(event)
      console.log('fillDataToLuckysheet event dispatched', event) 
    },
    cleanJsonString(jsonString) {
      return jsonString.trim()
        .replace(/^['"]|['"]$/g, '')  // 移除开头和结尾的引号
        .replace(/'/g, '"');  // 替换���有单引号为双引号
    },
    renderContent(content, textBeforeJson = '') {
      if (Array.isArray(content)) {
        return this.renderMarkdownTable(content, textBeforeJson);
      } else if (typeof content === 'object') {
        return JSON.stringify(content, null, 2);
      } else {
        return content;
      }
    },

    renderMarkdownTable(data, textBeforeJson) {
      if (data.length === 0) return '';

      let markdown = `${textBeforeJson}\n\n| `;
      
      // 添加表头
      for (let key in data[0]) {
        markdown += `${key} | `;
      }
      markdown += '\n| ';
      for (let key in data[0]) {
        markdown += '--- | ';
      }
      markdown += '\n';

      // 添加数据行
      data.forEach(row => {
        markdown += '| ';
        for (let key in row) {
          markdown += `${row[key]} | `;
        }
        markdown += '\n';
      });

      return this.renderMarkdown(markdown);
    },
 
    processTaggedContent(tag, content) {
      console.error(`Processing ${tag}:`, content);
      let isJson = false;
      let parsedContent = "";
      let textBeforeJson = content;
       
      try {  
        // 提取 JSON 字符串之间的内容
        const jsonStringMatch = content.match(/```json\s*([\s\S]*?)\s*```/);
        if (jsonStringMatch && jsonStringMatch[1]) {
          const jsonString = jsonStringMatch[1];
          const cleanedContent = this.cleanJsonString(jsonString);
          parsedContent = JSON.parse(cleanedContent);
          isJson = true;
          // 提取 JSON 之前的文本
          textBeforeJson = content.split(jsonStringMatch[0])[0];
          
        } else {
           // 尝试清理内容中的多余字符
           const cleanedContent = this.cleanJsonString(content);
          
        //  .replace(/(\w+):/g, '"$1":');  // 确保所有键名都被双引号包裹
          // 尝试解析 JSON
          parsedContent = JSON.parse(cleanedContent);
          isJson = true;
        }
      } catch (e) {
        //console.log('Failed to parse content as JSON:', e);
        parsedContent = content;
        isJson = false;
      }

      if (tag === '<<gendata>>') {
        this.fillDataToLuckysheet(content);
        return;
      }

      if (tag === null && isJson == false ) {
        if ( this.chatData.length > 0) {
          this.chatData[this.chatData.length - 1].content += content;
        } 

        return;
      }else{
        if(tag===null && isJson == true){
          tag = '<<nextbox>>'
          this.chatData = this.chatData.slice(0, -1);
        }
      }

      function decodeUnicode(str) {
        return str.replace(/\\u[\dA-F]{4}/gi, 
          function (match) {
            return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
          });
      }

      

      
      
      console.log(isJson)
      switch (tag) {
        case '<<nextbox>>':
          let temp = {
            id: uuidv4(), // 添加随机生成的 id
            type: 1,
            role: 'bot',
            name: '税审小智',
            content: (isJson && (parsedContent.type == "File")||  (parsedContent.type == "FileList")) ? content : this.renderContent(parsedContent, textBeforeJson),
            avatar: this.testAvatar,
            session_id: this.session_id,
            isDisplay: true,
            isChart: false
          };
          if (isJson) {
            if (parsedContent.content) {
              temp.content = this.renderContent(parsedContent.content, textBeforeJson);
              temp.info = parsedContent.info;
            }

            if (parsedContent.type == "File") {
              temp.operationType = 1;
              temp.operationValue = parsedContent.data;
            }
            if (parsedContent.type == "FileList") {
              temp.operationType = 1;
              try {  
                parsedContent.data = JSON.parse(parsedContent.data);
                parsedContent.data = parseNestedJson(parsedContent.data);
              } catch(ex) {
                console.error('Error parsing FileList data:', ex);
              }
              temp.operationNeed = parsedContent.data;
              temp.operationValue = [];
              temp.content = ""
            }
            if (parsedContent.type == "TableConfig") {
               console.log('TableConfig',parsedContent)
               // Get the last message id
               const lastMessageId = this.chatData[this.chatData.length - 1]?.id;
               if (lastMessageId) {
                 // Bind the parsed content to the last message
                 this.chatData[this.chatData.length - 1].tableConfig = parsedContent; 
               } 
            }
          }
          this.chatData.push(temp);
          // 在下一个 DOM 更新周期中滚动到底部
          this.$nextTick(() => {
            this.scrollToBottom();
          });
          break;
        case '<<clear>>':
          this.chatData = [];
          break;
        case '<<chart>>':
          this.chatData.push({
            id: uuidv4(), // 添加随机生成的 id
            type: 1,
            role: 'bot',
            name: 'AI Assistant',
            content: content,
            avatar: this.testAvatar,
            session_id: this.session_id,
            isChart: true
          });
          break;
        case '<<answer>>':
          this.chatData.push({
            id: uuidv4(), // 添加随机生成的 id
            type: 1,
            role: 'bot',
            name: 'AI Assistant',
            content: content,
            avatar: this.testAvatar,
            session_id: this.session_id
          });
          break;
        /*case '<<json>>':
          console.log('<<json>>',parsedContent)
            // 将 JSON 数据合并到当前对话的最后一条消息中
          this.mergeJsonToLastMessage(parsedContent);
          break;*/
        case '<<relatefile>>':
          // 处理相关文件
          // 这里需要根据你的需求来实现
          console.log('Related file:', content);
          break;
        case '<<error>>':
          console.error('Error from server:', content);
          this.$message.error(content);
          break;
        default:
          console.warn('Unknown tag:', tag);
          if (this.chatData.length > 0) {
            this.chatData[this.chatData.length - 1].content += content;
          }
          break;
      }
    },
    // 发送提问
    async sendMessage(generate_type,additional_params) { 
      
      const editableDiv = this.$refs.editableDiv;
      const mentionTags = editableDiv.querySelectorAll('.mention-tag');
      this.mentionedItems = [];
      // 提取 mention-tag 信息
      mentionTags.forEach((tag, index) => {
        this.mentionedItems.push({
          id: tag.getAttribute('data-id'),
          type: tag.getAttribute('data-type'),
          url: tag.getAttribute('data-url'),
          name: tag.textContent.slice(1), // 移除 '@' 符号
          index: index // 保存标签在文本中的顺序
        });
      });
      // 获取纯文本内容并清除 mention-tag
      let messageText = editableDiv.innerText;

      mentionTags.forEach(tag => {
        //messageText = messageText.replace(tag.textContent, `@${tag.getAttribute('data-id')}`);
        messageText = messageText.replace(tag.textContent, ` `);
      }); 
      if (messageText.trim()  == '') {
        // 发送消息的逻辑
        return;
       
      }
      const msg = {
        chat_message:{
          id: uuidv4(), 
          type: 2,
          role:'user',
          create_user:this.userInfo.user_id,
          name: this.userInfo.user_name, 
          content: editableDiv.innerHTML,
          avatar: this.userInfo.avatar || this.testAvatar,
          session_id:this.session_id,
          tenant_id:this.userInfo.tenant_id,
          user_id:this.userInfo.user_id,
          message:this.sendMSG,
          project_id:this.queryParam.projectId
 
        }, 
        mentionItems:this.mentionedItems,
        openFiles:this.openFiles,
        generate_type: typeof generate_type === 'string' ? generate_type : '',
        // additional_params:{  }
      }
      this.chatData.push(msg.chat_message)

      console.log('Sending message:', messageText);
      this.$refs.editableDiv.innerHTML = '';
      this.mentionedItems = [];
      this.sendMSG = '';
      this.formattedText = '';
      // 此处发送接口请求
      this.sengLoading = true
      //保存数据到数据库
      //const params = new URLSearchParams(msg);
      let token = getToken()
      
      setTimeout(() => {
        this.sengLoading = false
        this.sendMSG = ''
      }, 1000)
      let response = null
      try {
        response = await fetchWithTimeout(
          runFlowApi,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'text/event-stream',
              [website.tokenHeader]: 'bearer ' + token
            },
            body: JSON.stringify(msg)
          },
          120000 // 30秒超时
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        // 处理响应...
      } catch (error) {
        console.error('请求失败:', error);
        ElMessage.error(error.message || '请求失败，请稍后重试');
      }
      
      // Add a new AI response message to chatData
     
      const aiResponse = {
        id: uuidv4(), 
        type: 1,
        role: 'bot',
        name: 'AI Assistant',
        content: '',
        avatar: this.testAvatar,
        session_id: this.session_id,
      };
      this.chatData.push(aiResponse);

      // Get the index of the newly added AI response
      const aiResponseIndex = this.chatData.length - 1;
      //const tags = ['<<clear>>', '<<chart>>', '<<answer>>', '<<relatefile>>', '<<error>>'];
      const tags = ['<<nextbox>>','<<gendata>>','<<done>>','<<message>>']//,"<<json>>"
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let buffer = '';
      let currentTag = null;
      let currentContent = '';

      const processBuffer = () => {
        const regex = new RegExp(`(${tags.join('|')})`, 'g');
        const segments = buffer.split(regex).filter(segment => segment !== '');
        const tagMatches = [...buffer.matchAll(regex)].map(match => match[0]);

        buffer = '';
        
        return { segments, tagMatches };
      }; 
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        
        buffer += decoder.decode(value, { stream: true });
        console.log(buffer) 

        const { segments, tagMatches } = processBuffer();
        
        currentContent = ""
        currentTag = null
        segments.forEach((segment, index) => {
            if (tagMatches.includes(segment)) {
              //if (currentTag) {  
                this.processTaggedContent(currentTag, currentContent);
                currentContent = '';
              //} 
              currentTag = segment;
            } else {
              currentContent +=  segment;
              if (index === segments.length - 1) {
                // 更新最后一条消息的内容，而不是创建新消息
                this.updateLastMessage(currentTag, currentContent);
              }
            }
          });
      }

      // 处理最后一个标签的内容
      /*
      if (currentContent.trim()) {
          this.updateLastMessage(currentTag, currentContent);
      }
      */ 
      //createChatMessage(this.queryParam.projectId, msg).then(response => {})
      //this.chatData.push(msg)
      document.getElementById('chat').scrollTo({
        top: document.getElementById('chat').scrollHeight,
        behavior: 'smooth'
      })
      // 请求完成后清理输入框内容,测试用setTimeout
      setTimeout(() => {
        this.sengLoading = false
        this.sendMSG = ''
      }, 100)
    },
    updateLastMessage(tag, content) {
      if (this.chatData.length > 0) {
        const lastMessage = this.chatData[this.chatData.length - 1];
        if (tag === '<<nextbox>>') {
          // 如果是新的消息框，创建新的消息
          this.processTaggedContent(tag, content);
        } else if (tag === '<<gendata>>') {
          //if(generate_type=='gendata'){//触发excel回填 
          this.fillDataToLuckysheet(content); 
        }else if (tag === '<<json>>') {
          //if(generate_type=='gendata'){//触发excel回填 
          console.log('<<json>>',content)
        }else if (tag === '<<done>>') {
          //if(generate_type=='gendata'){//触发excel回填 
          console.log('<<done>>',content)
        } else if (tag === '<<message>>') {
          // Show message notification
          this.$message({
            message: content.trim(),
            type: 'success',
            duration: 3000
          });
        }  else  {
          // 否则，更新最后一条消息的内容
          console.log('===',content)
          // Skip if content starts with {
          if (content.trim().startsWith('{')) {
            // 如果没有消息，创建新的消息
          this.processTaggedContent(tag, content);
            return;
          }
          lastMessage.content += content;
        }
      } else {
        // 如果没有消息，创建新的消息
        this.processTaggedContent(tag, content);
      }
    },
    onMentionsChange(value) {
      this.sendMSG = value;
    },
    // 添加一个新的方法来处理滚动
    scrollToBottom() {
      const chatElement = this.$refs.chatContainer; // 假设你的聊天容器有一个 ref="chatContainer"
      if (chatElement) {
        chatElement.scrollTop = chatElement.scrollHeight;
      }
    },
    querySearch(queryString, cb) {
      const results = this.suggestions.filter(item => 
        item.value.toLowerCase().includes(queryString.toLowerCase())
      );
      cb(results);
    },
    handleChange (value)  {
   
    },
    handleSelect(option) {
      console.log('Selected:', option);
    },
    closeFile(index) {
      // Remove the file at the specified index
      this.openFiles.splice(index, 1);
      
      // If there are no more open files, you might want to reset some state or show a placeholder
      if (this.openFiles.length === 0) {
        this.placeholderText = '你可以输入想要问的问题，比如最新的是什么...';
        // You might also want to clear any file-related content or reset other states
      }
      
      // Optionally, you could focus on the previous file if it exists
      if (index > 0 && this.openFiles.length > 0) {
        // Logic to focus on the previous file
        // This might involve setting some active file state or updating the UI
      }
    },
    toggleFileList() {
      this.showFileList = !this.showFileList;
      if (this.showFileList) {
        this.$nextTick(() => {
          this.updatePopupPosition();
        });
      }
    },
    async loadChatHistory(item, session) {
      // 根据 item 和 session 加载聊天记录
      try {
        const response = await getChatMessages(session.id);
        if (response.data.code === 200) {
          this.chatData = response.data.data.map(item => this.convertItem(item));
          this.$nextTick(() => {
            const chatElement = this.$refs.chat;
            if (chatElement) {
              chatElement.scrollTop = chatElement.scrollHeight;
            }
          });
        } else {
          this.$message.error('加载历史对话记录失败');
        }
      } catch (error) {
        console.error('Error loading chat history:', error);
        this.$message.error('加载历史对话记录时发生错误');
      }
    },
    selectFile(file) {
      if (!this.openFiles.some(f => f.fileName === file.fileName)) {
        let decodedFileLink = null;
        if(file.fileLink){
          decodedFileLink  =  decodeURIComponent(file.fileLink);
        }
        file.fileLink = decodedFileLink;
        file.sheetName  = file.sheetName || '';

        file.name = file.name || file.originalName || file.fileName;
        this.openFiles.push(file);
      }
      this.showFileList = false;
    },
    handleCloseFile() {
      console.log('Close file clicked');
      // 在这里添加关闭文的逻辑
    },
   
    updatePopupPosition() {
      const iconElement = this.$refs.addIcon;
      if (iconElement) {
        const rect = iconElement.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        // 获取浮动框的高度
        const popupElement = this.$el.querySelector('.file-list-popup');
        const popupHeight = popupElement ? popupElement.offsetHeight : 300; // 默认高度为300px

        this.popupStyle = {
          top: `${rect.top + scrollTop - popupHeight - 10}px`, // 将浮动框放置在图标上方
          left: `${rect.left + scrollLeft}px`
        };
      }
    },
    toggleMentionPopup(event) {
      this.showMentionPopup = !this.showMentionPopup;
      if (this.showMentionPopup) {
        this.$nextTick(() => { 
          const iconElement = this.$refs.addIcon;
        
          if (iconElement) {
            const rect = iconElement.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

            // 获取浮动框的高度
            const popupElement = this.$el.querySelector('.mention-popup');
            const popupHeight = popupElement ? popupElement.offsetHeight : 300; // 默认高���为300px

            this.mentionPopupStyle = {
              top: `${rect.top + scrollTop - popupHeight - 10}px`, // 将浮动框放置在图标上方
              left: `${rect.left + scrollLeft}px`
            };
            this.currentItems = this.mentionItems;
            this.currentPath = ['Root'];
          }
        });
      }
    },
    selectItem(item) {
      if (item.children) {
        this.currentItems = item.children;
        this.currentPath.push(item.name);
      } else {
        const mentionText = `@${item.name} `;
        this.insertMention(mentionText,item);
        this.showMentionPopup = false;
      }
    },
    saveCursorPosition() {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        this.lastCursorPosition = selection.getRangeAt(0).cloneRange();
      }
    },
    insertMention(mentionText,item) {
      const editableDiv = this.$refs.editableDiv;
      const selection = window.getSelection();
      let range = this.lastCursorPosition;

      if (!range) {
        // 如果没有保存的光标位置，则在末尾插入
        range = document.createRange();
        range.selectNodeContents(editableDiv);
        range.collapse(false);
      }

      // 检查是否在已有的 mention-tag 内
      let currentNode = range.startContainer;
      while (currentNode !== editableDiv) {
        if (currentNode.nodeType === Node.ELEMENT_NODE && currentNode.classList.contains('mention-tag')) {
          // 如果在 mention-tag 内，将范围移到 mention-tag 之后
          range.setStartAfter(currentNode);
          range.setEndAfter(currentNode);
          break;
        }
        currentNode = currentNode.parentNode;
      }

      // 检查并删除前面的 "@" 符号
      let beforeRange = range.cloneRange();
      beforeRange.setStart(beforeRange.startContainer, Math.max(0, beforeRange.startOffset - 1));
      let beforeText = beforeRange.toString();
      if (beforeText === '@') {
        beforeRange.deleteContents();
      }

      const mentionNode = document.createElement('span');
      mentionNode.className = 'mention-tag';
      mentionNode.textContent = mentionText;
      
      mentionNode.setAttribute('data-type', item.type);
      mentionNode.setAttribute('data-id', item.id);
      mentionNode.setAttribute('data-url', item.fileLink);
      
      range.insertNode(mentionNode);
      
      // 将光标移动到插入的 mention 之后
      range = document.createRange();
      range.setStartAfter(mentionNode);
      range.setEndAfter(mentionNode);
      
      selection.removeAllRanges();
      selection.addRange(range);

      // 在 mention 后插入一个空格
      const spaceNode = document.createTextNode(' ');
      range.insertNode(spaceNode);
      range.setStartAfter(spaceNode);
      range.setEndAfter(spaceNode);

      editableDiv.focus();
      this.lastCursorPosition = null; // 重置保存的光标位置
    },
    onInput(event) {
      this.saveCursorPosition();
      const text = this.$refs.editableDiv.innerText;
      const lastChar = text.slice(-1);
      if (lastChar === '@') { 
        this.$nextTick(() => {
          this.toggleMentionPopup();
        });
      }
    },
    onKeyDown(e) {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        this.sendMessage();
      } else if (e.key === 'Escape' && this.showMentionPopup) {
        this.showMentionPopup = false;
      } else if (this.showMentionPopup && (e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
        e.preventDefault();
        // 在这里处理 mention-popup 中的项目导航
      }
      this.saveCursorPosition();
    },
    /*updatePopupPosition() {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        this.mentionPopupStyle = {
          top: `${rect.bottom + window.scrollY}px`,
          left: `${rect.left + window.scrollX}px`
        };
      }
    },*/
    onPaste(e) {
      e.preventDefault();
      const text = e.clipboardData.getData('text/plain');
      document.execCommand('insertText', false, text);
      this.saveCursorPosition();
    },
    onBlur() {
      // 在失去焦点时保存光标位置
      this.saveCursorPosition();
    },
    navigateTo(index) {
      if (index === this.currentPath.length - 1) return;
      this.currentPath = this.currentPath.slice(0, index + 1);
      if (index === 0) {
        this.currentItems = this.mentionItems;
      } else {
        let items = this.mentionItems;
        for (let i = 1; i <= index; i++) {
          items = items.find(item => item.name === this.currentPath[i]).children;
        }
        this.currentItems = items;
      }
    },
    convertItem(item) {
      // 转换聊天记录项的逻辑
      let info = item.info;
      try {
        info = JSON.parse(info);
        info = parseNestedJson(info);
      } catch (e) {
        console.error('Error parsing info:', e);
      }
      console.log(item.id,info)
      return {
        id: item.id,
        type: item.role === 'user' ? 2 : (item.role === 'system' ? 3 : 1),
        name: item.name || item.role,
        content: item.content,
        avatar: item.avatar || '',
        isGenerate: item.isGenerate,
        operationType: this.getOperationType(item.biz_type),
        operationValue: info?.operationValue || [],
        operationNeed: info?.operationNeed || info?.operation_need || [],
        session_id: item.session_id,
        tenant_id: item.tenant_id,
        user_id: item.user_id,
        project_id: item.project_id
      };
    }
}
};
</script>

<style lang="scss" scoped>
.icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(135deg, #8e4ec6 0%, #3a1c71 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon i {
  color: white;
  font-size: 24px;
}
.ai-avatar {
  width: 20px;
  height: 20px;
  margin-left: 8px;
  img {
    aspect-ratio: 1 / 1;
    object-fit: cover;
    object-position: center center;
  }
}
.send-msg-other{
  border-radius: 0 0 10px 10px;
  background-color: #f9f9fb;
  position: relative; /* 添加这行 */
}

.send-msg-btn {
  position: absolute; /* 改为绝对定位 */
  right: 20px;
  top: 55%; /* 垂直居中 */
  transform: translateY(-50%); /* 确保完全居中 */
  cursor: pointer;
  font-size: 30px;
  color: #006cff; /* 使用主色调 */
  transition: color 0.3s ease; /* 添加过渡效果 */

  &:hover {
    color: #8A2BE2;
  }
}
a{
  color: #409EFF;
}
.send-msg {
  padding-right: 60px; /* 增加右侧padding，为按钮留出空间 */
  border: 0px solid rgba(0, 0, 0, 0.06);
  
}
.send-msg textarea{
  margin: 3px!important;
  padding-right: 40px; /* 确保文本不会被按钮遮挡 */
}
.regenerate {
  font-size: 14px;
  color: #666;
  width: fit-content;
  margin: 5px auto;
  padding: 10px 20px;
  border: 1px solid #eee;
  border-radius: 10px;
  transition: .3s;
  &:hover {
    cursor: pointer;
    background-color: #006cff;
    color: #fff;
  }
}
.message-box {
  border: 1px solid   rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 0px #e3e3e3;
  padding: 0px;
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
  min-height: 60px; // 设置最小高度
  max-height: 300px; 
  :deep(.el-textarea__inner) {
    box-shadow: none;
  } 
 
}
.message-box ::v-deep textarea{
  border-radius: 30px;
  padding-top: 15px;
  padding-left:  20px ;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.message-box ::v-deep textarea::placeholder {
  color: rgba(0, 0, 0, 0.3);/* 您想要的颜色 */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 300;
}
.message-box:hover,
.message-box:active {
    border: 1px solid rgba(76, 64, 229);
  }
  .mentions-wrapper {
  position: relative;
  width: 100%;
  display: flex!important;
  flex-direction: column;
  min-height: 40px; /* 设置最小高度 */
  max-height: 200px; /* 设置最大高度 */
  overflow-y: auto!important; /* 当内容超过最大高度时显示滚动条 */
}

.mentions-wrapper textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  background-color: transparent;
  z-index: 1;
}

.mentions-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
  pointer-events: none;
}

.mention-tag {
  background-color: #e6f7ff;
  border-radius: 3px;
  padding: 2px 4px;
  color: #1890ff;
}
.base-question {
  justify-content: space-between;
  display: grid;
  justify-items: stretch;
  align-items: stretch;
  grid-gap: 20px;
  grid-template-columns: repeat(2,1fr);
  li {
    padding: 10px;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
  }
}
.ai-guide {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
:deep(.el-footer) {
  height: auto;
}
:deep(.el-upload-dragger) {
  padding: 20px
}
.upload-file-box {
  border: 1px solid;
}
.select-box {
  background-color: #eee;
  margin-right: 20px;
  :deep(.el-checkbox__inner), :deep(.el-radio__inner) {
    display: none
  }
}


._mainPage_qbfqu_1 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 60px 24px;
    height: 100%;
    overflow: hidden;
    position: relative;
    min-width: 1280px;
    max-width: 1920px;
    margin: 0 auto;
}

._app_1k3bk_1 {
    height: 100%;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    overflow: hidden;
}
.custom-radio {
  transition: all 0.3s ease;
  background-color: #fff;
  border-radius: 20px;
  color: #333;
  font-weight: 300;
  height: 30px;
  margin-right: 10px;
  &:hover {
    background-color: #fff;
    border-color: #8e4ec6;
    color: #3a1c71;
  }

  &.is-checked {
    background-color: #409EFF;
    border-color: #409EFF;
    color: #ffffff;

    &:hover {
      background-color: #66b1ff;
      border-color: #66b1ff;
    }
  }
}
.chat-box {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .04), 0 4px 12px rgba(0, 0, 0, .06);
    transition: box-shadow .2s ease-in-out;
    padding: 10px 10px;
}
::v-deep .chat-box table {
    width: 100%;
    border-collapse: collapse;
}

::v-deep .chat-box td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

::v-deep .chat-box th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    background-color: #f2f2f2;
}

::v-deep .chat-box tr:nth-child(even) {
    background-color: #f9f9f9;
}

::v-deep .chat-box tr:hover {
    background-color: #ddd;
}
.editable-div {
  width: 100%;
  min-height: 40px; /* 设置默认高度 */
  max-height: 200px; /* 设置最大高度 */
  overflow-y: auto; /* 当内容超过最大高度时显示滚动条 */ 
  border-radius: 4px;
  padding: 5px 80px 5px 10px;
  font-size: 14px;
  border: 0px solid #ccc;
  border-radius: 4px;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #393939;
}

.editable-div:empty:before {
  content: attr(placeholder);
  color: #999;
}

.mention-tag {
  background-color: #e6f7ff;
  border-radius: 3px;
  padding: 2px 4px;
  color: #1890ff;
  white-space: nowrap;
}
.fc-light{
  color: #a9b6c0;
  font-size: 12px;
}

.send-msg {
  padding-right: 40px; /* 为发送按钮留出空间 */
}
 
.operation-need-container {
  display: flex;
  flex-wrap: wrap;
  margin: -10px; /* 抵消子元素的margin */
}

.operation-need-item {
  flex: 0 0 calc(50% - 20px); /* 每行两个元素，减去margin */
  margin: 10px;
  max-width: calc(50% - 20px); /* 确保不会超过50%宽度 */
}

/* 对于小屏幕设备，可以改为单列布局 */
@media (max-width: 768px) {
  .operation-need-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.mentions-wrapper {
  position: relative;
}

.custom-mentions {
  border-radius: 8px;
  overflow: hidden;
}
.mentions-wrapper {
  position: relative;
  border-radius: 8px;
  overflow: hidden; 
}
.mentions-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center; 
  padding: 5px 10px; 
  z-index: 1;
  overflow-x: auto; /* 允许横向滚动 */
  white-space: nowrap; /* 防止换行 */
}

.add-icon {
  color: #fff;
  margin-right: 3px;
  font-size: 12px;
  cursor: pointer;
  flex-shrink: 0; /* 防止图标缩小 */
  padding: 2px 5px ;
  border-radius: 3px;
  background-color: #3c3c3c ;
}

.file-tab-component {
  display: inline-flex;
  align-items: center;
  background-color: #3c3c3c ;
  padding: 0px 5px;
  border-radius: 3px;
  margin-right: 5px;
  margin-top: 3px;
}

.file-name {
  color: #fff;
  font-size: 11px;
  white-space: nowrap;
  margin-right: 0px;
  padding: 2px 3px;
}

.file-type {
  color: #888; /* 浅灰色 */
  font-size: 10px;
  white-space: nowrap;
  margin-right: 10px;
}

.close-icon {
  color: #ccc;
  font-size: 12px;
  cursor: pointer;
}

.file-list-popup {
  position: fixed; /* 改为 fixed 定位 */
  width: 300px;
  max-height: 300px;
  min-height: 300px;
  overflow-y: auto;
  background-color: #2d2d2d;
  border: 1px solid #333;
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
}

.search-bar {
  padding: 10px;
}

.search-bar input {
  width: 100%;
  padding: 5px;
  background-color: #3c3c3c;
  border: 1px solid #555;
  color: #ccc;
  font-size: 14px;
  flex-grow: 1;
  white-space: nowrap; /* 防止文本换行 */
  background-color: #3c3c3c ;
}

.file-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
}

.file-item:hover {
  background-color: #3c3c3c;
}

:deep(.ant-mentions) { 
  border: none;
  padding-top: 30px;
}

:deep(.ant-mentions-measure),
:deep(.ant-mentions textarea) {
  color: #333; 
  padding: 10px;
  height: 45px;
}

.el-container{    
  background: url(/img/background.png) #f7f8ff;
    background-size: cover;
}
.mention-popup {
  position: fixed; /* 改为 fixed 定位 */
  width: 300px;
  max-height: 300px;
  min-height: 300px;
  overflow-y: auto;
  background-color: #2d2d2d;
  border: 1px solid #333;
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
}
.el-button--small{
  font-size: 10px!important;
}
.mention-header {
  padding: 10px;
  background-color: #3c3c3c;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}

.mention-item {
  padding: 10px;
  color: #ccc;
  cursor: pointer;
  font-size: 12px;
}
.item-arrow{
  position: absolute;
  right: 10px;
}

.mention-item:hover {
  background-color: #3c3c3c;
}
.mentions-wrapper {
  position: relative;
  width: 100%;
  padding-top: 15px; 
}

.mentions-wrapper textarea {
  width: 100%;
  height: 80px;
  padding: 10px;
  font-size: 14px;
  border: 0px solid #ccc;
  border-radius: 4px;
  resize: none;
  background-color: transparent;
  z-index: 1;
}

.mentions-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
  pointer-events: none;
}

::v-deep .mention-tag {
  background-color: #e6f7ff;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 12px;
  color: #333333;
}
::v-deep .el-loading-mask{
  right: 40px;
}
::v-deep a{
  color: #409EFF;
  cursor: pointer;
}

/* 添加这个样式来覆盖 Element Plus 的默认过渡效果 */
.static-tag {
  transition: none !important;
  animation: none !important;
}

/* 如果上面的样式不够，可以尝试更具体的选择器 */
.static-tag.el-tag {
  transition: none !important;
  animation: none !important;
}

/* 确保标签始终可见 */
.static-tag.el-tag {
  opacity: 1 !important;
  transform: none !important;
}

::v-deep .markdown-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  background-color: #fff;
  margin: 10px 0;
}

::v-deep .markdown-table th,
::v-deep .markdown-table td {
  padding: 6px 8px;
  border: 1px solid #e8e8e8;
  text-align: left;
}

::v-deep .markdown-table th {
  background-color: #fafafa;
  font-weight: 500;
}

::v-deep .markdown-table tr:hover {
  background-color: #fafafa;
}

::v-deep .markdown-table .el-button--small {
  padding: 4px 8px;
  font-size: 11px;
  margin: 0 4px;
  height: 24px;
  line-height: 1;
}

::v-deep .markdown-table .el-button--small + .el-button--small {
  margin-left: 4px;
}

.operation-cell {
  display: flex;
  gap: 8px;
  align-items: center;
}

.operation-cell .el-button--link {
  padding: 4px;
  height: auto;
}

.operation-cell .el-icon {
  width: 16px;
  height: 16px;
  color: var(--el-color-primary);
}


::v-deep .el-button--primary { 
  --el-button-font-weight: var(--el-font-weight-primary);
  --el-button-border-color: var(--el-border-color);
  --el-button-bg-color: var(--el-fill-color-blank);
  --el-button-text-color: var(--el-text-color-regular);
  --el-button-disabled-text-color: var(--el-disabled-text-color);
  --el-button-disabled-bg-color: var(--el-fill-color-blank);
  --el-button-disabled-border-color: var(--el-border-color-light);
  --el-button-divide-border-color: rgba(255, 255, 255, 0.5);
  --el-button-hover-text-color: var(--el-color-primary);
  --el-button-hover-bg-color: var(--el-color-primary-light-9);
  --el-button-hover-border-color: var(--el-color-primary-light-7);
  --el-button-active-text-color: var(--el-button-hover-text-color);
  --el-button-active-border-color: var(--el-color-primary);
  --el-button-active-bg-color: var(--el-button-hover-bg-color);
  --el-button-outline-color: var(--el-color-primary-light-5);
  --el-button-hover-link-text-color: var(--el-color-info);
  --el-button-active-color: var(--el-text-color-primary);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  height: 32px;
  white-space: nowrap;
  cursor: pointer;
  color: var(--el-button-text-color);
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  transition: .1s;
  font-weight: var(--el-button-font-weight);
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  background-color: var(--el-button-bg-color);
  border: var(--el-border);
  border-color: var(--el-button-border-color);
  padding: 4px 2px 4px 8px;
  font-size: var(--el-font-size-base);
  border-radius: var(--el-border-radius-base);
} 

/* 可选: 添加悬停提示 */
.view-journal-entry {
  position: relative;
}

.view-journal-entry:hover::after {
  content: '查看凭证';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0,0,0,0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
}

.view-vat-file:hover::after {
  content: '查看报税信息';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0,0,0,0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
}

/* 为父容器添加相对定位 */
.border-radius8 {
  position: relative;
}

/* 将保存按钮定位到右上角 */
::v-deep .table-submit-btn {
  position: absolute;
  top: -10px;
  right: 10px;
  z-index: 1;
}

/* 移除原来的底部margin */
.table-submit-btn + div {
  margin-bottom: 0;
}

::v-deep .table-ai-btn {
  position: absolute;
  top: -10px;
  right: 100px;
  z-index: 1;
}

::v-deep .highlighted-cell {
  background-color: #e6f7ff;
  transition: background-color 0.2s;
}

.sortable-header {
  cursor: pointer;
  user-select: none;
}
.sortable-header:hover {
  background-color: #f5f7fa;
}
.sort-icon {
  margin-left: 5px;
  display: inline-block;
}
</style>
