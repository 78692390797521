class TableOperator {
  constructor(table, onHighlight) {
    this.table = table;
    this.highlightedCells = new Set();
    if(typeof onHighlight === 'function'){
      this.onHighlight = onHighlight; // 回调函数用于通知组件更新
    }else{
      this.onHighlight = this.defaultHighlight
    }
  }

  // 默认高亮函数
  defaultHighlight (cells){
    let table = this.table
    // 使用表格的id作为key来存储高亮状态
    if (table.id) {
        this.highlightedCells[table.id] = cells
        cells.forEach(cell => {
        const [rowIndex, columnIndex] = cell.split('-'); 
        // 移除高亮样式
        const celldom = table.rows[rowIndex].cells[columnIndex];
        if (celldom) {
            celldom.classList.remove('highlighted-cell');
        }
        celldom.classList.add('highlighted-cell');
        });
    } else {
        console.warn('Table has no id, using default highlightedCells');
        cells.forEach(cell => {
            cell.classList.add('highlighted-cell');
        });
    }
 }

  // 根据条件切换选择值并勾选复选框
  setSelectValuesByConditions(conditions,selectValue) {
    const tbody = this.table.querySelector('tbody');
    const thead = this.table.querySelector('thead');
    if (!tbody || !thead) return;

    // 获取表头
    const headers = Array.from(thead.querySelectorAll('th')).map(th => th.textContent.trim());
    const rows = tbody.querySelectorAll('tr');

    rows.forEach(row => {
      let matchesConditions = true;
      
      // 检查每个条件是否匹配
      for (const [columnName, targetValue] of Object.entries(conditions)) {
        const columnIndex = headers.indexOf(columnName);
        if (columnIndex === -1) continue;
        
        
        const cell = row.cells[columnIndex ];
        if (!cell) continue;

        const cellValue = cell.textContent.trim(); 
        if (cellValue !== targetValue) {
          matchesConditions = false;
          break;
        }
      }

      // 如果所有条件都匹配
      if (matchesConditions) {
        // 勾选第一列的复选框
        const checkbox = row.querySelector('input[type="checkbox"]');
        if (checkbox) {
          checkbox.checked = true;
        }

        // 设置select的值
        row.querySelectorAll('select').forEach(select => {
          const columnName = headers[Array.from(row.cells).indexOf(select.closest('td')) - 1];
          //if (selectValue[columnName]) {
            // Find option that contains the target value
            const options = Array.from(select.options);
            const matchingOption = options.find(opt => opt.value.includes(selectValue));
            if (matchingOption) {
              select.value = matchingOption.value;
            }
          //}
        });
      }
    });
  }
  
  // 根据列名和值查询匹配的行
  findRowsByColumnValue(columnName, value) {
    const rows = [];
    const columnIndex = this.table.header.findIndex(h => h === columnName);
    
    if (columnIndex === -1) return rows;

    this.table.data.forEach((row, rowIndex) => {
      if (row[columnIndex] === value) {
        rows.push({
          rowIndex,
          data: row
        });
      }
    });

    return rows;
  }

  // 根据多个条件查询匹配的行
  findRowsByConditions(conditions) {
    const rows = [];
    
    this.table.data.forEach((row, rowIndex) => {
      let match = true;
      console.log('conditions',row.innerText)
      for (const [columnName, value] of Object.entries(conditions)) {
        const columnIndex = this.table.header.findIndex(h => h === columnName);
        if (columnIndex === -1 || row[columnIndex] !== value) {
          match = false;
          break;
        }
      }

      if (match) {
        rows.push({
          rowIndex,
          data: row
        });
      }
    });

    return rows;
  }

  // 高亮指定单元格
  highlightCell(rowIndex, columnIndex) {
    const cellKey = `${rowIndex}-${columnIndex}`;
    this.highlightedCells.add(cellKey);
     // 获取并添加高亮样式
    // 通知组件更新
    if (this.onHighlight) {
        this.onHighlight(this.highlightedCells);
    }
  }

  // 取消高亮指定单元格
  unhighlightCell(rowIndex, columnIndex) {
    const cellKey = `${rowIndex}-${columnIndex}`;
    this.highlightedCells.delete(cellKey);
    // 移除高亮样式
    const cell = this.table.rows[rowIndex].cells[columnIndex];
    if (cell) {
        cell.classList.remove('highlighted-cell');
    }
  }

  // 清除所有高亮
  clearHighlights() {
    this.highlightedCells.forEach(cellKey => {
      const [rowIndex, columnIndex] = cellKey.split('-');
      this.unhighlightCell(parseInt(rowIndex), parseInt(columnIndex));
    });
    this.highlightedCells.clear();
  }

  // 检查单元格是否被高亮
  isCellHighlighted(rowIndex, columnIndex) {
    const cellKey = `${rowIndex}-${columnIndex}`;
    return this.highlightedCells.has(cellKey);
  }

  // 高亮整行
  highlightRow(rowIndex) {
    console.log('Table in highlightRow:', this.table); // 调试用
    console.log('Rows:', this.table.rows); // 调试用
    
    // 防御性编程
    if (!this.table || !this.table.rows) {
      console.warn('No table or rows found');
      return;
    }
    
    // 获取表格的第一行
    const firstRow = this.table.rows[0];
    if (!firstRow) {
      console.warn('No rows in table');
      return;
    }
    
    // 获取列数
    const columnCount = firstRow.cells ? firstRow.cells.length : firstRow.length;
    
    // 高亮该行的所有列
    for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
      this.highlightCell(rowIndex, columnIndex);
    }
  }
  

  // 高亮整列
  highlightColumn(columnIndex) {
    this.table.data.forEach((_, rowIndex) => {
      this.highlightCell(rowIndex, columnIndex);
    });
  }

  // 获取列的所有唯一值
  getUniqueValuesInColumn(columnName) {
    const columnIndex = this.table.header.findIndex(h => h === columnName);
    if (columnIndex === -1) return new Set();

    return new Set(this.table.data.map(row => row[columnIndex]));
  }

  // 获取表格的基本统计信息
  getTableStats() {
    return {
      rowCount: this.table.data.length,
      columnCount: this.table.header.length,
      headers: [...this.table.header]
    };
  }
}

// 如果使用 setup 语法
const useTableOperator = (table,onHighlight) => {
  const tableOperator = new TableOperator(table,onHighlight)
  
  // 示例：查找特定列的值
  const findRows = (columnName, value) => {
    return tableOperator.findRowsByColumnValue(columnName, value)
  }
  
  // 示例：高亮某一行
  const highlightRow = (rowIndex) => {
    tableOperator.highlightRow(rowIndex)
  }
  
  return {
    tableOperator,
    findRows,
    highlightRow
  }
}

export default {
  TableOperator,
  useTableOperator
}