<template>
  <div v-if="show" class="mention-popup" :style="popupStyle">
    <div class="mention-header">
      <span v-for="(breadcrumb, index) in currentPath" :key="index" @click="navigateTo(index)">
        {{ breadcrumb }}
        <span v-if="index < currentPath.length - 1"> > </span>
      </span>
    </div>
    <div class="mention-item" v-for="item in currentItems" :key="item.name" @click="selectItem(item)">
      <span v-if="item.type !== 'Folder'" :class="['item-icon file-icon', item.icon]"></span>
      <span v-else :class="['item-icon file-icon', item.icon]">
        <el-icon class="margin-r10"><Folder /></el-icon>
      </span>
      {{ item.name }}
      <span v-if="item.children" class="item-arrow">
        <el-icon class="margin-r10"><Right /></el-icon>
      </span>
    </div>
  </div>
</template>

<script>
import { Folder, Right } from '@element-plus/icons-vue';
import { getProjectFiles } from '@/api/firmProject/firmProject'; // 确保路径正确
import { getKnowledgeList } from '@/api/firmProject/firmProject'; // 确保导入正确的 API 函数

export default {
  name: 'MentionPopup',
  components: {
    Folder,
    Right
  },
  props: {
    show: Boolean,
    popupStyle: Object,
    params: {
      type: Object,
      default: () => ({})
    },
    items: {
      type: Array,
      default: null
    }
  },
  watch: {
    params: {
      handler(newVal) {
        if (newVal && newVal.projectId) {
          this.fetchMentionProjectFiles(newVal.projectId); 


          
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      currentPath: ['Root'],
      currentItems: [],
      projectFiles: [],
      defaultItems: [
        { name: '项目资料', icon: 'folder-icon', children: [] },
        { name: '税法', icon: 'folder-icon' },
        { name: '企业资料', icon: 'code-icon', children: [ 
        ]},
        { name: '历年项目', icon: 'web-icon' },
        { name: '行业对比', icon: 'docs-icon' }, 
        { name: '填写指导', icon: 'codebase-icon', children: [ 
          { name: '表单填写', icon: 'js-icon' ,type:'billFilling'},
          { name: '税收政策', icon: 'python-icon' ,type:'taxPolicy'} 
        ]},
        { 
          name: '系统知识库', 
          icon: 'git-icon', 
          children: [], 
          type: 'Folder',
          fetchChildren: this.fetchKnowledgeList 
        }
      ]
    };
  },
  computed: {
    effectiveItems() {
      return this.items || this.defaultItems;
    }
  },
  methods: {
    updateCurrentItems(items) {
      this.currentItems = items;
    },
    navigateTo(index) {
      if (index < this.currentPath.length - 1) {
        this.currentPath = this.currentPath.slice(0, index + 1);
        let items = this.effectiveItems;
        for (let i = 1; i <= index; i++) {
          const item = items.find(item => item.name === this.currentPath[i]);
          if (item && item.children) {
            items = item.children;
          } else {
            break;
          }
        }
        this.updateCurrentItems(items);
      }
    },
    async fetchKnowledgeList() {
      try {
        const response = await getKnowledgeList(1); //获得系统知识库
        if (response.data.code === 200) {
          return response.data.data.records.map(item => ({
            name: item.title,
            id: item.id,
            type: 'knowledge'
          }));
        } else {
          this.$message.error('Failed to load knowledge list');
          return [];
        }
      } catch (error) {
        console.error('Error fetching knowledge list:', error);
        this.$message.error('Error fetching knowledge list');
        return [];
      }
    },
    async selectItem(item) {
      if (item.children) { 
        this.currentPath.push(item.name);
        this.updateCurrentItems(item.children);
      } else if (item.type === 'knowledge') {
        this.$emit('select', { id:item.id,type: 'knowledge', name: item.name });
      } else {
        this.$emit('select', item);
      }
    },
    async fetchMentionProjectFiles() {
      if (typeof this.params=='undefined') {
        return
      }
      try {
        // 循环 effectiveItems，如果是 fun 类型则加载
        for (let item of this.effectiveItems) {
          if ( typeof item.fetchChildren === 'function') {
            try {
              const children = await item.fetchChildren();
              item.children = children;
            } catch (error) {
              console.error(`Error fetching children for ${item.name}:`, error);
              this.$message.error(`Failed to load data for ${item.name}`);
            }
          }
        }


        const response = await getProjectFiles(this.params.projectId);
        if (response.data.code === 200) {
          this.projectFiles = response.data.data;
          // 更新 mentionItems 中的 Files 子项
          const filesItem = this.effectiveItems.find(item => item.name === '项目资料');
          if (filesItem) {
            filesItem.children = this.projectFiles.map(file => ({
              type: 'File',
              id: file.id,
              name: file.originalName,
              originalName: file.originalName,
              fileName: file.fileName,
              fileLink: file.fileLink,
              icon: this.getFileIconClass(file.fileName)
            }));
          }
          this.updateCurrentItems(this.effectiveItems);
        } else {
          this.$message.error('Failed to load project files');
        }
      } catch (error) {
        console.error('Error fetching project files:', error);
        this.$message.error('Error fetching project files');
      }
    },
    getFileIconClass(fileName) {
      // 实现获取文件图标的逻辑
      // 这里只是一个示例，你需要根据实际情况来实现
      const extension = fileName.split('.').pop().toLowerCase();
      switch (extension) {
        case 'pdf': return 'pdf-icon';
        case 'doc':
        case 'docx': return 'word-icon';
        case 'xls':
        case 'xlsx': return 'excel-icon';
        case 'ppt':
        case 'pptx': return 'powerpoint-icon';
        default: return 'file-icon';
      }
    }
  },
  mounted() {
    this.updateCurrentItems(this.effectiveItems); 
  },
  
};
</script>
 

  
<style scoped>
 
.mention-popup {
  position: fixed; /* 改为 fixed 定位 */
  width: 300px;
  max-height: 300px;
  min-height: 300px;
  overflow-y: auto;
  background-color: #2d2d2d;
  border: 1px solid #333;
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
}
.mention-header {
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
  font-size: 12px;
  color: #999;
}

.mention-item {
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #fcfcfc;
  font-size: 13px;
}

.mention-item:hover {
  background-color: #ccc;
  color: #000;
}

.item-icon {
  margin-right: 5px;
}

.item-arrow {
  margin-left: auto;
}
</style>