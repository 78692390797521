<template>
    <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        :label-width="labelWidth"
        class="sc-form"
    >
        <el-row :gutter="20">
            <el-col 
                v-for="(item, index) in visibleColumns" 
                :key="index"
                :span="colSpan"
            >
                <el-form-item 
                    :label="item.label"
                    :prop="item.prop"
                >
                    <!-- 输入框 -->
                    <el-input 
                        v-if="item.type === 'input'"
                        v-model="formData[item.prop]"
                        :placeholder="'请输入' + item.label"
                    />
                    
                    <!-- 数字输入框 -->
                    <el-input-number
                        v-if="item.type === 'number'"
                        v-model="formData[item.prop]"
                        :min="item.min ?? 0"
                        :max="item.max"
                        :placeholder="'请输入' + item.label"
                        style="width: 100%"
                        :controls-position="item.controlsPosition || 'right'"
                    />
                    
                    <!-- 选择器 -->
                    <el-select
                        v-if="item.type === 'select' || item.dicUrl"
                        v-model="formData[item.prop]"
                        :placeholder="'请选择' + item.label"
                        :multiple="item.multiple"
                        :clearable="true"
                    >
                        <el-option
                            v-if="item.dicUrl"
                            v-for="dict in dictCache[item.dicUrl]"
                            :key="dict.dictKey"
                            :label="dict.dictValue"
                            :value="dict.dictKey"
                        />
                        <el-option
                            v-else
                            v-for="dict in item.dicData"
                            :key="dict.value"
                            :label="dict.label"
                            :value="dict.value"
                        />
                    </el-select>

                    <!-- 日期选择器 -->
                    <el-date-picker
                        v-if="item.type === 'date'"
                        v-model="formData[item.prop]"
                        type="date"
                        :placeholder="'请选择' + item.label"
                    />
                </el-form-item>
            </el-col>
        </el-row>

        <el-form-item class="form-footer">
            <slot name="footer">
                <div class="footer-buttons">
                    <el-button 
                        type="primary" 
                        :loading="loading" 
                        @click="debouncedSubmit"
                    >保存</el-button>
                    <el-button 
                        @click="$emit('cancel')"
                    >取消</el-button>
                </div>
            </slot>
        </el-form-item>
    </el-form>
</template>

<script>
import axios from '@/axios'

export default {
    name: 'ScForm',
    props: {
        option: {
            type: Object,
            required: true
        },
        modelValue: {
            type: Object,
            default: () => ({})
        },
        labelWidth: {
            type: String,
            default: '120px'
        },
        colSpan: {
            type: Number,
            default: 24
        }
    },
    emits: ['update:modelValue', 'submit', 'cancel'],
    data() {
        return {
            formData: {},
            rules: {},
            loading: false,
            submitTimer: null,
            dictCache: {}
        }
    },
    watch: {
        modelValue: {
            handler(val) {
                const processedData = { ...val }
                this.visibleColumns.forEach(item => {
                    if (item.type === 'number' && (processedData[item.prop] === undefined || processedData[item.prop] === null)) {
                        processedData[item.prop] = 0
                    }
                })
                this.formData = processedData
            },
            immediate: true,
            deep: true
        },
        formData: {
            handler(val) {
                this.$emit('update:modelValue', val)
            },
            deep: true
        }
    },
    created() {
        this.initRules()
        this.loadDictionaryData()
    },
    computed: {
        visibleColumns() {
            return this.option.column.filter(item => item.addDisplay !== false);
        }
    },
    methods: {
        initRules() {
            this.rules = this.visibleColumns.reduce((acc, item) => {
                if (item.required) {
                    acc[item.prop] = [{
                        required: true,
                        message: `请${item.type === 'select' ? '选择' : '输入'}${item.label}`,
                        trigger: item.type === 'select' ? 'change' : 'blur'
                    }]
                }
                return acc
            }, {})
        },
        debouncedSubmit() {
            if (this.submitTimer) {
                clearTimeout(this.submitTimer)
            }
            
            this.submitTimer = setTimeout(() => {
                this.submitFormWithLoading()
            }, 300) // 300ms 的防抖延迟
        },

        async submitFormWithLoading() {
            if (this.loading) return
            
            this.loading = true
            try {
                await this.$refs.form.validate()
                await this.$emit('submit', this.formData)
            } catch (error) {
                // 验证失败时不做处理
            } finally {
                this.loading = false
            }
        },

        resetForm() {
            this.$refs.form.resetFields()
        },

        // 组件销毁时清除定时器
        beforeDestroy() {
            if (this.submitTimer) {
                clearTimeout(this.submitTimer)
            }
        },

        async loadDictionaryData() {
            for (const column of this.visibleColumns) {
                if (column.dicUrl && !this.dictCache[column.dicUrl]) {
                    try {
                        const response = await axios.get(column.dicUrl)
                        if (response.data.success) {
                            this.dictCache[column.dicUrl] = response.data.data
                        }
                    } catch (error) {
                        console.error('Failed to load dictionary data:', error)
                    }
                }
            }
        }
    }
}
</script>
<style scoped>
.sc-form {
    padding: 20px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    position: relative;
}

.form-footer {
    text-align: center;
    margin-top: 20px;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    padding: 10px 0;
    margin-bottom: 0;
}

.footer-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.sc-form::-webkit-scrollbar {
    width: 6px;
}

.sc-form::-webkit-scrollbar-thumb {
    background-color: #dcdfe6;
    border-radius: 3px;
}

.sc-form::-webkit-scrollbar-track {
    background-color: #f5f7fa;
}

:deep(.el-form-item) {
    margin-bottom: 18px;
}

:deep(.el-select),
:deep(.el-date-picker),
:deep(.el-input) {
    width: 100%;
}

:deep(.el-form-item.form-footer) {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

:deep(.el-form-item.form-footer .el-form-item__content) {
  width: 100%;
  margin-left: 0 !important;  /* 覆盖默认的 margin-left */
}

.footer-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
}
</style>
