import { setToken, setRefreshToken, removeToken, removeRefreshToken } from '@/utils/auth';
import { setStore, getStore } from '@/utils/store';
import { validatenull } from '@/utils/validate';
import { deepClone } from '@/utils/util';
import {
  loginByUsername,
  loginBySocial,
  loginBySso,
  getUserInfo,
  logout,
  refreshToken,
  getButtons,
} from '@/api/user';
import { getFirmDetailByTenantId } from '@/api/firm/firm'
import { getRoutes, getTopMenu, getRoutesByVisibilityScope } from '@/api/system/menu';
import { formatPath } from '@/router/avue-router';
import { ElMessage } from 'element-plus';
import md5 from 'js-md5';
import firmStaticRoutes from '@/router/firmStaticRoutes';
import staticPermissions from '@/config/staticPermission.json'
import eduStaticRoutes from '@/router/eduStaticRoutes';

const user = {
  state: {
    tenantId: getStore({ name: 'tenantId' }) || '',
    userInfo: getStore({ name: 'userInfo' }) || [],
    permission: getStore({ name: 'permission' }) || {},
    roles: [],
    menuId: {},
    menu: getStore({ name: 'menu' }) || [],
    menuAll: getStore({ name: 'menuAll' }) || [],
    token: getStore({ name: 'token' }) || '',
    refreshToken: getStore({ name: 'refreshToken' }) || '',
    firmInfo: getStore({ name: 'firmInfo' }) || null,
    tenantType: getStore({ name: 'tenantType' }) || null,
    portalUrl: getStore({ name: 'portalUrl' }) || null,
  },
  actions: {
    //根据用户名登录
    LoginByUsername({ commit }, userInfo = {}) {
      return new Promise((resolve, reject) => {
        loginByUsername(
          userInfo.tenantId,
          userInfo.deptId,
          userInfo.roleId,
          userInfo.username,
          md5(userInfo.password),
          userInfo.type,
          userInfo.key,
          userInfo.code,
          userInfo.grant_type
        )
          .then(res => {
            const data = res.data;
            if (data.error_description) {
              ElMessage({
                message: data.error_description,
                type: 'error',
              });
            } else {
              commit('SET_TOKEN', data.access_token);
              commit('SET_REFRESH_TOKEN', data.refresh_token);
              commit('SET_TENANT_ID', data.tenant_id);
              commit('SET_USER_INFO', data);
              commit('DEL_ALL_TAG');
              commit('CLEAR_LOCK');
            }
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    //根据第三方信息登录
    LoginBySocial({ commit }, userInfo) {
      return new Promise(resolve => {
        loginBySocial(userInfo.tenantId, userInfo.source, userInfo.code, userInfo.state).then(
          res => {
            const data = res.data;
            if (data.error_description) {
              ElMessage({
                message: data.error_description,
                type: 'error',
              });
            } else {
              commit('SET_TOKEN', data.access_token);
              commit('SET_REFRESH_TOKEN', data.refresh_token);
              commit('SET_USER_INFO', data);
              commit('SET_TENANT_ID', data.tenant_id);
              commit('DEL_ALL_TAG');
              commit('CLEAR_LOCK');
            }
            resolve();
          }
        );
      });
    },
    //根据单点信息登录
    LoginBySso({ commit }, userInfo) {
      return new Promise(resolve => {
        loginBySso(userInfo.state, userInfo.code).then(res => {
          const data = res.data;
          if (data.error_description) {
            ElMessage({
              message: data.error_description,
              type: 'error',
            });
          } else {
            commit('SET_TOKEN', data.access_token);
            commit('SET_REFRESH_TOKEN', data.refresh_token);
            commit('SET_USER_INFO', data);
            commit('SET_TENANT_ID', data.tenant_id);
            commit('DEL_ALL_TAG');
            commit('CLEAR_LOCK');
          }
          resolve();
        });
      });
    },
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then(res => {
            const data = res.data.data;
            commit('SET_ROLES', data.roles);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    //刷新token
    RefreshToken({ state, commit }, userInfo) {
      return new Promise((resolve, reject) => {
        refreshToken(
          state.refreshToken,
          state.tenantId,
          !validatenull(userInfo) ? userInfo.deptId : state.userInfo.dept_id,
          !validatenull(userInfo) ? userInfo.roleId : state.userInfo.role_id
        )
          .then(res => {
            const data = res.data;
            commit('SET_TOKEN', data.access_token);
            commit('SET_REFRESH_TOKEN', data.refresh_token);
            commit('SET_USER_INFO', data);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            commit('SET_TOKEN', '');
            commit('SET_MENU_ALL_NULL', []);
            commit('SET_MENU', []);
            commit('SET_ROLES', []);
            commit('DEL_ALL_TAG', []);
            commit('CLEAR_LOCK');
            commit('CLEAR_FIRM_INFO');
            removeToken();
            removeRefreshToken();
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    //注销session
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '');
        commit('SET_MENU_ALL_NULL', []);
        commit('SET_MENU', []);
        commit('SET_ROLES', []);
        commit('DEL_ALL_TAG', []);
        commit('CLEAR_LOCK');
        commit('CLEAR_FIRM_INFO');
        removeToken();
        removeRefreshToken();
        resolve();
      });
    },
    GetTopMenu() {
      return new Promise(resolve => {
        getTopMenu().then(res => {
          const data = res.data.data || [];
          resolve(data);
        });
      });
    },
    GetMenu({ commit, dispatch }, {enterpriseId, visibilityScope}) {
      return new Promise(resolve => {
        getRoutes(null, enterpriseId, visibilityScope).then(res => {
          const data = res.data.data;
          let menu = deepClone(data);
          menu.forEach(ele => formatPath(ele, true));
          console.log(menu);
          commit('SET_MENU', menu);
          commit('SET_MENU_ALL', menu);
          dispatch('GetButtons');
          resolve(menu);
        });
      });
    },
    GetMenuByVisibilityScope({ commit, dispatch, state }, visibilityScope) {
      return new Promise(resolve => {
        getRoutesByVisibilityScope(visibilityScope).then(res => {
          const data = res.data.data;
          let menu = deepClone(data);
          menu.forEach(ele => formatPath(ele, true));
          
          const staticRoutesToMerge = [];
          
          // Helper function to extract static routes
          const extractStaticRoutes = (routes, visibilityScope) => {
            const userRole = state.userInfo.role_name; // Get current user's role
            routes.forEach(route => {
              if (route.children) {
                route.children.forEach(child => {
                  // Check both visibility scope and user role
                  const hasValidScope = child.meta?.visibilityScope?.includes(visibilityScope) || 
                                      visibilityScope?.includes(child.meta?.visibilityScope);
                  const hasValidRole = !child.meta?.role || 
                                     child.meta.role.includes(userRole);
                  
                  if (child.meta?.isMenu === 'true' && hasValidScope && hasValidRole) {
                    staticRoutesToMerge.push({
                      path: `${route.path}/${child.path}`,
                      name: child.name,
                      meta: child.meta,
                      component: child.component,
                      id: `static_${child.path}`,
                      parentId: "0",
                      code: child.path,
                      alias: child.path,
                      source: "<el-icon><Delete /></el-icon>",
                      sort: 1,
                      category: 1,
                      action: 0,
                      isOpen: 1,
                      visibilityScope: visibilityScope
                    });
                  }
                });
              }
            });
          };

          // Choose static routes based on tenant type
          const staticRoutes = state.tenantType === 'firm' ? firmStaticRoutes : eduStaticRoutes;
          extractStaticRoutes(staticRoutes, visibilityScope);
          
          // Combine dynamic and static menus
          const combinedMenu = [...menu, ...staticRoutesToMerge];
          
          commit('SET_MENU', combinedMenu);
          commit('SET_MENU_ALL', combinedMenu);
          dispatch('GetButtons');
          resolve(combinedMenu);
        });
      });
    },
    // 重新设置应用的专属菜单，别名 alias 为aiMenu的都是，后期可以根据实际需求修改判断条件
    setAIMenu({ commit }, menu) {
      return new Promise(resolve => {
        commit('SET_MENU', menu);
        commit('SET_MENU_ALL', menu);
        resolve(menu);
      })
    },
    GetButtons({ commit }) {
      return new Promise(resolve => {
        getButtons().then(res => {
          const data = res.data.data;
          commit('SET_PERMISSION', data);
          resolve();
        });
      });
    },
    GetFirmInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getFirmDetailByTenantId(state.tenantId)
          .then(res => {
            const firmInfo = res.data.data;
            commit('SET_FIRM_INFO', firmInfo);
            resolve(firmInfo);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      setToken(token);
      state.token = token;
      setStore({ name: 'token', content: state.token });
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      setRefreshToken(refreshToken);
      state.refreshToken = refreshToken;
      setStore({ name: 'refreshToken', content: state.refreshToken });
    },
    SET_MENU_ID(state, menuId) {
      state.menuId = menuId;
    },
    SET_TENANT_ID: (state, tenantId) => {
      state.tenantId = tenantId;
      setStore({ name: 'tenantId', content: state.tenantId });
    },
    SET_USER_INFO: (state, userInfo) => {
      if (validatenull(userInfo.avatar)) {
        userInfo.avatar = '/img/bg/img-logo.jpg';
      }
      state.userInfo = userInfo;
      setStore({ name: 'userInfo', content: state.userInfo });
    },
    SET_MENU_ALL: (state, menuAll) => {
      let menu = state.menuAll;
      menuAll.forEach(ele => {
        let index = menu.findIndex(item => item.path === ele.path);
        if (index === -1) {
          menu.push(ele);
        } else {
          menu[index] = ele;
        }
      });
      state.menuAll = menu;
      setStore({ name: 'menuAll', content: state.menuAll });
    },
    SET_MENU_ALL_NULL: state => {
      state.menuAll = [];
      setStore({ name: 'menuAll', content: state.menuAll });
    },
    SET_MENU: (state, menu) => {
      console.log('change menu', menu)
      state.menu = menu;
      setStore({ name: 'menu', content: state.menu });
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSION: (state, permission) => {
      let result = [];

      function getCode(list) {
        list.forEach(ele => {
          if (typeof ele === 'object') {
            const children = ele.children;
            const code = ele.code;
            if (children) {
              getCode(children);
            } else {
              result.push(code);
            }
          }
        });
      }

      getCode(permission);
      
      // 初始化动态权限
      state.permission = {};
      result.forEach(ele => {
        state.permission[ele] = true;
      });

      // 获取用户角色
      const userRole = state.userInfo.role_name?.toLowerCase();
      
      // 如果有角色，则遍历所有模块的权限配置
      if (userRole) {
        Object.values(staticPermissions).forEach(modulePermissions => {
          // 获取该角色的静态权限配置
          const rolePermissions = modulePermissions[userRole];
          
          if (rolePermissions) {
            // 合并该模块的权限配置
            state.permission = {
              ...state.permission,
              ...rolePermissions
            };
          }
        });
      }
      
      setStore({ name: 'permission', content: state.permission, type: 'session' });
    },
    SET_FIRM_INFO: (state, firmInfo) => {
      state.firmInfo = firmInfo;
      setStore({ name: 'firmInfo', content: firmInfo });
    },
    CLEAR_FIRM_INFO: (state) => {
      state.firmInfo = null;
      setStore({ name: 'firmInfo', content: null });
    },
    SET_TENANT_TYPE: (state, tenantType) => {
      state.tenantType = tenantType;
      setStore({ name: 'tenantType', content: tenantType });
    },
    SET_PORTAL_URL: (state, portalUrl) => {
      state.portalUrl = portalUrl;
      setStore({ name: 'portalUrl', content: portalUrl });
    }
  },
};
export default user;
