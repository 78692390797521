import firmLayout from '@/page/index/firmIndex.vue';

export default [
  {
    path: '/firm',
    component: firmLayout,
    redirect: '/firm/index',
    children: [
      {
        path: 'index',
        name: '事务所主页', // name不能重复
        meta: {
          i18n: 'firm',
          menuType: 'firm',
          visibilityScope: 'firm',
          role: ['admin', 'administrator', 'zxs']
        },
        component: () => import(/* webpackChunkName: "firm" */ '@/views/firm/index.vue'),
      },
      // {
      //   path: 'aisearch/aisearch',
      //   name: '税法AI',
      //   meta: {
      //     i18n: 'aisearchResult',
      //     menuType: 'firm',
      //     visibilityScope: 'firm',
      //     isMenu: 'true',
      //     role: ['admin', 'administrator', 'zxs']
      //   },
      //   component: () => import(/* webpackChunkName: "firm" */ '@/views/aisearch/aisearch.vue'),
      // },
      {
        path: 'aisearch/aisearchResult',
        name: '税法AI',
        meta: {
          i18n: 'aisearchResult',
          menuType: 'firm',
          visibilityScope: 'firm',
          isMenu: 'true',
          role: ['admin', 'administrator', 'zxs']
        },
        component: () => import(/* webpackChunkName: "firm" */ '@/views/aisearch/aisearchResult.vue'),
      },
      {
        path: 'firm',
        name: '事务所管理',
        meta: {
          i18n: 'firm',
          menuType: 'firm',
          visibilityScope: 'system,firm',
          isMenu: 'true',
        },
        component: () => import(/* webpackChunkName: "firm" */ '@/views/firm/firm.vue'),
      },
      {
        path: 'firmEnterprise',
        name: '事务所企业管理',
        meta: {
          i18n: 'firmEnterprise',
          menuType: 'firm',
          visibilityScope: 'system,firm',
          isMenu: 'true',
        },
        component: () => import(/* webpackChunkName: "firm" */ '@/views/firmEnterprise/firmEnterprise.vue'),
      },
      {
        path: 'knowledge/llmKnowledgeSwsDocTemplate',
        name: '事务所底稿模板',
        meta: {
          i18n: 'firmDocTemplate',
          menuType: 'firm',
          visibilityScope: 'firm,zxs',
          isMenu: 'true',
          role: ['admin', 'administrator', 'zxs']
        },
        component: () => import(/* webpackChunkName: "firm" */ '@/views/knowledge/llmKnowledgeSwsDocTemplate.vue'),
      },
      {
        path: 'user/info',
        name: '事务所个人信息', // name不能重复
        meta: {
          i18n: 'info',
          menuType: 'firm',
          visibilityScope: 'firm',
        },
        component: () => import(/* webpackChunkName: "firm" */ '@/views/firm/userinfo.vue'),
      },
      {
        path: 'knowledge/llmKnowledgeSwsDisk',
        name: '事务所云盘',
        meta: {
          i18n: 'firmKnowledge',
          menuType: 'firm',
          visibilityScope: 'firm',
        },
        component: () => import('@/views/knowledge/llmKnowledgeSwsDisk.vue'),
      },
      {
        path: 'disk/file',
        name: '事务所云盘文件',
        meta: {
          i18n: 'firmDiskFiles',
          menuType: 'firm',
          visibilityScope: 'firm',
          isTab: false,
          role: ['admin', 'administrator', 'zxs']
        },
        component: () => import('@/views/firm/firmDiskFiles.vue'),
      },

      {
        path: 'userDisk',
        name: '事务所用户云盘',
        meta: {
          i18n: 'userDisk',
          menuType: 'firm',
          visibilityScope: 'firm,zxs',
        //   isMenu: 'true',  有空再实现
        },
        component: () => import('@/views/knowledge/llmKnowledgeSwsUserDisk.vue'),
      },
      {
        path: 'userDisk/file',
        name: '事务所用户云盘文件',
        meta: {
          i18n: 'userDiskFiles',
          menuType: 'firm',
          visibilityScope: 'firm',
          isTab: false,
          role: ['zxs']
        },
        component: () => import('@/views/firm/firmUserDiskFiles.vue'),
      },
      {
        path: 'firmTools',
        name: '事务所工具箱',
        meta: {
          i18n: 'firmTools',
          menuType: 'firm',
          visibilityScope: 'firm,zxs',
          isMenu: 'true',
          role: ['admin', 'administrator', 'zxs']
        },
        component: () =>
          import(/* webpackChunkName: "firm" */ '@/views//chat/index.vue'),
      },
      {
        path: 'trash',
        name: '事务所回收站',
        meta: {
          i18n: 'firmTrash',
          menuType: 'firm',
          visibilityScope: 'firm',
          isMenu: 'true',
          role: ['admin', 'administrator']
        },
        component: () => import(/* webpackChunkName: "firm" */ '@/views/llmtrash/llmTrash.vue'),
      },
      {
        path: 'userTrash',
        name: '回收站',
        meta: {
          i18n: 'firmTrash',
          menuType: 'firm',
          visibilityScope: 'firm',
          isMenu: 'true',
          role: ['zxs']
        },
        component: () => import(/* webpackChunkName: "firm" */ '@/views/llmtrash/llmTrash.vue'),
      },
    ],
  },
];
