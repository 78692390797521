import request from '@/axios';

/*系统模版底稿 */
export const fetchSystemWorkpapers = ( ) => {
  return request({
    url: '/sc-llm//freeApi/llmKnowledgeDocFiles/listWorkingPaperSysFiles',
    method: 'get',
    params: {
        taxProjectCategory:1
    }
  })
}

export const fetchProjectWorkpapers = (project_id ) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/projectWorkingPaper',
    method: 'get',
    params: {
      projectId:project_id
    }
  })
}

