<template>
    <el-scrollbar class="avue-menu">
      <div v-if="menu && menu.length == 0 && !isHorizontal" class="avue-sidebar--tip">
        {{ $t('menuTip') }}
      </div>
      <el-menu
        unique-opened
        :default-active="activeMenu"
        :mode="setting.sidebar"
        :collapse="getScreen(isCollapse)"
      >
        <sidebar-item :menu="menu"></sidebar-item>
      </el-menu>
    </el-scrollbar>
  </template>
  
  <script>
  import { mapGetters, mapState } from 'vuex';
  import sidebarItem from './sidebarItem.vue';
  
  export default {
    name: 'courseSidebar',
    components: { sidebarItem },
    inject: ['index'],
    created() {
      this.index.openMenu({visibilityScope: 'edu'});
    },
    computed: {
      ...mapGetters(['isHorizontal', 'setting', 'menu', 'tagCourse', 'isCollapse', 'menuId']),
      ...mapState({
        tenantId: state => state.user.tenantId,
      }),
      activeMenu() {
        const route = this.$route;
        const { meta, path } = route;
        if (meta.activeMenu) {
          return meta.activeMenu;
        }
        return path;
      },
    },
  };
  </script>
  <style lang="scss" scoped></style>
  