import Layout from '@/page/index/index.vue';
import AiLayout from '@/page/index/aiIndex.vue';
import projectLayout from '@/page/index/projectIndex.vue';
import Store from '@/store/';
import DefaultLayout from '@/layouts/DefaultLayout.vue'

export default [
  {
    path: '/portal',
    redirect: '/portal/home',
    children: [
      {
        path: 'home',
        name: 'Portal Home', // name不能重复
        meta: {
          i18n: 'portalHome',
          menuType: 'standalone',
        },
        component: () => import(/* webpackChunkName: "portal" */ '@/views/portal/Home1.vue'),
      },
    ],
  }, 
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('@/views/chat/index.vue')
  },
  {
    path: '/newmain',
    component: DefaultLayout, 
    children: [  
      {
        path: 'my-courses',
        name: 'MyCourses',
        meta: {
          i18n: 'courses',
          title: 'My Courses'
        },
        component: () => import('@/views/courses/CourseList.vue')
      },
      
      // ... 其他路由可以后续添加
    ]
  },
  {
    path: '/wel',
    component: () =>
      Store.getters.isMacOs ? import('@/mac/index.vue') : import('@/page/index/index.vue'),
      redirect: '/wel/index',
      children: [
        {
          path: 'index',
          name: '首页', // name不能重复
          meta: {
            i18n: 'dashboard',
          },
          component: () => import(/* webpackChunkName: "views" */ '@/views/wel/index.vue'),
        },
        {
          path: 'dashboard',
          name: '控制台',
          meta: {
            i18n: 'dashboard',
            menu: false, // 当为false，页面没有左侧菜单栏
          },
          component: () => import(/* webpackChunkName: "views" */ '@/views/wel/dashboard.vue'),
        },
      ],
  },
  {
    path: '/aiApp',
    component: AiLayout,
    redirect: '/aiApp/index',
    children: [
      {
        path: 'index',
        name: 'AI工作台',
        alias: 'aiMenu',
        meta: {
          i18n: 'aiApp',
          // target: 'blank',
          menuType: 'ai', //这个类型，在主菜单中不显示
        },
        component: () => import(/* webpackChunkName: "views" */ '@/views/aiApplication/index.vue'),
      },
      {
        // 预览专用，不清楚实际要怎么调用，先放这里给个参考
        path: 'sonPage',
        name: 'AI子页面',
        alias: 'aiMenu',
        meta: {
          i18n: 'aiApp',
          menuType: 'ai',
        },
        component: () =>
          import(/* webpackChunkName: "views" */ '@/views/aiApplication/aiSonPage.vue'),
      },
    ],
  },
  // 新添加的项目主页路由，与 aiApp 同级
  {
    path: '/projectApp',
    component: projectLayout, // 假设您使用了一个通用的 Layout 组件
    redirect: '/projectApp/index',
    children: [
      {
        path: 'index',
        name: '项目主页', // name不能重复
        meta: {
          i18n: 'projectApp',
          menuType: 'project',
          // 根据需要添加其他元信息
        },
        component: () => import(/* webpackChunkName: "firm" */ '@/views/projectApp/index.vue'),
      },
    ],
  },
  {
    path: '/projectfirmEnterprise',
    component: projectLayout,
    redirect: '/projectfirmEnterprise/index',
    children: [
      {
        path: 'index',
        name: 'projectfirmEnterprise',
        meta: {
          i18n: 'projectApp',
          menuType: 'project',
        },
        component: () =>
          import(/* webpackChunkName: "firm" */ '@/views/firmEnterprise/baseInfo.vue'),
      },
    ],
  },
  {
    path: '/luckysheet',
    component: projectLayout, // 假设您使用了一个通用的 Layout 组件
    redirect: '/luckysheet/index',
    children: [
      {
        path: 'index',
        name: 'luckysheet',
        meta: {
          i18n: 'projectApp',
          menuType: 'project',
          // 根据需要添加其他元信息
        },
        component: () => import(/* webpackChunkName: "firm" */ '@/views/luckysheet/index.vue'),
      },
    ],
  },
   
  {
    path: '/thinkChain',
    redirect: '/thinkChain/index',
    children: [
      {
        path: 'index',
        name: 'thinkChain',
        meta: {
          i18n: 'thinkChain',
          menuType: 'firm',
          isTab: false,
          // 根据需要添加其他元信息
        },
        component: () =>
          import(/* webpackChunkName: "firm" */ '@/views/luckysheet/indexOnlyExcel.vue'),
      },
    ],
  },

  // 新添加的路由
  {
    path: '/ai-content-editor',
    name: 'aiContentEditor',
    meta: {
      i18n: 'aiContentEditor',
      menuType: 'standalone',
      // 根据需要添加其他元信息
    },
    component: () =>
      import(
        /* webpackChunkName: "ai-content-editor" */ '@/views/wordonline/plugin/AiContenEditor/index.vue'
      ),
  },
  // 新添加的 wordonline 路由
  {
    path: '/wordonline',
    component: projectLayout,
    redirect: '/wordonline/index',
    children: [
      {
        path: 'index',
        name: 'wordonline',
        meta: {
          i18n: 'projectApp',
          menuType: 'project',
          // 你可以根据需要添加或修改元信息
        },
        component: () => import(/* webpackChunkName: "firm" */ '@/views/wordonline/index.vue'),
      },
    ],
  },
  
  // 新添加的路由
  {
    path: '/questionsetting',
    name: 'questionsetting',
    meta: {
      i18n: 'questionsetting',
      menuType: 'standalone',
      // 根据需要添加其他元信息
    },
    component: () =>
      import(/* webpackChunkName: "ai-content-editor" */ '@/views/wordonline/question/setting.vue'),
  },
  {
    path: '/questionthinking',
    name: 'questionthinking',
    meta: {
      i18n: 'questionthinking',
      menuType: 'standalone',
      // 根据需要添加其他元信息
    },
    component: () =>
      import(
        /* webpackChunkName: "ai-content-editor" */ '@/views/wordonline/question/thinking.vue'
      ),
  },
  {
    path: '/questionstuview',
    name: 'questionstuview',
    meta: {
      i18n: 'questionstuview',
      menuType: 'standalone',
      // 根据需要添加其他元信息
    },
    component: () =>
      import(/* webpackChunkName: "ai-content-editor" */ '@/views/wordonline/question/stuview.vue'),
  },
  {
    path: '/file-viewer',
    component: projectLayout,
    redirect: '/file-viewer/index',
    children: [
      {
        path: 'index',
        name: 'file-viewer',
        meta: {
          i18n: 'projectApp',
          menuType: 'project',
          // 根据需要添加其他元信息
        },
        component: () => import(/* webpackChunkName: "views" */ '@/views/file-viewer/index.vue'),
        props: route => ({
          fileId: route.query.fileId,
          fileName: route.query.fileName,
          fileLink: route.query.fileLink,
        }),
      },
    ],
  },
  {
    path: '/initData',
    name: '企业数据初始化',
    meta: {
      i18n: 'aiApp',
    },
    component: () => import(/* webpackChunkName: "firm" */ '@/views/aiApplication/initData.vue'),
  },
  {
    path: '/firm/login',
    name: 'firmLogin',
    meta: {
      i18n: 'firmLogin',
      menuType: 'standalone',
    },
    component: () => import(/* webpackChunkName: "firm-login" */ '@/views/firm/login.vue'),
  },
  {
    path: '/firm/regist',
    name: 'firmRegist',
    meta: {
      i18n: 'firmRegist',
      menuType: 'standalone',
    },
    component: () => import(/* webpackChunkName: "firm-regist" */ '@/views/firm/regist.vue'),
  },
  {
    path: '/firmProject',
    component: projectLayout, // 假设您使用了一个通用的 Layout 组件
    redirect: '/firmProject/financialInfo',
    children: [
      {
        path: 'financialInfo',
        name: 'firmProject',
        meta: {
          i18n: 'projectApp',
          menuType: 'project',
          // 根据需要添加其他元信息
        },
        component: () =>
          import(/* webpackChunkName: "firm" */ '@/views/firmProject/financialInfo.vue'),
      },
    ],
  },
  {
    path: '/swjc',
    component: AiLayout,
    redirect: '/swjc/index',
    children: [
      {
        path: 'index',
        name: '税务检查',
        meta: {
          i18n: 'swjc',
          menuType: 'ai',
        },
        component: () => import(/* webpackChunkName: "firm" */ '@/views/swjc/index.vue'),
      },
    ],
  },
  {
    path: '/test',
    component: Layout,
    redirect: '/test/index',
    children: [
      {
        path: 'index',
        name: '测试页',
        meta: {
          i18n: 'test',
        },
        component: () => import(/* webpackChunkName: "views" */ '@/views/util/test.vue'),
      },
    ],
  },
  {
    path: '/dict-horizontal',
    component: Layout,
    redirect: '/dict-horizontal/index',
    children: [
      {
        path: 'index',
        name: '字典管理',
        meta: {
          i18n: 'dict',
        },
        component: () =>
          import(/* webpackChunkName: "views" */ '@/views/util/demo/dict-horizontal.vue'),
      },
    ],
  },
  {
    path: '/dict-vertical',
    component: Layout,
    redirect: '/dict-vertical/index',
    children: [
      {
        path: 'index',
        name: '字典管理',
        meta: {
          i18n: 'dict',
        },
        component: () =>
          import(/* webpackChunkName: "views" */ '@/views/util/demo/dict-vertical.vue'),
      },
    ],
  },
  {
    path: '/info',
    component: Layout,
    redirect: '/info/index',
    children: [
      {
        path: 'index',
        name: '个人信息',
        meta: {
          title: '234234',
          i18n: 'info',
        },
        component: () => import(/* webpackChunkName: "views" */ '@/views/system/userinfo.vue'),
      },
    ],
  },
  {
    path: '/knowledge',
    component: Layout,
    redirect: '/knowledge/llmKnowledgeDoc',
    children: [
      {
        path: 'llmKnowledgeDoc',
        name: '配置知识库',
        meta: {
          i18n: 'info',
        },
        component: () =>
          import(/* webpackChunkName: "views" */ '@/views/knowledge/llmKnowledgeDoc.vue'),
      },
    ],
  }, 
  {
    path: '/work/process/leave',
    component: Layout,
    redirect: '/work/process/leave/form',
    children: [
      {
        path: 'form/:processDefinitionId',
        name: '请假流程',
        meta: {
          i18n: 'work',
        },
        component: () =>
          import(/* webpackChunkName: "views" */ '@/views/work/process/leave/form.vue'),
      },
      {
        path: 'handle/:taskId/:processInstanceId/:businessId',
        name: '处理请假流程',
        meta: {
          i18n: 'work',
        },
        component: () =>
          import(/* webpackChunkName: "views" */ '@/views/work/process/leave/handle.vue'),
      },
      {
        path: 'detail/:processInstanceId/:businessId',
        name: '请假流程详情',
        meta: {
          i18n: 'work',
        },
        component: () =>
          import(/* webpackChunkName: "views" */ '@/views/work/process/leave/detail.vue'),
      },
    ],
  },
  // {
  //   path: '/aisearch',
  //   component: () => import(/* webpackChunkName: "views" */ '@/page/index/firmIndex.vue'),
  //   children: [
  //     {
  //       path: '/aisearch-result',
  //       name: '税法AI',
  //       component: aisearchResult,
  //       meta: {
  //         menuType: 'firm'
  //       }
  //     }
  //   ]
  // },
];
