<template>
  <div class="side-menu">
    <!-- Logo部分 -->
    <div class="logo-container">
      <img src="/asset/logo/logo-dark.jpg" alt="E-Studieses" class="logo" />
      <!-- <span class="logo-text">E-Studieses</span> -->
    </div>

    <!-- Main Menu部分 -->
    <div class="menu-section"> 
      <h3 class="section-title">我的事务所</h3>
      <router-link to="/firm/index" class="menu-item"
      :class="{ 'active': activeMenu === '/firm/index' }">
          <img src="/asset/icon/asset_icon_home-normal.png" alt="Profile" class="menu-icon" />
          <span class="menu-text">事务所主页</span>
        </router-link>
      <div class="menu-items">
        <router-link 
        v-for="item in menu" 
        :key="item.path"
        :to="item.path"
        class="menu-item"
        :class="{ active: activeMenu === item.path }"
      >
        <img :src="item.icon || '/asset/icon/asset_icon_home-normal.png'" :alt="item.label" class="menu-icon" />
        <span class="menu-text">{{ item.name }}</span>
      </router-link>

      </div>
    </div>

    <!-- Settings部分 -->
    <div class="menu-section">
      <h3 class="section-title">我的</h3>
      <div class="menu-items">
        <div @click.native="logout" class="menu-item cursor-pointer">
          <img src="/asset/icon/asset_icon_code-normal.png" alt="Logout" class="menu-icon" />
          <span class="menu-text">退出账号</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'; 
export default {
  name: 'sidebar', 
  inject: ['index'],
  created() {
    this.index.openMenu({visibilityScope: 'firm'});
  },
  methods: {
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        this.$router.push({ path: this.portalUrl });
      });
    },
  },
  computed: {
    ...mapGetters(['isHorizontal', 'setting', 'menu', 'tag', 'isCollapse', 'menuId']),
    ...mapState({
      tenantId: state => state.user.tenantId,
      portalUrl: state => state.user.portalUrl,
    }),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
};
</script>
<style scoped>
.side-menu {
  width: 250px;
  height: 100vh; 
  padding: 24px 16px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 12px;
  margin-bottom: 20px;
}

.logo { 
  height: 30px;
}

.logo-text {
  font-size: 20px;
  font-weight: 600;
  color: #1A1A1A;
}

.section-title {
  font-size: 14px;
  font-weight: 400;
  color: #0F1B41;
  margin-bottom: 16px;
  padding: 0 12px;
}

.menu-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  text-decoration: none;
  color: #666666;
  border-radius: 8px; 
}

.menu-item:hover{
    background: #A4ADC8;
}
.menu-item.active{
  background: #2F3CED;
}
.menu-item:hover .menu-text,.menu-item.active .menu-text {
    color: #FFFFFF;
}

 
.menu-icon {
  width: 20px;
  height: 20px;
}

.menu-text {
    color: #A4ADC8;
     font-size: 14px;
}

/* 当菜单项处于激活状态时，图标颜色变白 */
.menu-item.active .menu-icon {
  filter: brightness(0) invert(1);
}

.menu-item:hover img {
  content: url('/asset/icon/asset_icon_home-hover.png');
}

/* 为每个菜单项添加特定的hover图标 */
.menu-item[to="/dashboard"]:hover img {
  content: url('/asset/icon/asset_icon_home-hover.png');
}

.menu-item[to="/ebook"]:hover img {
  content: url('/asset/icon/asset_icon_ebook-hover.png');
}

.menu-item[to="/my-courses"]:hover img {
  content: url('/asset/icon/asset_icon_mycourse-hover.png');
}

.menu-item[to="/purchase"]:hover img {
  content: url('/asset/icon/asset_icon_purchase-hover.png');
}

.menu-item[to="/completed"]:hover img {
  content: url('/asset/icon/asset_icon_completed-hover.png');
}

.menu-item[to="/challenges"]:hover img {
  content: url('/asset/icon/asset_icon_code-hover.png');
}

.menu-item[to="/community"]:hover img {
  content: url('/asset/icon/asset_icon_community-hover.png');
}

.menu-item[to="/settings"]:hover img {
  content: url('/asset/icon/asset_icon_setting-hover.png');
}

.menu-item[to="/logout"]:hover img {
  content: url('/asset/icon/asset_icon_logout-hover.png');
}
</style> 