import { getStore, setStore } from '@/utils/store';
import website from '@/config/website';
 
 
const dict = {
  state: {
    isSidebarOpen: getStore({ name: 'isSidebarOpen' }) || false,
    projectTags: getStore({ name: 'projectTags' }) || {}, // 用于存储每个项目的标签数据
    projectTagWel: website.projectFirstPage,
  }, 
  mutations: {
    toggleSidebar(state) {
        
        state.isSidebarOpen = !state.isSidebarOpen;
      },
    ADD_PROJECT_TAG: (state, { projectId, tag }) => {
      let curProjectTags = state.projectTags[projectId] || { projectTagList: [], projectTag: {} };
      if (typeof tag.name == 'function') {
        tag.name = tag.name(tag.query);
      }
      curProjectTags.projectTag = tag;
      if (!curProjectTags.projectTagList.some(ele => ele.fullPath === tag.fullPath)) {
        console.log('tag',tag)
        //if(tag.name !== '项目主页'){
          curProjectTags.projectTagList.push(tag);
        //} 
      }
      state.projectTags[projectId] = curProjectTags;
      setStore({ name: `projectTags`, content: state.projectTags });
    },
    DEL_PROJECT_TAG: (state, { projectId, fullPath }) => {
      const curProjectTags = state.projectTags[projectId];
      if (curProjectTags) {
        curProjectTags.projectTagList = curProjectTags.projectTagList.filter(item => item.fullPath !== fullPath);
        state.projectTags[projectId] = curProjectTags;
        setStore({ name: `projectTags`, content: state.projectTags });
      }
    },
    DEL_PROJECT_ALL_TAG: (state, projectId) => {
      const curProjectTags = state.projectTags[projectId];
      if (curProjectTags) {
        curProjectTags.projectTagList = [];
        state.projectTags[projectId] = curProjectTags;
        setStore({ name: `projectTags`, content: state.projectTags });
      }
    },
    DEL_PROJECT_TAG_OTHER: (state, { projectId, excludePaths }) => {
      const curProjectTags = state.projectTags[projectId];
      if (curProjectTags) {
        curProjectTags.projectTagList = curProjectTags.projectTagList.filter(item =>
          excludePaths.includes(item.fullPath)
        );
        state.projectTags[projectId] = curProjectTags;
        setStore({ name: `projectTags`, content: state.projectTags });
      }
    },
  },
};

export default dict;
