<template>
  <template v-for="item in menu">
    <el-menu-item
      v-if="validatenull(item[childrenKey]) && validRoles(item)"
      :index="getPath(item)"
      @click="open(item)"
      :key="item[labelKey]"
    >
      <i :class="item[iconKey]"></i>
      <template #title>
        <span :alt="item[pathKey]">{{ getTitle(item) }}</span>
      </template>
    </el-menu-item>
    <el-sub-menu
      v-else-if="!validatenull(item[childrenKey]) && validRoles(item)"
      :index="getPath(item)"
      :key="item[labelKey]"
    >
      <template #title>
        <i :class="['icon2 folder']"></i>
        <span>{{ getTitle(item) }}</span>
      </template>
      <template v-for="(child, cindex) in item[childrenKey]" :key="child[labelKey]">
        <el-menu-item
          :index="getPath(child)"
          @click="open(child)"
          v-if="validatenull(child[childrenKey])"
        >
          <i :class="['icon2 pdf']"></i>
          <template #title>
            <span class="menu-title">{{ getTitle(child) }}</span>
          </template>
        </el-menu-item>
        <sidebar-item v-else :menu="[child]" :key="cindex"></sidebar-item>
      </template>
    </el-sub-menu>
  </template>
</template>
<script>
import { mapGetters } from 'vuex';
import { validatenull } from 'utils/validate';
import website from '@/config/website';
import { setTheme } from 'utils/util';
import { ref } from 'vue'
import { ChatLineRound, Document, Grid, TrendCharts, Setting, QuestionFilled } from '@element-plus/icons-vue'

export default {
  name: 'sidebarItem',
  data() {
    return {
      props: website.menu,
    };
  },
  props: {
    menu: Array,
  },
  created() {
    console.log('Menu prop:', this.menu);
  },
  computed: {
    ...mapGetters(['roles']),
    labelKey() {
      return this.props.label;
    },
    pathKey() {
      return this.props.path;
    },
    queryKey() {
      return this.props.query;
    },
    iconKey() {
      return this.props.icon;
    },
    childrenKey() {
      return this.props.children;
    },
  },
  methods: {
    validatenull,
    getPath(item) {
      return item[this.pathKey];
    },
    getTitle(item) {
      return this.$router.$avueRouter.generateTitle(item, this.props);
    },
    validRoles(item) {
      item.meta = item.meta || {};
      return item.meta.roles ? item.meta.roles.includes(this.roles) : true;
    },
    open(item) {
      console.log('open', item.alias, item);

      this.$router.push({
        path: item[this.pathKey],
        query: item[this.queryKey],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-sub-menu .el-menu-item {
  height: 45px;
}
::v-deep .el-sub-menu__title{
  height: 45px;
  line-height: 45px;
}
 
.el-menu-item {
  .menu-title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 140px!important; // 确保文本不会超出菜单项的宽度
  }
}

// 如果需要在悬停时显示完整标题，可以添加以下样式
.el-menu-item:hover .menu-title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 140px!important; // 确保文本不会超出菜单项的宽度
}

// 为子菜单项添加样式
.el-sub-menu .el-menu-item .menu-title {
  padding-right: 20px; // 为可能的滚动条留出空间
}
i.iconfont{
  height: 30px; /* 根据需要调整图标的高度 */
  margin-right: 8px; /* 根据需要调整图标与文本之间的间距 */ 
  background-size: 28px; /* 使背景图片覆盖整个元素 */
  background-position: left; /* 使背景图片居中 */
  display: inline-block; /* 确保素是块级元素，以便应用背景图片 */
  vertical-align: middle; /* 使图标与文本垂直对齐 */
  background-repeat: no-repeat;
  padding-left: 40px;
  font-style: normal;
}
i.iconfont:before {
    display: none;
}
i.icon-huanyingye{ 
  background-image: url('/img/icon/发现.png'); /* 替换为您的背景图片路径 */ 
}
i.icon-biaodan{
  background-image: url('/img/icon/草稿箱.png');
  background-size: 26px;
}
i.iconicon_study {
  background-image: url('/img/icon/草稿箱.png');
  background-size: 26px;
}
i.iconicon_wechat {
  background-image: url('/img/icon/消息.png');
  background-size: 24px;
}

.el-menu-item {
  .menu-title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%; // 确保文本不会超出菜单项的宽度
  }
}

 
i.icon2 {
    height: 16px!important; 
    padding-left: 24px!important;
}
</style>
